import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { useParams } from "react-router-dom";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../actions";
import useCheckSessionApi from "../SessionValidity";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PersonIcon from "@material-ui/icons/Person";
import ReceiptIcon from "@material-ui/icons/Receipt";
import DomainIcon from "@material-ui/icons//Domain";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import GroupIcon from "@material-ui/icons/Group";
import AppsIcon from "@material-ui/icons/Apps";
import DevicesIcon from "@material-ui/icons/Devices";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import AccountTreeIcons from "@material-ui/icons/AccountTree";
import SecurityIcon from "@material-ui/icons/Security";
import AssessmentIcon from "@material-ui/icons/Assessment";
import BusinessIcon from "@material-ui/icons/Business";
import LockIcon from "@material-ui/icons/Lock";
import StorageIcon from "@material-ui/icons/Storage";
import HelpIcon from "@material-ui/icons/Help";

import Users from './Users/Users';
import Category from './Category/Category';
import CategoryTree from './CategoryTree/CategoryTree';
import Process from './Process/Process';
import Step from './Step/Step';
import StepLinks from './StepLinks/StepLinks';
import StepStates from './StepStates/StepStates';
import States from './States/States';
import Questions from './Questions/Questions';
import DocumentTypes from './DocumentTypes/DocumentTypes';
import QuestionLinks from './QuestionLinks/QuestionLinks';
import AnswerDocumentLink from './AnswerDocumentLink/AnswerDocumentLink';
import Tag from './Tag/Tag';
import Task from './Task/Task';
import TaskListsStepLink from './TaskListsStepLink/TaskListsStepLink';
import Clients from './Clients/Clients';


const useStyles = makeStyles((theme) => ({
  rootrp: {
    flexGrow: 1,
    // backgroundColor: 'white',
  },
  cursorPointer: {
    cursor: "pointer"
  },
  wrapper: {
    margin: "10%",
    marginRight: "10%",
    marginTop: "8%",
    marginBottom: "3%",
  },
  gridMainContainer: {
      marginTop:'2%',
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    marginRight:"5%",
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  moreVertIcon:{
    textAlign: "right",
  },

  personIconColor: {
      color: 'blue',
  },
  userAddUpdateDelete: {
      marginTop: '4%',
      fontSize: 11,
      color: 'black',
  },
  paperSmall: {
    padding: theme.spacing(2),
    height: '100%',
    marginRight:"5%",
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  twentyFontSize: {
    fontSize: 20,
  },
  twoMarginTop: {
    marginTop:'2%',
  },
  websiteLink: {
    fontSize: 20,
    marginBottom: '10%',
  },

}));

// export default function BusinessDashboard({itemname}){
export default function BusinessDashboard(props){
    // const [change_req_id, setChange_req_id] =  useState(id);
    // const [business_id, setbusiness_id] =  useState(itemname);
    // console.log("props in business dashboard===== ",match.params);
    // const [businessId, setbusinessId] =  useState(businessId);
    // let params = match.params;
    let params = useParams();
    // const { business_id } = useParams();

    const classes = useStyles();
    const [businessDetailsUseEffect, setbusinessDetailsUseEffect] = React.useState(false);
    // const [businessDetails, setbusinessDetails] = React.useState(JSON.parse(localStorage.getItem('businessDetailsForDashboard')));
    const [businessDetails, setbusinessDetails] = React.useState([]);
    const [categoryListDropdown, setcategoryListDropdown] = React.useState([]);
    const [serviceListDropdown ,setserviceListDropdown] = React.useState([]);
    const [processListDropdown, setprocessListDropdown] = React.useState([]);
    const [stepListDropdown, setstepListDropdown] = React.useState([]);
    const [stateListDropdown, setstateListDropdown] = React.useState([]);
    const [questionListDropdown, setquestionListDropdown] = React.useState([]);

    const [flag, setflag] = React.useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        // readItemFromStorage();
        dispatch(userActions.checkSessionValidity());
        let selected_business_id = JSON.parse(localStorage.getItem('selected_business_id'));
        console.log("selected_business_id in use Effect===== ",selected_business_id);
        let device_token = JSON.parse(localStorage.getItem('device_token'));
        let session_id = JSON.parse(localStorage.getItem('session_id'));

        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({'business_id': selected_business_id,'device_token': device_token, 'session_id': session_id })
        };

        fetch( process.env.REACT_APP_GET_BUSINESS_DETAIL , requestOptions)
        .then((Response) => Response.json())
        .then( async(businessDetails) => {
            console.log("businessDetails on same page",businessDetails.data.business_detail);
            if (businessDetails.data.business_detail)
            {
                setbusinessDetails(businessDetails.data.business_detail);
                await localStorage.setItem('selected_business_shortcode', JSON.stringify(businessDetails.data.business_detail.shortcode));
            }
            if(businessDetails.success == false){
                setbusinessDetails([])
                Swal.fire({
                icon: 'error',
                text: businessDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
            }
        })
        .catch(err => {
            Swal.fire({
                icon: 'error',
                //   text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
          });

        // to get category details for dropdown
        let category_type = "category"
        const requestOptionsCategory = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({'category_type': category_type,'business_id':selected_business_id,
            'device_token': device_token, 'session_id': session_id })
        };

        fetch( process.env.REACT_APP_GET_CATEGORIES , requestOptionsCategory)
        .then((Response) => Response.json())
        .then(categoryDetails => {
            console.log("categoryDetails for category dropdown on dashboard",categoryDetails.data.categories);
            if(categoryDetails.data.categories)
            {
                setcategoryListDropdown(categoryDetails.data.categories)
            }
            else{
                setcategoryListDropdown([]) 
            }
        })
        .catch(err => {
            Swal.fire({
                icon: 'error',
                //   text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
          });

        // to get service list for categoryListDropdown
        let service = "service"

        const requestOptionsService = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({'category_type': service,'business_id':selected_business_id,
            'device_token': device_token, 'session_id': session_id })
        };

        fetch( process.env.REACT_APP_GET_CATEGORIES , requestOptionsService)
        .then((Response) => Response.json())
        .then(categoryDetails => {
            console.log("categoryDetails on process page",categoryDetails.data.categories);
            if(categoryDetails.data.categories)
            {
                setserviceListDropdown(categoryDetails.data.categories)
            }
            else{
                setserviceListDropdown([]) 
            }
        })
        .catch(err => {
            Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
        });

        // to get process list for processListDropdown
        const requestOptionsProcess = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({'business_id':selected_business_id,
                'device_token': device_token, 'session_id': session_id })
        };

        fetch( process.env.REACT_APP_GET_PROCESSES , requestOptionsProcess)
        .then((Response) => Response.json())
        .then(processDetails => {
            console.log("processDetails on same page",processDetails.data.processes);
            if(processDetails.data.processes)
            {
                setprocessListDropdown(processDetails.data.processes)
            }
            else{
                setprocessListDropdown([]) 
            }
        })
        .catch(err => {
            Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
        });

        // to get step list for stepListDropdown
        const requestOptionsSteps = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({'business_id':selected_business_id,
                'device_token': device_token, 'session_id': session_id })
        };

        fetch( process.env.REACT_APP_GET_ALL_STEPS , requestOptionsSteps)
        .then((Response) => Response.json())
        .then(stepDetails => {
            console.log("stepDetails on same page",stepDetails.data.steps);
            if(stepDetails.data.steps)
            {
                setstepListDropdown(stepDetails.data.steps)
            }
            else{
                setstepListDropdown([])
            }
        })
        .catch(err => {
            Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
        });

        let service_id = "1";
        const requestOptionsStates = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({'service_id':service_id,
            'device_token': device_token, 'session_id': session_id })
        };
        fetch( process.env.REACT_APP_GET_STATES , requestOptionsStates)
        .then((Response) => Response.json())
        .then(stepStateDetails => {
            console.log("state Details on same page",stepStateDetails.data.states);
            if(stepStateDetails.data.states)
            {
                setstateListDropdown(stepStateDetails.data.states)
            }
            else{
                setstateListDropdown([])
            }
        })
        .catch(err => {
            Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
        });

        fetch( process.env.REACT_APP_GET_ALL_QUESTIONS , requestOptions)
        .then((Response) => Response.json())
        .then(questionDetails => {
            console.log("question details for question list",questionDetails.data.questions);
            if(questionDetails.data.questions)
            {
                setquestionListDropdown(questionDetails.data.questions)
            }
            if(questionDetails.success == false){
                setquestionListDropdown([])
                Swal.fire({
                    icon: 'error',
                    text: questionDetails.errors,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK',
                    timer: 5000,
                })
            }
        })
        .catch(err => {
            Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
        });

    }, [flag]);

    // const readItemFromStorage = async() => {
    //     try {
    //     setDeviceToken(deviceTokenFromRedux)
    //     setSessionId(sessionIdFromRedux)
    //     setflag(false)    
    //     } catch (error) {
    //         console.log("ERROR:",error);
    //     }
    // };
     
    return(
        // console.log("props in business dashboard===== ",businessDetails),
        <div className={classes.rootrp}>
        <div className={classes.wrapper}>
        <div style={{textAlign:'left'}}>
            <Typography className={classes.twentyFontSize}>Business Id :  &nbsp;{ businessDetails && businessDetails.business_id}</Typography>
            <Typography className={classes.twentyFontSize}>Business Name :   &nbsp;{ businessDetails && businessDetails.name}</Typography>
            <Typography className={classes.twentyFontSize}>Business Short Code :  &nbsp;{ businessDetails && businessDetails.shortcode}</Typography>
            <Typography className={classes.twentyFontSize}>Business Address :  &nbsp;{ businessDetails && businessDetails.address}</Typography>
            <Typography className={classes.twentyFontSize}>Business Website : &nbsp;&nbsp;
                <Link to={ businessDetails && businessDetails.website} 
                    className={classes.twentyFontSize} 
                    target = "_blank"
                >
                    { businessDetails && businessDetails.website}
                </Link>
            </Typography>
        </div>
        <Grid container className={classes.gridMainContainer}>
            <Grid item xs={12}>
                <Grid item xs={12} container>
                    <Grid item xs={4} container>
                        <Typography className={classes.twentyFontSize}> Users </Typography>
                    </Grid>

                    <Grid item xs={4} container>
                        <Typography className={classes.twentyFontSize}> Clients </Typography>
                    </Grid>

                </Grid>
                <Grid container>
                
                    <Users/>

                    <Clients/>

                </Grid>

                <div className={classes.twoMarginTop}></div>
                <Grid item xs={12} container>
                    <Typography className={classes.twentyFontSize}> Category </Typography>
                </Grid>
                <Grid container>
                    <Category/>
                    
                    {/* <CategoryTree /> */}
                    { categoryListDropdown && <CategoryTree  categoryListDropdown={categoryListDropdown}/> }
                                       
                </Grid>
                <div className={classes.twoMarginTop}></div>
                <Grid item xs={12} container>
                    <Typography className={classes.twentyFontSize}> Work Flow </Typography>
                </Grid>
                <Grid container>

                    { serviceListDropdown && <Process serviceListDropdown={serviceListDropdown}/> }

                    { processListDropdown && <Step processListDropdown={processListDropdown}/>}

                    { stepListDropdown && <StepLinks stepListDropdown={stepListDropdown} /> }
                   
                </Grid>
                <div className={classes.twoMarginTop}></div>
                <Grid container>

                    {/* {stepListDropdown && <StepStates stepListDropdown={stepListDropdown} stateListDropdown={stateListDropdown}/>} */}
                    {stepListDropdown && <StepStates stepListDropdown={stepListDropdown}/>}

                    {serviceListDropdown && <States serviceListDropdown={serviceListDropdown}/> }

                </Grid>
                <div className={classes.twoMarginTop}></div>
                <Grid item xs={12} container>
                    <Typography className={classes.twentyFontSize}> Questions </Typography>
                </Grid>
                <Grid container>

                    { serviceListDropdown && <Questions serviceListDropdown={serviceListDropdown}/>}
                    
                    { questionListDropdown && <QuestionLinks questionListDropdown={questionListDropdown}/>}
                    
                    
                </Grid>
                <div className={classes.twoMarginTop}></div>
                <Grid item xs={12} container>
                    <Typography className={classes.twentyFontSize}> Documents </Typography>
                </Grid>
                <Grid container>

                    { serviceListDropdown && <DocumentTypes serviceListDropdown={serviceListDropdown}/>}
                    
                    { questionListDropdown && <AnswerDocumentLink questionListDropdown={questionListDropdown}/>}

                    
                   
                </Grid>
                <div className={classes.twoMarginTop}></div>
                <Grid item xs={12} container>
                    <Typography className={classes.twentyFontSize}> Tasks </Typography>
                </Grid>
                <Grid container>
                    { serviceListDropdown && <Tag serviceListDropdown={serviceListDropdown}/>}

                    {stepListDropdown && <Task stepListDropdown={stepListDropdown}/>}

                    {stepListDropdown && <TaskListsStepLink stepListDropdown={stepListDropdown}/>}

                </Grid>
                <div className={classes.twoMarginTop}></div>

                {/* small containers */}
                {/* <Grid container>
                    <Grid item xs={4} className={classes.cursorPointer}>
                        <Paper className={classes.paperSmall}>
                            <Grid container spacing={2}>
                            <Grid item>
                                <Grid item xs={2} className={classes.moreVertIcon}>
                                    <GroupIcon className={classes.personIconColor}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm container spacing={2}>
                                <Grid item xs container direction="column" spacing={1}>
                                    <Typography style={{color:'black'}}>
                                        Groups
                                    </Typography>
                                    <Typography style={{fontSize:9,color:'black'}}>
                                        Create groups for mailing lists and applying ploicies
                                    </Typography>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={4} className={classes.cursorPointer}>
                        <Paper className={classes.paperSmall}>
                            <Grid container spacing={2}>
                            <Grid item>
                                <Grid item xs={2} className={classes.moreVertIcon}>
                                    <AppsIcon/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm container spacing={2}>
                                <Grid item xs container direction="column" spacing={1}>
                                    <Typography style={{color:'black'}}>
                                        Apps
                                    </Typography>
                                    <Typography style={{fontSize:9,color:'black'}}>
                                        Manage web and mobile app access and settings
                                    </Typography>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={4} className={classes.cursorPointer}>
                        <Paper className={classes.paperSmall}>
                            <Grid container spacing={2}>
                            <Grid item>
                                <Grid item xs={2} className={classes.moreVertIcon}>
                                    <DevicesIcon/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm container spacing={2}>
                                <Grid item xs container direction="column" spacing={1}>
                                    <Typography style={{color:'black'}}>
                                        Devices
                                    </Typography>
                                    <Typography style={{fontSize:9,color:'black'}}>
                                        Manage device and secure your organization's data
                                    </Typography>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <div style={{marginTop:'2%'}}></div>
                <Grid container>
                    <Grid item xs={4} className={classes.cursorPointer}>
                        <Paper className={classes.paperSmall}>
                            <Grid container spacing={2}>
                            <Grid item>
                                <Grid item xs={2} className={classes.moreVertIcon}>
                                    <SettingsApplicationsIcon/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm container spacing={2}>
                                <Grid item xs container direction="column" spacing={1}>
                                    <Typography style={{color:'black'}}>
                                        Account settings
                                    </Typography>
                                    <Typography style={{fontSize:9,color:'black'}}>
                                        Manage your organizations profile and preferences
                                    </Typography>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={4} className={classes.cursorPointer}>
                        <Paper className={classes.paperSmall}>
                            <Grid container spacing={2}>
                            <Grid item>
                                <Grid item xs={2} className={classes.moreVertIcon}>
                                    <AccountTreeIcons/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm container spacing={2}>
                                <Grid item xs container direction="column" spacing={1}>
                                    <Typography style={{color:'black'}}>
                                        Organizational units
                                    </Typography>
                                    <Typography style={{fontSize:9,color:'black'}}>
                                        Organize users into units for applying policies
                                    </Typography>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={4} className={classes.cursorPointer}>
                        <Paper className={classes.paperSmall}>
                            <Grid container spacing={2}>
                            <Grid item>
                                <Grid item xs={2} className={classes.moreVertIcon}>
                                    <SecurityIcon/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm container spacing={2}>
                                <Grid item xs container direction="column" spacing={1}>
                                    <Typography style={{color:'black'}}>
                                        Security
                                    </Typography>
                                    <Typography style={{fontSize:9,color:'black'}}>
                                        Configure security settings and view alerts and analytics
                                    </Typography>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <div style={{marginTop:'2%'}}></div>
                <Grid container>
                    <Grid item xs={4} className={classes.cursorPointer}>
                        <Paper className={classes.paperSmall}>
                            <Grid container spacing={2}>
                            <Grid item>
                                <Grid item xs={2} className={classes.moreVertIcon}>
                                    <AssessmentIcon/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm container spacing={2}>
                                <Grid item xs container direction="column" spacing={1}>
                                    <Typography style={{color:'black'}}>
                                        Reports
                                    </Typography>
                                    <Typography style={{fontSize:9,color:'black'}}>
                                        Manage your organizations user and admin activity
                                    </Typography>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={4} className={classes.cursorPointer}>
                        <Paper className={classes.paperSmall}>
                            <Grid container spacing={2}>
                            <Grid item>
                                <Grid item xs={2} className={classes.moreVertIcon}>
                                    <BusinessIcon/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm container spacing={2}>
                                <Grid item xs container direction="column" spacing={1}>
                                    <Typography style={{color:'black'}}>
                                        Buildings and resources
                                    </Typography>
                                    <Typography style={{fontSize:9,color:'black'}}>
                                        Manage and monitor your buildings, rooms, and resources
                                    </Typography>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={4} className={classes.cursorPointer}>
                        <Paper className={classes.paperSmall}>
                            <Grid container spacing={2}>
                            <Grid item>
                                <Grid item xs={2} className={classes.moreVertIcon}>
                                    <LockIcon/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm container spacing={2}>
                                <Grid item xs container direction="column" spacing={1}>
                                    <Typography style={{color:'black'}}>
                                        Admin roles
                                    </Typography>
                                    <Typography style={{fontSize:9,color:'black'}}>
                                        Assign roles to control permissions for the Admin Console
                                    </Typography>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <div style={{marginTop:'2%'}}></div>
                <Grid container>
                    <Grid item xs={4} className={classes.cursorPointer}>
                        <Paper className={classes.paperSmall}>
                            <Grid container spacing={2}>
                            <Grid item>
                                <Grid item xs={2} className={classes.moreVertIcon}>
                                    <StorageIcon/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm container spacing={2}>
                                <Grid item xs container direction="column" spacing={1}>
                                    <Typography style={{color:'black'}}>
                                        Data migration
                                    </Typography>
                                    <Typography style={{fontSize:9,color:'black'}}>
                                        Manage your data from other products or services
                                    </Typography>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={4} className={classes.cursorPointer}>
                        <Paper className={classes.paperSmall}>
                            <Grid container spacing={2}>
                            <Grid item>
                                <Grid item xs={2} className={classes.moreVertIcon}>
                                    <HelpIcon/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm container spacing={2}>
                                <Grid item xs container direction="column" spacing={1}>
                                    <Typography style={{color:'black'}}>
                                        Support
                                    </Typography>
                                    <Typography style={{fontSize:9,color:'black'}}>
                                        Connect with the Help Assistant
                                    </Typography>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid> */}

        </Grid>
        </Grid>
        </div>
        </div>
    )
}