import React, {useEffect} from "react";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TaxManagementCaseDetails from "../TaxManagementCaseDetails";
import AddIcon from '@material-ui/icons/Add';
import TaxManagementCasesTableClient from "./TaxManagementCasesTableClient";
import TaxManagementCasesTableTrpAdmin from "./TaxManagementCasesTableTrpAdmin";
import FilterListIcon from "@material-ui/icons/FilterList";
import { FormattedMessage } from "react-intl";
import FilterViewCases from "../FilterViewCases";
import Drawer from "@material-ui/core/Drawer";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
// import useCheckSessionApi from "../SessionValidity";
import { useSelector, useDispatch } from 'react-redux';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    // margin: "10%",
    marginRight: "1%",
    marginLeft: "7%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  additionalDetail: {
    background: "#E5E5E5",
  },
  buttonContainer:{
    textAlign:"right",
  },
  casesdisplay: {
    display: "block",
  },
  casesdisplayNone: {
    display: "none",
  },
  customFilterLabel: {
    textTransform: "none",
    background: "#FFFFFF",
  },
  drawerPaper: {
    marginTop: "145px",
    width: "425px",
  },
  pageHeaderLabel:{
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  buttonStyle:{
    marginLeft: 1,
  },
  backArrow: {
    marginRight: "10%",

  },
  backButton: {
    // padding: 10,
    lineHeight: 0,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },

}));

export default function TaxManagementCases(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [flag, setflag] = React.useState(false);
  const[business_id, setbusiness_id ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const[device_token, setdevice_token ] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const[session_id, setsession_id ] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const[CategoryCreation, setCategoryCreation] = React.useState([]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
  // console.log("user_permissions from local storage on header page::::::",user_permissions)

  useEffect(() => {
    setflag(true);
    let viewClientCases = localStorage.getItem("view_clients_active_cases")
    console.log("viewClientCases in cases table", viewClientCases)
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_ALL_CATEGORY_INFO , requestOptions)
      .then((Response) => Response.json())
      .then( async(categoryDetails) => {
        console.log("categoryDetails",categoryDetails);
        if(categoryDetails.success == true)
        {
          setCategoryCreation(categoryDetails.data.category_tree);
        }
        else if(categoryDetails.success == false){
          setCategoryCreation([]);
        }
    })
    .catch(err => {
      setCategoryCreation([]);
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });
  }, [flag]);


  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));

  let viewClientCases = JSON.parse(localStorage.getItem("view_clients_active_cases"))
  console.log("viewClientCases in cases table", viewClientCases)

  return (
    console.log("CategoryCreation.lenght in case table",CategoryCreation.length),
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Slide
          direction="right"
          in={!checked}
          timeout={500}
          mountOnEnter
          unmountOnExit
        >
          <div
            className={
              !checked ? classes.casesdisplay : classes.casesdisplayNone
            }
          >
            
             <Grid container>
              {
                (viewClientCases)?
                (
                <Grid item xs={1} className={classes.pageHeaderLabel} style={{marginLeft:'-0.5%'}} >
                  <Button href="/TaxManagementClientDetailsUI" className={classes.backButton}>
                      <span className={classes.backArrow}>
                      <ArrowBackIcon fontSize="small" />
                    </span>
                    Back
                  </Button>
                </Grid>
                ):
                (
                  <Grid></Grid>
                )
              }
            </Grid>

            <Grid container>
              {
                (viewClientCases)?
                (
                  <Grid item xs={8} className={classes.pageHeaderLabel} >
                    {/* <FormattedMessage id="caseLabel" /> */}
                    Active Cases of {viewClientCases.name}
                  </Grid>
                ):
                (
                  <Grid item xs={8} className={classes.pageHeaderLabel} >
                    {/* <FormattedMessage id="caseLabel" /> */}
                    List of Cases
                  </Grid>
                )
              }
              
              {/* <Grid item xs={2}>
                <Button
                  variant="outlined"
                  className={classes.customFilterLabel}
                  startIcon={<FilterListIcon />}
                  onClick={handleDrawerOpen}
                >
                  <FormattedMessage id="filtersLabel" />
                </Button>
              </Grid> */}
              <Grid item xs={4}>
                {
                  ( CategoryCreation.length == 0)?
                  (
                    <div></div>
                  )
                  :(
                    <div className={classes.buttonContainer}>
                    {
                      ( role_of_user == "client" )?(
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonStyle}
                          startIcon={<AddIcon />}
                          href="/TaxManagementClientCases"
                        >
                          {/* <FormattedMessage id="newCasesLabel" /> */}
                          New Case
                        </Button>
                      ):(
                          // ( role_of_user == "trpadmin" )?(
                          <Grid>
                            {/* {user_permissions && user_permissions.permissions.create_case &&
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.buttonStyle}
                                startIcon={<AddIcon />}
                                href="/newcase"
                              >
                                New Case
                              </Button>
                              
                            } */}
                          </Grid>
                        // ):(
                        //   <div></div>
                        // )
                      )
                    }
                    </div>
                  )
                }
                

              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                {
                  ( role_of_user == "client" )?(
                    <TaxManagementCasesTableClient handleChange={handleChange} />
                  ):(
                      // ( role_of_user == "trpadmin" || role_of_user == "trp" || role_of_user == "trpdoc" )?(
                        <TaxManagementCasesTableTrpAdmin handleChange={handleChange} />
                    // ):(
                    //   <div></div>
                    // )
                  )
                }
              </Grid>
            </Grid>
          </div>
        </Slide>
        <div
            className={
              checked ? classes.casesdisplay : classes.casesdisplayNone
            }
          >
        <Slide
          direction="left"
          in={checked}
          timeout={500}
          mountOnEnter
          unmountOnExit
        >
          <div className={classes.additionalDetail}>
            <TaxManagementCaseDetails handleChange={handleChange} />
          </div>
        </Slide>
        </div>
        <Drawer
          anchor={"right"}
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <FilterViewCases handleDrawerClose={handleDrawerClose} />
        </Drawer>
      </div>
    </div>
  );
}
