import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Avatar from '@material-ui/core/Avatar';
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
// import useCheckSessionApi from "../SessionValidity";
import FeedbackIcon from '@material-ui/icons/Feedback';
import Grid from "@material-ui/core/Grid";
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';

import { userActions } from "../actions";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    topPosition: {
        top: "5px !important"
    },
    noUnderlineStyle: {
        textDecoration: "none"
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'scroll',
    },
    paperModalFeedback: {
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        width: 700,
        height: 650,
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
    },
    addNewBusinessHeaderBackgroud: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor: '#23c1f7',
        backgroundColor: 'white',
        height: '10%',
    },
    modalBody: {
        padding: theme.spacing(2, 4, 3),
    },
    formControl: {
        // margin: theme.spacing(1),
        // marginTop: '4%',
        width: '100%',
    },

}));

export default function TakeFeedback({ users }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const [flag, setflag] = React.useState(false);
    const [RedirectProfile, setRedirectProfile] = React.useState(false);

    const [OpenTakeFeedback, setOpenTakeFeedback] = React.useState(false);

    useEffect(() => {
        setflag(true);

    }, [flag]);


    const dispatch = useDispatch()

    const handleFeedbackClick = () => {
        console.log("handleFeedbackClick")
        setOpenTakeFeedback(true);
    }

    const handelTakeFeedbackClose = () => {
        console.log("handelTakeFeedbackClose")
        setOpenTakeFeedback(false);
    }

    const bodyTakeFeedback = (
        <div className={classes.paperModalFeedback}>
            <div className={classes.addNewBusinessHeaderBackgroud}>
                <h2>Feedback</h2>
            </div>

            <div className={classes.modalBody}>
                <FormControl className={classes.formControl}>

                    <TextField
                        margin="normal"
                        // required
                        fullWidth
                        variant="outlined"
                        id="full_address"
                        label="Feedback"
                        multiline
                        rows={4}
                        placeholder='Enter Feedback'
                        name="full_address"
                        autoComplete="full_address"
                        
                        // value={newAddressDetails.full_address}
                        // onChange={(e) => setnewAddressDetails({ ...newAddressDetails, full_address: e.target.value })}
                    />
                </FormControl>

                
            </div>
        </div>
    )

    let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));

    if (RedirectProfile == true) {
        return <Redirect to={`/UsersProfile`} />
    }
    else {
        return (
            <div className={classes.root}>
                <Button
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleFeedbackClick}
                >
                    <FeedbackIcon style={{ fontSize: 45, cursor: 'pointer', color: '#e1e5eb', }} />
                </Button>

                <Modal
                    open={OpenTakeFeedback}
                    onClose={handelTakeFeedbackClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {bodyTakeFeedback}
                </Modal>

            </div>
        );
    }
}
