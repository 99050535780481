import React from 'react';
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { injectIntl, FormattedMessage } from "react-intl";
// import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { history } from "../helpers";
import { Router, Route, Redirect, Link} from "react-router-dom";
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../actions";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(../Image/loginBanner.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatarLocked: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  avatarSignIn: {
    margin: theme.spacing(1),
    backgroundColor: "#ffffff",
  },
  form: {
    marginLeft: "-10%",
    width: "120%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  leftAlign: {
    textAlign: "left",
  },
  errorBlock: {
    marginLeft: "-10%",
    // marginRight: "5%",
    width: "120%",
    paddingTop: "2%",
    textAlign: "left",
  },
  overFlowHidden: {
    overflow: "hidden",
  },
  dontHaveAccText: {
    cursor: "pointer",
    color: "#3f51b5",
  },
  verifyLabel: {
    width: "100%",
    marginTop: theme.spacing(5),
    // textAlign: "left",
    fontSize: 20,
  },
  verifyText: {
    width: "100%",
    marginTop: theme.spacing(2),
    textAlign: "left",
    fontSize: 15,
  },
  didntOtpText: {
    width: "100%",
    marginTop: theme.spacing(2),
    textAlign: "left",
    fontSize: 12,
  },
  resendText: {
    width: "100%",
    marginTop: "1%",
    textAlign: "left",
    fontSize: 12,
    cursor: "pointer",
    color: "#3f51b5",
  },
  leftAlignBackToHome: {
    textAlign: "left",
    marginTop: '1%',
    marginLeft: '1%',
    cursor:"pointer",
  },

}));

export default function SignUpVerification() {
    const [fullname, setfullname] = React.useState();
    const classes = useStyles();
    const[showRefereralCode, setshowRefereralCode] = React.useState(false);
    const[createpassword, setcreatepassword] = React.useState();
    const[SubmitPassword, setSubmitPassword] =  React.useState(false);

    const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  
    const dispatch = useDispatch()

    React.useEffect(() => {
      // console.log("loggingIn in login",loggingIn)
      console.log("device_token in useeffect login",device_token)
      console.log("session_id in useeffect login",session_id)
      
      let user = JSON.parse(localStorage.getItem('user'));
      console.log("user in useeffect login",user)
  
      if( device_token !== null && session_id !== null && user !== null){
        console.log("device token , session id, user info found in useeffect of login")
        // Swal.fire({
        //   icon: 'error',
        //   text: "You are already logged in in app",
        //   confirmButtonColor: '#d33',
        //   confirmButtonText: 'OK'
        // })
        dispatch(userActions.logout());
      }
      else{
        console.log("device token , session id, user info is empty in useeffect of login")
      }
  
    }, []);

    const savePasswordFunc = () => {
      // setshowRefereralCode(true)

        setSubmitPassword(true)
        if( createpassword && createpassword.length > 7 ){
          let client_info = JSON.parse(localStorage.getItem('client_signup_info'))
          console.log("client_id from local storage", client_info)
          let client_id = client_info.client_id
          console.log("client_id from local storage", client_id)

          const createPasswordData = {
            'client_id': client_id,
            'password': createpassword,
          }
          console.log("createPasswordData",createPasswordData)

          const requestOptions = {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(createPasswordData)
          };
          fetch( process.env.REACT_APP_SIGN_UP_CLIENT , requestOptions)
          .then((Response) => Response.json())
          .then( createPasswordData => {
              console.log("createPasswordData in submit password",createPasswordData);
              if(createPasswordData.success == true)
              {
                setshowRefereralCode(true)
              }
              if(createPasswordData.success == false){
                Swal.fire({
                  icon: 'error',
                  text: createPasswordData.errors,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              }
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });


        }
    }

  if(showRefereralCode == true){
    return (
      localStorage.setItem('client_password', JSON.stringify(createpassword)),
      <Redirect to={'/SignUpReferalCode'}/>
    )
  }
else{
  let client_full_info = JSON.parse(localStorage.getItem('client_signup_info'))
  return (
    console.log("client_full_info",client_full_info),
    <div>
        <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            className={classes.overFlowHidden}
        >

        <Grid item xs className={classes.leftAlignBackToHome}  >
          <Link to="HomePage" variant="body2" style={{color:'#551A88'}}>
            <FormattedMessage id="backToHomePageLabel" />
           </Link>
        </Grid>

        <div className={classes.paper}>
            <Avatar className={classes.avatarLocked}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign Up
            </Typography>
            <div>
                    <div>
                    <div  className={classes.form}>
                      <Grid item xs={12}>
                        {
                          ( client_full_info !== null )?
                          ( 
                            <Typography className={classes.verifyLabel}>
                              Welcome {client_full_info.name}!
                            </Typography>
                          ):
                          (<Grid></Grid>)
                        }
                        <Typography className={classes.verifyText}>
                          Create Password
                        </Typography>

                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          // width="100%"
                          id="createpassword"
                          name="createpassword"
                          autoComplete="createpassword"
                          autoFocus
                          value={createpassword}
                          onChange={(e) => setcreatepassword(e.target.value) }
                        />

                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          onClick={savePasswordFunc}
                        >
                          Save Password
                        </Button>
                      </Grid>
                    </div> 
                    <div>
                      <Typography className={classes.didntOtpText}>
                        Helper Text
                      </Typography>
                    </div>  
                    <Grid item xs={12}>
                      <div>
                        { SubmitPassword == true && !createpassword && (
                          <div className={classes.errorBlock}>
                            <Alert variant="filled" severity="error">
                              { SubmitPassword == true && !createpassword && (
                                <div>
                                  Password is required
                                </div>
                              )}
                            </Alert>
                          </div>
                        )}
                        { SubmitPassword == true && createpassword  && (
                          <div className={classes.errorBlock}>
                            {
                                (createpassword.length < 8)?
                                (
                                  <Alert variant="filled" severity="error">
                                    Password must contain atleast 8 characters
                                  </Alert>
                                ):
                                (
                                  <div></div>
                                )
                             }
                           
                          </div>
                        )}
                      </div>
                    </Grid>                     
                  </div>
        </div> 
        </div>
      </Grid>
    </Grid>
    </div>  
        
  )
}
}