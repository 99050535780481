import { authHeader} from '../helpers';
import {fetchAPI} from '../apis';

export async function updateProcessDetailsAPI(processData){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(processData),
        timeout: 4000
    };
    // URL should be an env variable
    const response = await fetchAPI( process.env.REACT_APP_CREATE_PROCESS_URL, 
        requestOptions);
    return await response.json();
}

export async function getServiceProcessesAPI(serviceData){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(serviceData),
        timeout: 4000
    };
    // URL should be an env variable
    const response = await fetchAPI( process.env.REACT_APP_GET_SERVICE_PROCESS_URL, 
        requestOptions);
    return await response.json();
}

export async function makeCategoryLiveAPI(categoryData){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(categoryData),
        timeout: 4000
    };
    // URL should be an env variable
    const response = await fetchAPI( process.env.REACT_APP_MAKE_CATEGORY_LIVE, 
        requestOptions);
    return await response.json();
}
