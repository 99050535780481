import React, {useEffect} from "react";
import Slide from "@material-ui/core/Slide";
import fetch from "cross-fetch";
import { injectIntl } from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage } from "react-intl";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import TaxManagementCaseDetails from "../TaxManagementCaseDetails";
import { modal } from 'reactstrap';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { PropagateLoader } from 'react-spinners';
import { userActions } from "../actions";
import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import Steps, { Step } from "rc-steps";
import "rc-steps/assets/index.css";
import TextField from "@material-ui/core/TextField";
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import AntSwitch from '@material-ui/core/Switch';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FormGroup, Label, Input, ModalHeader, ModalBody, ModalFooter , Spinner,
  Popover, PopoverHeader, PopoverBody} from 'reactstrap';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  pageHeaderContainer: {
    marginBottom: "5%",
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  CategoryLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
    marginLeft: "5%",
  },
  ProcessLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    // fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
    // marginLeft: "5%",
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    textAlign: "left",
    width:'100%',
    color: theme.palette.text.secondary,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginLeft: "5%",
    marginTop: '2%',
    width: '90%',
  },
  CreateProcessLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
  },
  stepContainer: {
    textAlign: "left",
    color: "#4D47DD",
    marginTop:'2%',
    marginLeft:'5%',
  },
  validation: {
    marginTop: '0.5%',
    color: 'red',
    fontSize: 12,
    textAlign: "left",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    height: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block',
    overflowY:'scroll',
  },
  paperModalCreateStepandTasks: {
    position: 'absolute',
    width: 500,
    height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block',
    overflowY:'scroll',
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'12%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),
    width:'100%',
  },
  subHeadingLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "15px",
    color: "rgba(0, 0, 0, 0.96)",
    marginBottom: "5px",
    textAlign: "left",
  },
  AddMoresStepsLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "15px",
    color: "#4D47DD",
    marginBottom: "5px",
    textAlign: "right",
    cursor:'pointer',
  },
  buttonSwalDelete: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
  },
  buttonSwalCancel: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
    marginRight: "2%",
  },
  table: {
    minWidth: 400,
  },
}));

function TaxManagementProcessCreation({ intl , props}) {
  const classes = useStyles();
  const[ShowProcedure, setShowProcedure ] = React.useState(false);
  const[Flag,setFlag] = React.useState(false);
  const[FlagProcess, setFlagProcess] = React.useState(false);
  const[business_id, setbusiness_id ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const[device_token, setdevice_token ] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const[session_id, setsession_id ] = React.useState(JSON.parse(localStorage.getItem('session_id')));

  const[superCategoryList, setsuperCategoryList] = React.useState([]);
  const[selectedSuperCategory, setselectedSuperCategory] = React.useState("");
  const[subCategoryList, setsubCategoryList] = React.useState([]);
  const[selectedSubCategory, setselectedSubCategory] = React.useState("");
  const[SubCtegoryFetched, setSubCtegoryFetched] = React.useState(false);
  const[CategorySelected, setCategorySelected] = React.useState(false);
  const[ServiceSelected, setServiceSelected] = React.useState(false);
  const[subCategoryListForSelectedCategory,setsubCategoryListForSelectedCategory] = React.useState([]);
  const[selectedServiceFromCategory, setselectedServiceFromCategory] = React.useState("");

  const[ServiceSumbmited, setServiceSumbmited] = React.useState(false);
  const[NameServiceSumbmited, setNameServiceSumbmited] = React.useState("");
  const[serviceErr, setserviceErr] = React.useState("");
  const[service_id, setservice_id] = React.useState();

  const [editProcessOrCreateProcess, seteditProcessOrCreateProcess] = React.useState(false);
  const [ProcessDetailsList, setProcessDetailsList] = React.useState([]);
  const [selectedProcessToEdit, setselectedProcessToEdit] = React.useState();
  const [CreateNewProcess, setCreateNewProcess] = React.useState(false);

  const [ExistingProcessDetails, setExistingProcessDetails] = React.useState({});
  const [showAntSwitch, setshowAntSwitch] = React.useState(true);
  const [EditExistingProcess, setEditExistingProcess] = React.useState(false);
  const [openEditStep, setopenEditStep] = React.useState(false);
  const [stepNameEditErr, setstepNameEditErr] = React.useState("");
  const [taskNameEditErr, settaskNameEditErr] = React.useState("");
  const [openNewStepForEditPlace, setopenNewStepForEditPlace] = React.useState(false);

  const [OpenMenuAddAnotherStep, setOpenMenuAddAnotherStep] = React.useState(false);

  const [ExistingProcessStepDetails, setExistingProcessStepDetails] = React.useState();
  const [stepNameErr, setstepNameErr] = React.useState("");
  const [taskNameErr, settaskNameErr] = React.useState("");
  const [taskCompulsaryNotSlectedErr, settaskCompulsaryNotSlectedErr] = React.useState("");

  const [afterStep, setAfterStep] = React.useState(1);
  const [deleteStep, setDeleteStep] = React.useState(1);
  const [stepActionErrMessage, setStepActionErrMessage] =  React.useState("");


  const [NewProcessDetails, setNewProcessDetails] = React.useState({
    name: "",
    service: "",
    shortcode: "",
    status: true,
    description: "",
    version: 1,
    is_current: false,
  });



  const [stepCount, setstepCount] = React.useState("");


  const[ProcessDetailsSubmited, setProcessDetailsSubmited] = React.useState(false);
  const[processNameErr, setprocessNameErr] = React.useState("");
  const[processShortcodeErr, setprocessShortcodeErr] = React.useState("");
  const[processDescriptionErr, setprocessDescriptionErr] = React.useState("");
  const[stepCountErr, setstepCountErr] = React.useState("");

  const[StepsToCreate, setStepsToCreate] = React.useState([
    // {
    //   process_id: "",
    //   name: "",
    //   description: "",
    //   device_token: JSON.parse(localStorage.getItem('device_token')),
    //   session_id: JSON.parse(localStorage.getItem('session_id')),
    // },
  ])
  const[openCreateStep,setopenCreateStep] = React.useState(false);
  const [stepNameNewErr, setstepNameNewErr] = React.useState("");
  const [taskNameNewErr, settaskNameNewErr] = React.useState("");
  const [stepNameCreateErr, setstepNameCreateErr] = React.useState("");
  const [taskNameCreateErr, settaskNameCreateErr] = React.useState("");

  const [ShortCodeAlreadyExistForNewProcessErr, setShortCodeAlreadyExistForNewProcessErr] = React.useState("");
  const [ShortCodeAlreadyExistForEditProcessErr, setShortCodeAlreadyExistForEditProcessErr] = React.useState("");

  const [undefinedVariable, setundefinedVariable] = React.useState("<Undefined>");

  // redux
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.checkSessionValidity());

    localStorage.removeItem('view_clients_active_cases');

    let category_type = "supercategory"
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'category_type': category_type,'business_id':business_id,
      'device_token': device_token, 'session_id': session_id })
    };
    setShowProcedure(true);
    fetch( process.env.REACT_APP_GET_CATEGORIES , requestOptions)
      .then((Response) => Response.json())
      .then(superCategoryDetails => {
        setShowProcedure(false);
        console.log("super categoryDetails for category",superCategoryDetails.data.categories);
        if(superCategoryDetails.data.categories)
        {
          setsuperCategoryList(superCategoryDetails.data.categories)
        }
        else
        {
          setsuperCategoryList([])
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

  }, []);

  useEffect(() => {
    dispatch(userActions.checkSessionValidity());
    setFlag(true);
  }, [Flag]);

  useEffect(() => {
    dispatch(userActions.checkSessionValidity());
    setFlagProcess(true)
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
          'process_type': 'draft',
          'service_id': service_id,
          'device_token': device_token, 
          'session_id': session_id
      })
    };
    fetch( process.env.REACT_APP_GET_SERVICE_PROCESS_URL , requestOptions)
    .then((Response) => Response.json())
    .then( async(processDetails) => {
      setShowProcedure(false);
      console.log("processDetails",processDetails);
      if(processDetails.success == true)
      {
        setProcessDetailsList(processDetails.data.processes);
      }
      else{
        setProcessDetailsList([]);
      }
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });
  }, [FlagProcess]);

  const handleNewProcessDetailsShortcode = async(value) => {
    console.log("e of handleNewProcessDetailsShortcode",value);
    await setNewProcessDetails({...NewProcessDetails, shortcode: value })

    if ( value !== "" ){
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'shortcode': value, 'entity_type': "process", 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
      };
      return fetch( process.env.REACT_APP_SHORTCODE_VALIDATION, requestOptions)
      .then((Response) => Response.json())
      .then( checkShortcode => {
        console.log("checkShortcode is valid or not",checkShortcode)
        console.log("checkShortcode is valid or not",checkShortcode.data.is_valid)
        if( checkShortcode.success == true ){
          if( checkShortcode.data.is_valid == false ){
            setShortCodeAlreadyExistForNewProcessErr("Short code already Exist")
          }
          else if( checkShortcode.data.is_valid == true ){
            setShortCodeAlreadyExistForNewProcessErr("")
          }
        }
      })
    }
  }

  const handleChangeSelectStepCountDropdown = (e) => {
    dispatch(userActions.checkSessionValidity());
    console.log("e of handleChangeSelectStepCountDropdown",e.target.value);
    setstepCount(e.target.value);
    // if value changes it sets to array 0 if not hen it gets added in previous count
    setStepsToCreate([]);
    // ///////////
    // if( !e.target.value ){
    //   setstepCountErr("");
    // }
    var pattern = new RegExp(/^[0-9\b]+$/)
      if (!pattern.test(e.target.value)) {
        setstepCountErr("Please enter only numbers.")
    }

    if(e.target.value > 0 && e.target.value < 12 ){
      setstepCountErr("");
      for(var i = 1 ; i <= e.target.value; i++  ){
        setStepsToCreate(prevState => [...prevState, {
            // process_id: "",
            name: "",
            step_id: "",
            is_review: false,
            todolist_id: "",
            // description: "",
            // shortcode: "",
            // device_token: JSON.parse(localStorage.getItem('device_token')),
            // session_id: JSON.parse(localStorage.getItem('session_id')),
            tasks: [
              {
                item_id: "",
                item : "",
                is_compulsory: false,
                is_active: true,
                // todolist_id: "",
                // item_type: "business",
              }
            ]
        }] );
      }
    }
    else{
      if( e.target.value < 1 ){
        setstepCountErr("Step Count Must be Greater than 1")
      }
      if( e.target.value >= 12){
        setstepCountErr("Step Count Must be Less than 12")
      }
    }

  }

  const handleChangeSuprCategoryDropdown = (e) =>{
    console.log("e",e.target.value, e.target);
    dispatch(userActions.checkSessionValidity());
    setselectedSuperCategory(e.target.value);

    // if category changes
    setCategorySelected(false);
    setsubCategoryListForSelectedCategory([]);
    setServiceSelected(false);
    setselectedServiceFromCategory("");
    setselectedSubCategory("");

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'parent_category_id': e.target.value,
      'device_token': device_token, 'session_id': session_id })
    };
    setShowProcedure(true);
    fetch( process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY , requestOptions)
      .then((Response) => Response.json())
      .then( subCategoryDetails => {
        setShowProcedure(false);
        console.log("subCategoryDetails for list",subCategoryDetails.data.category_children);
        if(subCategoryDetails.data.category_children)
        {
          setsubCategoryList(subCategoryDetails.data.category_children)
        }
        else
        {
          setsubCategoryList([])
        }
        setSubCtegoryFetched(true)
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  const handleChangeCategoryOrServiceDropdown = (e) => {
    console.log("e",e.target.value);
    dispatch(userActions.checkSessionValidity());
    setselectedSubCategory(e.target.value)
    setServiceSelected(false);
    setCategorySelected(false);

    let matchedCategory = "";
    subCategoryList.map((item, index) =>{
      if(e.target.value == item.category_id){
        // console.log("ok this is matched of selected item",item)
        matchedCategory = item;
      }
    })
    console.log("matchedCategory",matchedCategory)

    if(matchedCategory.category_type == "category"){
      setCategorySelected(true);
      setShowProcedure(true);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'parent_category_id': e.target.value,
        'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY , requestOptions)
      .then((Response) => Response.json())
      .then( subCategoryDetailsForSelectedCategory => {
        setShowProcedure(false);
        console.log("subCategoryDetailsForSelectedCategory for list",subCategoryDetailsForSelectedCategory.data.category_children);
        if(subCategoryDetailsForSelectedCategory.data.category_children)
        {
          setsubCategoryListForSelectedCategory(subCategoryDetailsForSelectedCategory.data.category_children)
        }
        else
        {
          setsubCategoryListForSelectedCategory([])
        }

      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    }

    if(matchedCategory.category_type == "service"){
      dispatch(userActions.checkSessionValidity());
      setServiceSelected(true);
      setNameServiceSumbmited(matchedCategory.name)
      setservice_id(e.target.value)

      console.log("step count and sevice submited");
      setShowProcedure(true);
      setServiceSumbmited(true);
      // setNameServiceSumbmited()

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            'process_type': 'draft',
            'service_id': e.target.value,
            'device_token': device_token, 
            'session_id': session_id 
        })
      };
      fetch( process.env.REACT_APP_GET_SERVICE_PROCESS_URL , requestOptions)
      .then((Response) => Response.json())
      .then( async(processDetails) => {
        setShowProcedure(false);
        console.log("processDetails",processDetails);
        if(processDetails.success == true)
        {
          setProcessDetailsList(processDetails.data.processes);
        }
        else{
          setProcessDetailsList([]);
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    }

  }

  const handleChangeServiceDropdown = (e) => {
    console.log("e",e.target.value);
    dispatch(userActions.checkSessionValidity());
    setselectedServiceFromCategory(e.target.value)
    let matchedService = "";
    subCategoryListForSelectedCategory.map((item, index) =>{
      if(e.target.value == item.category_id){
        console.log("ok this is matched of selected item",item)
        matchedService = item;
      }
    })
    console.log("matchedService",matchedService)

    if(matchedService.category_type == "service"){
      setServiceSelected(true);
      setNameServiceSumbmited(matchedService.name)
      setservice_id(e.target.value)

      console.log("step count and sevice submited");
      setShowProcedure(true);
      setServiceSumbmited(true);

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            'process_type': 'draft',
            'service_id': e.target.value,
            'device_token': device_token, 
            'session_id': session_id 
            })
      };
      fetch( process.env.REACT_APP_GET_SERVICE_PROCESS_URL , requestOptions)
      .then((Response) => Response.json())
      .then( async(processDetails) => {
        setShowProcedure(false);
        console.log("processDetails",processDetails);
        if(processDetails.success == true)
        {
          setProcessDetailsList(processDetails.data.processes);
        }
        else{
          setProcessDetailsList([]);
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    }

  }

  const validate = () =>{
    let serviceErr = '';

    if( ServiceSelected == false){
      serviceErr = "Please Select Service"
    }

    if( serviceErr ){
      setserviceErr(serviceErr);

      return false;
    }
    return true;
  }

  const handelSubmitStepCountAndService = () => {
    dispatch(userActions.checkSessionValidity());
    const isValid = validate();
    if(isValid){
      console.log("step count and sevice submited");
      setShowProcedure(true);
      setServiceSumbmited(true);

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            'process_type': 'draft',
            'service_id': service_id,
            'device_token': device_token, 
            'session_id': session_id 
        })
      };
      fetch( process.env.REACT_APP_GET_SERVICE_PROCESS_URL , requestOptions)
      .then((Response) => Response.json())
      .then( async(processDetails) => {
        setShowProcedure(false);
        console.log("processDetails",processDetails);
        if(processDetails.success == true)
        {
          setProcessDetailsList(processDetails.data.processes);
        }
        else{
          setProcessDetailsList([]);
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    }
  }

  const handelCancelStepCountAndService = () => {
    dispatch(userActions.checkSessionValidity());
    console.log("step count and sevice cancel");
    setServiceSumbmited(false);
    setProcessDetailsSubmited(false);
    setCreateNewProcess(false);
    setEditExistingProcess(false);

    setselectedServiceFromCategory("");
    setselectedSubCategory("");

    setCategorySelected(false);

    seteditProcessOrCreateProcess(false);

  }

  // Process selection
  const handleChangeProcessDropdown = (e) => {
    dispatch(userActions.checkSessionValidity());
    console.log("process selected",e.target.value)
    setselectedProcessToEdit(e.target.value);
    setNewProcessDetails({
      name: "",
      service: "",
      shortcode: "",
      status: true,
      description: "",
      version: 1,
      is_current: false,
    })
    setstepCount("");
    setProcessDetailsSubmited(false);
    setprocessNameErr("");
    setprocessShortcodeErr("");
    setprocessDescriptionErr("");
    setEditExistingProcess(true);
    setCreateNewProcess(false);
    seteditProcessOrCreateProcess(true);
    setShowProcedure(true);

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id, process_id: e.target.value,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_PROCESS_DETAILS , requestOptions)
      .then((Response) => Response.json())
      .then( async(processDetailsH) => {
        setShowProcedure(false);
        console.log("processDetailsH.data.process_details",processDetailsH.data.process_details);
        if(processDetailsH.success == true)
        {
          setshowAntSwitch(false);
          setExistingProcessDetails(processDetailsH.data.process_details)
          setshowAntSwitch(true);
        }
        else{
          setExistingProcessDetails({});
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

      const requestOptionsSteps = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ process_id: e.target.value,
          'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_PROCESS_STEPS , requestOptions)
        .then((Response) => Response.json())
        .then( async(processDetailsSteps) => {
          setShowProcedure(false);
          console.log("processDetailsSteps=",processDetailsSteps.data.steps_in_order);
          if(processDetailsSteps.success == true)
          {
            setExistingProcessStepDetails(processDetailsSteps.data.steps_in_order)
          }
          else{
            setExistingProcessStepDetails({});
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

  }


  // edit process
  const validateProcessEdit = () => {
    let processNameErr = '';
    let processShortcodeErr = '';
    let processDescriptionErr = '';

    if( !ExistingProcessDetails.name ){
      processNameErr = "Please Enter Process Name"
    }
    if( !ExistingProcessDetails.shortcode ){
      processShortcodeErr = "Please Enter Process Shortcode"
    }
    if( !ExistingProcessDetails.description ){
      processDescriptionErr = "Please Enter Process Description"
    }

    if( processNameErr || processShortcodeErr || processDescriptionErr ){
      setprocessNameErr(processNameErr);
      setprocessShortcodeErr(processShortcodeErr);
      setprocessDescriptionErr(processDescriptionErr);

      return false;
    }
    return true;
  }

  const handelSubmitEditProcess = () => {
    dispatch(userActions.checkSessionValidity());
    const isValid = validateProcessEdit();
    if(isValid){
      setShowProcedure(true);
      console.log("handel Submit Edit Process",ExistingProcessDetails);
      let ProcessDetailsToSubmit = {
        process_id: selectedProcessToEdit,
        service_id: JSON.parse(service_id),
        shortcode: ExistingProcessDetails.shortcode,
        name: ExistingProcessDetails.name,
        description: ExistingProcessDetails.description,
        status: ExistingProcessDetails.status,
        is_current: ExistingProcessDetails.is_current,
        version: ExistingProcessDetails.version,
        business_id: business_id,
        device_token: device_token,
        session_id: session_id,
      }
      console.log("ProcessDetailsToSubmit",ProcessDetailsToSubmit);

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(ProcessDetailsToSubmit )
      };
      fetch( process.env.REACT_APP_CREATE_PROCESS_URL , requestOptions)
      .then((Response) => Response.json())
      .then( async(processDetailsSubmited) => {
        setShowProcedure(false);
        console.log("processDetailsSubmited",processDetailsSubmited);
        if(processDetailsSubmited.success == true)
        {
          setshowAntSwitch(false);
          setExistingProcessDetails(processDetailsSubmited.data.process_info)
          setshowAntSwitch(true);
        }
        else{
          Swal.fire({
            icon: 'error',
            text: "Something went wrong",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    }
  }

  const handelCancelEditProcess = () => {
    dispatch(userActions.checkSessionValidity());
    console.log("handel Cancel EditProcess");
    setEditExistingProcess(false);
    setselectedProcessToEdit("");
    setprocessNameErr("");
    setprocessShortcodeErr("");
    setprocessDescriptionErr("");
    seteditProcessOrCreateProcess(false);
    setselectedProcessToEdit("");
  }

  // edit step
  const handleToggleAddAnotherStep = () => {
    setOpenMenuAddAnotherStep((prevOpenMenuAddAnotherStep) => !prevOpenMenuAddAnotherStep);
  }

  const handleAddAnotherStepInEdit  = async(place) => {
    console.log("add ste is clicked",place);
    await setStepActionErrMessage("");

    if( place == "Last" ){
      await setExistingProcessStepDetails(prevState => [...prevState, {
        // name: "New Step",
        name: "",
        process_id: parseInt(selectedProcessToEdit),
        step_id: "",
        is_review: false,
        todolist_id: "",
        tasks: [
          {
            item_id: "",
            item : "",
            is_compulsory: false,
            is_active: true,
          }
        ]
      }]);
    }
    else if( place == "First" ){
      await setExistingProcessStepDetails(prevState => [{
        // name: "New Step",
        name: "",
        process_id: parseInt(selectedProcessToEdit),
        step_id: "",
        is_review: false,
        todolist_id: "",
        tasks: [
          {
            item_id: "",
            item : "",
            is_compulsory: false,
            is_active: true,
          }
        ]
      }, ...prevState]);
    }
    else if( place == "AddAfterStep" ){

        if(afterStep <= ExistingProcessStepDetails.length && afterStep >= 1){
          // comment
          await setExistingProcessStepDetails(prevState => [...prevState.slice(0, parseInt(afterStep)), {
                // name: "New Step",
                name: "",
                process_id: parseInt(selectedProcessToEdit),
                step_id: "",
                is_review: false,
                todolist_id: "",
                tasks: [
                {
                    item_id: "",
                    item : "",
                    is_compulsory: false,
                    is_active: true,
                }
                ]
            }, ...prevState.slice(parseInt(afterStep), prevState.length)]);
        } else {
          await setStepActionErrMessage("Step cannot be added.");
        }

    }
    else if( place == "DeleteStep" ){
        if(deleteStep <= ExistingProcessStepDetails.length && deleteStep >= 1){
            if(ExistingProcessStepDetails.length !== 1){
              await setExistingProcessStepDetails(prevState => [...prevState.slice(0, parseInt(deleteStep)-1), ...prevState.slice(parseInt(deleteStep), prevState.length)]);
            } else {
              await setStepActionErrMessage("Step cannot be deleted.");
            }
        } else {
          await setStepActionErrMessage("Step cannot be deleted.");
        }

    }

  }

  const AddAnotherStepForEditStep = () => {
    console.log("add step for edit clicked");
    setopenNewStepForEditPlace(true);
  }

  const handelNewStepForEditPlaceClose = () => {
    setopenNewStepForEditPlace(false);
  }

  const BodyNewStepForEditPlace = (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}>
        <Grid container item xs={12}>
          <Grid item xs={11} >
              <h2 style={{marginLeft:'15%'}}>Where Do You Want to Add Step?</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'4%',}}>
              <CloseIcon onClick={()=>handelNewStepForEditPlaceClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
        </Grid>
      </div>
      <div className={classes.modalBody} style={{position:'absolute'}}>
        <Grid container>
        {/* ExistingProcessStepDetails */}
        <div>
        {
          ExistingProcessStepDetails && ExistingProcessStepDetails.map(( item, index ) => {
            <Grid>
            <RadioGroup
              aria-label="position"
              name="position"
              // value={selectedUserToAssignName}
              // value={item.userSelected == true ? item.name : null}
              // onChange={ (e) => handleChangeAssignUserRadio(e, item.user_id , item.name)}
            >
              <div className={classes.customRadioButton}>
                <FormControlLabel
                  value={item.name}
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label={item.name}
                />
              </div>
              </RadioGroup>
            </Grid>
          })
        }
        </div>

        </Grid>
        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',marginTop:'2%'}}>
          <Button
            type="submit"
            //   fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelNewStepForEditPlaceClose}
            >
              Add
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            // color="grey"
            className={classes.submit}
            onClick={handelNewStepForEditPlaceClose}
            >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )

  const EditStepClickedFunction = (isteps, indexsteps) => {
    dispatch(userActions.checkSessionValidity());
    console.log("handel Edit step clicked", isteps, indexsteps);
    setExistingProcessStepDetails(
      ExistingProcessStepDetails.map((f,i)=>{
        if(i == indexsteps){
          f.isEditable = true;
        }
        return f;
      })
    )

    setopenEditStep(true);
  }

  const handleChangeEditStep = (e, index) => {
    dispatch(userActions.checkSessionValidity());
    console.log("in handleChangeEditStep e, index",e.target.value, index)
    const { name, value } = e.target;
    setExistingProcessStepDetails(
      ExistingProcessStepDetails.map((f,i)=>{
        if(i == index){
          f = {...f, [name]: value};
        }
        return f;
      })
    )
  }

  const handleChangeEditStepTask = (e, index, indextask) => {
    dispatch(userActions.checkSessionValidity());
    console.log("in handleChangeEditStepTask e, index, indextask",e.target.value, index, indextask)
    const { name, value } = e.target;
    setExistingProcessStepDetails(
      ExistingProcessStepDetails.map((f,i) => {
        if(i == index){
          f.tasks[indextask] = {...f.tasks[indextask], [name]: value};
        }
        return f;
      })
    )
  }

  const handleIsCompulsoryEditStepChange = (itask,indextask) => {
    console.log("handle IsCompulsoryEditStepChange",itask,indextask)
    if( itask.is_compulsory == false){
      setExistingProcessStepDetails(
        ExistingProcessStepDetails.map((i, index) => {
          if(i.isEditable == true){
            i.tasks[indextask] = {...i.tasks[indextask], is_compulsory: true };

          }
          return i;
        })
      )
    }
    if( itask.is_compulsory == true){
      setExistingProcessStepDetails(
        ExistingProcessStepDetails.map((i, index) => {
          if(i.isEditable == true){
            i.tasks[indextask] = {...i.tasks[indextask], is_compulsory: false };

          }
          return i;
        })
      )
    }
  }

  const handleAddMoreTaskInEdit = (index) => {
    dispatch(userActions.checkSessionValidity());

    let toDoIdHere = "";
    ExistingProcessStepDetails.map((f,i)=>{
      if(index == i){
        f.tasks.map(( ftask, itask) => {
          if(ftask.todolist_id !== ""){
            toDoIdHere = ftask.todolist_id ;
          }
        })
      }
    })

    console.log("toDoIdHere", toDoIdHere);
    setExistingProcessStepDetails(
      ExistingProcessStepDetails.map((f,i) => {
        if(i == index){
          f.tasks= [...f.tasks,
            {
              item_id: "",
              item : "",
              item_type: "business",
              is_active: true,
              todolist_id: toDoIdHere,
              todolist_decription: "",
              todolist_name: "",
              is_compulsory: false,
            }
          ]
        }
        return f;
      })
    )
  }

  const handelRemoveTask = (index, indextask) => {
    dispatch(userActions.checkSessionValidity());
    setExistingProcessStepDetails(
      ExistingProcessStepDetails.map((f,i) => {
        if(i == index){
          f.tasks.splice(indextask, 1);
        }
        return f;
      })
    )
  }

  const handelEditStepClose = () => {
    dispatch(userActions.checkSessionValidity());
    setopenEditStep(false);
    setExistingProcessStepDetails(
      ExistingProcessStepDetails.map((f,i)=>{
        f.isEditable = false;
        return f;
      })
    )
    setstepNameEditErr("");
    settaskNameEditErr("");

    ExistingProcessStepDetails.map((i, index) =>{
      i.tasks.map((itask, indextask)=>{
        console.log("blank item to remove",itask)
        if( itask.item == '' && indextask !== 0 ) {
          i.tasks.splice(indextask)
        }
      })
    })
  }

  const validateStepEdit = () => {
    let stepNameEditErr = '';
    let taskNameEditErr = '';

    ExistingProcessStepDetails.map((itemStep, indexStep) => {
      if( itemStep.isEditable == true){
        if(itemStep.name == "" || itemStep.name == "<Undefined>"){
          stepNameEditErr = "Please Enter Step Name"
        }

        itemStep.tasks.map((itemtask, indextask) => {
          if(itemtask.item == ""){
            taskNameEditErr = "Please Enter Task Name"
          }
        })
      }
    })


    if( stepNameEditErr || taskNameEditErr ){
      setstepNameEditErr(stepNameEditErr);
      settaskNameEditErr(taskNameEditErr);

      return false;
    }
    return true;
  }

  const handelEditStepSubmit = () => {
    dispatch(userActions.checkSessionValidity());
    console.log("ExistingProcessStepDetails = ",ExistingProcessStepDetails);

    let checkCompulsaory = "";
    console.log("checkCompulsaory before",checkCompulsaory)
    ExistingProcessStepDetails.map((f) => {
      if( f.isEditable == true){
        console.log("f is",f)
        f.tasks.map((t)=>{
          console.log("t is",t)
          if(t.is_compulsory == true){
            checkCompulsaory = "true";
          }
        })
      }
    })
    console.log("checkCompulsaory after",checkCompulsaory);

    

      const isValid = validateStepEdit();
      if(isValid){
        if( checkCompulsaory == "true" ){
          setopenEditStep(false);
          console.log("handel Submit Edit Step",ExistingProcessStepDetails);

          setExistingProcessStepDetails(
              ExistingProcessStepDetails.map((f,i)=>{
                f.isEditable = false;
                return f;
              })
            )
        }
        else if(checkCompulsaory == ""){
          Swal.fire({
            icon: 'error',
            text: "Please Select At Least One Task As compulsory",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
          })
        }
      }
  }

  const BodyEditStep = (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}>
        <Grid container item xs={12}>
          {/* <Grid item xs={10} >
          </Grid> */}
          <Grid item xs={11} >
              <h2 style={{marginLeft:'35%'}}>Edit Step or Tasks</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'4%',}}>
              <CloseIcon onClick={()=>handelEditStepClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
        </Grid>
      </div>
      <div className={classes.modalBody} style={{position:'absolute'}}>
        {
          ExistingProcessStepDetails && ExistingProcessStepDetails.map((i, index) =>(
              ( i.isEditable == true )?
              (
                <Grid>
                  <Grid className={classes.subHeadingLabel}>
                    Service : {NameServiceSumbmited}
                  </Grid>

                  <Grid className={classes.subHeadingLabel}>
                    Process : {ExistingProcessDetails.name}
                  </Grid>

                  <Grid className={classes.ProcessLabel} style={{marginTop:'2%'}}>
                    Step Name*
                  </Grid>
                  <TextField
                    autoFocus
                    margin="normal"
                    required
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    // label="Step Name"
                    name="name"
                    value={i.name}
                    onChange={(e) => handleChangeEditStep(e, index) }
                  />
                  <div className={classes.validation}>{( !i.name || i.name == "<Undefined>")?(stepNameEditErr):(<div></div>)}</div>

                  {
                    i.tasks.map((itask, indextask)=>(
                      <Grid>
                        {
                          ( indextask == 0)?
                          (
                            <Grid container item xs={12}>
                              <Grid item xs={11} >
                                <Grid className={classes.ProcessLabel} style={{marginTop:'2%'}}>
                                  Task Name*
                                </Grid>
                                <TextField
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="outlined-basic"
                                  variant="outlined"
                                  // label="Task Name"
                                  name="item"
                                  value={itask.item}
                                  onChange={(e) => handleChangeEditStepTask(e, index, indextask) }
                                />
                                <div className={classes.validation}>{( !itask.item )?(taskNameEditErr):(<div></div>)}</div>
                              </Grid>
                              <Grid>
                                Is Task Compulsory : &nbsp;&nbsp;
                                <AntSwitch
                                  checked={itask.is_compulsory}
                                  onChange={() => handleIsCompulsoryEditStepChange(itask,indextask)}
                                  size="small"
                                  name="ExistingProcessDetails.is_current"
                                  value="ExistingProcessDetails.is_current"
                                  color="primary"
                                />
                              </Grid>
                            </Grid>
                          )
                          :(
                            <Grid container item xs={12}>
                              <Grid item xs={11}>
                                <Grid className={classes.ProcessLabel} style={{marginTop:'2%'}}>
                                  Task Name*
                                </Grid>
                                <TextField
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="outlined-basic"
                                  variant="outlined"
                                  // label="Task Name"
                                  name="item"
                                  value={itask.item}
                                  onChange={(e) => handleChangeEditStepTask(e, index, indextask) }
                                />
                                <div className={classes.validation}>{( !itask.item )?(taskNameEditErr):(<div></div>)}</div>
                              </Grid>
                              <Grid item xs={1}>
                                <CloseIcon
                                  onClick={() => handelRemoveTask(index, indextask)}
                                  style={{fontSize: 20, cursor:'pointer', marginTop:'35%'}}
                                />
                              </Grid>
                              <Grid>
                                Is Task Compulsory : &nbsp;&nbsp;
                                <AntSwitch
                                  checked={itask.is_compulsory}
                                  onChange={() => handleIsCompulsoryEditStepChange(itask,indextask)}
                                  size="small"
                                  name="ExistingProcessDetails.is_current"
                                  value="ExistingProcessDetails.is_current"
                                  color="primary"
                                />
                              </Grid>
                            </Grid>
                          )
                        }
                      </Grid>

                    ))
                  }
                  <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',
                      cursor:'pointer'}}
                      onClick={() => handleAddMoreTaskInEdit(index) }
                  >
                    {/* <Grid style={{color:'blue'}}>+ Add More Task</Grid> */}
                    <Grid style={{color:'blue'}}>+ Add Another Task</Grid>
                  </div>
                </Grid>
              )
              :(
                <Grid></Grid>
              )
          ))
        }
        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',marginTop:'2%'}}>
          <Button
            type="submit"
            //   fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelEditStepSubmit}
            >
              Update
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            // color="grey"
            className={classes.submit}
            onClick={handelEditStepClose}
            >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )

  const validateStepProcessEditSaveAsDraft = () => {
    let processNameErr = '';
    let processShortcodeErr = '';
    let processDescriptionErr = '';
    // let stepNameErr = '';
    // let taskNameErr = '';

    if( !ExistingProcessDetails.name ){
      processNameErr = "Please Enter Process Name"
    }
    if( !ExistingProcessDetails.shortcode ){
      processShortcodeErr = "Please Enter Process Shortcode"
    }
    if( !ExistingProcessDetails.description ){
      processDescriptionErr = "Please Enter Process Description"
    }

    // ExistingProcessStepDetails.map((itemStep, indexStep) => {
    //   if(itemStep.name == ""){
    //     stepNameErr = "Please Enter Step Name"
    //   }

    //   itemStep.tasks.map((itemtask, indextask) => {
    //     if(itemtask.item == ""){
    //       taskNameErr = "Please Enter Task Name"
    //     }
    //   })
    // })

    if( processNameErr || processShortcodeErr || processDescriptionErr){
      setprocessNameErr(processNameErr);
      setprocessShortcodeErr(processShortcodeErr);
      setprocessDescriptionErr(processDescriptionErr);
      // setstepNameErr(stepNameErr);
      // settaskNameErr(taskNameErr);

      return false;
    }
    return true;
  }

  const handelSubmitEditStepProcess = () => {
    dispatch(userActions.checkSessionValidity());
    const isValid = validateStepProcessEditSaveAsDraft();
    if(isValid){

      if ( ShortCodeAlreadyExistForEditProcessErr == "") {
        console.log("handel Submi EditStepProcess", ExistingProcessStepDetails);
        console.log("handel Submit Edit Process",ExistingProcessDetails);
  
        let ProcessDetailsToSubmit = {
          process_id: parseInt(selectedProcessToEdit),
          service_id: JSON.parse(service_id),
          shortcode: ExistingProcessDetails.shortcode,
          name: ExistingProcessDetails.name,
          description: ExistingProcessDetails.description,
          status: ExistingProcessDetails.status,
          is_current: ExistingProcessDetails.is_current,
          version: ExistingProcessDetails.version,
          business_id: business_id,
          device_token: device_token,
          session_id: session_id,
        }
        console.log("ProcessDetailsToSubmit",ProcessDetailsToSubmit);
  
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(ProcessDetailsToSubmit )
        };
        fetch( process.env.REACT_APP_CREATE_PROCESS_URL , requestOptions)
        .then((Response) => Response.json())
        .then( async(processDetailsSubmited) => {
          setShowProcedure(false);
          console.log("processDetailsSubmited edit",processDetailsSubmited,ExistingProcessStepDetails);
          if(processDetailsSubmited.success == true)
          {
            setshowAntSwitch(false);
            setExistingProcessDetails(processDetailsSubmited.data.process_info);
            setshowAntSwitch(true);
            let ProcessStepDetailsToSubmit = {
              process_id: processDetailsSubmited.data.process_info.process_id,
              process_type: "old",
              steps_in_order: ExistingProcessStepDetails,
              device_token: device_token,
              session_id: session_id,
            }
            console.log("ProcessStepDetailsToSubmit",ProcessStepDetailsToSubmit);
  
            const requestOptionsSteps = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify(ProcessStepDetailsToSubmit)
            };
            fetch( process.env.REACT_APP_CREATE_PROCESS_STEPS_AND_TASKS , requestOptionsSteps)
            .then((Response) => Response.json())
            .then( async(processStepDetailsSubmited) => {
              setShowProcedure(false);
              console.log("processStepDetailsSubmited edit",processStepDetailsSubmited);
  
              if(processStepDetailsSubmited.success == true)
              {
                console.log("processStepDetailsSubmited",processStepDetailsSubmited.data);
                // setExistingProcessStepDetails(processStepDetailsSubmited.data.process_info)
                Swal.fire({
                  icon: 'success',
                  text: "Process details updated Successfully",
                  confirmButtonColor: 'primary',
                  confirmButtonText: 'OK'
                })
  
                setEditExistingProcess(false);
                setExistingProcessStepDetails([]);
                setExistingProcessDetails([]);
                seteditProcessOrCreateProcess(false);
                setselectedProcessToEdit("");
                setProcessDetailsSubmited(false);
  
                setstepNameErr("");
                settaskNameErr("");
                settaskCompulsaryNotSlectedErr("");
  
                setprocessNameErr("");
                setprocessShortcodeErr("");
                setprocessDescriptionErr("");
  
                setAfterStep(1)
                setDeleteStep(1)
  
                setFlagProcess(false);
              }
              else{
                Swal.fire({
                  icon: 'error',
                  text: processStepDetailsSubmited.error,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              }
            })
            .catch(err => {
              Swal.fire({
                icon: 'error',
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
          }
          else{
            Swal.fire({
              icon: 'error',
              text: processDetailsSubmited.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
      } 
      else{
        Swal.fire({
          // icon: 'error',
          // text: "Something went wrong",
          text: "Please change the shortcode",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      }
    }
    else{
      Swal.fire({
        icon: 'error',
        text: "You have missed some process fields",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    }
  }

  const validateStepProcessEdit = () => {
    let processNameErr = '';
    let processShortcodeErr = '';
    let processDescriptionErr = '';
    let stepNameErr = '';
    let taskNameErr = '';
    let taskCompulsaryNotSlectedErr = '';

    if( !ExistingProcessDetails.name ){
      processNameErr = "Please Enter Process Name"
    }
    if( !ExistingProcessDetails.shortcode ){
      processShortcodeErr = "Please Enter Process Shortcode"
    }
    if( !ExistingProcessDetails.description ){
      processDescriptionErr = "Please Enter Process Description"
    }

    ExistingProcessStepDetails.map((itemStep, indexStep) => {
      console.log("step name in validation =========",itemStep.name, itemStep.atLeastOneCompulsary)
      if(itemStep.name == "" || itemStep.name ==  "New Step" || itemStep.name ==  "<Undefined>"){
        stepNameErr = "Please Enter Step Name"
      }

      if(itemStep.atLeastOneCompulsary){
        console.log("settaskCompulsaryNotSlectedErr",itemStep.atLeastOneCompulsary)
      }
      else if(itemStep.atLeastOneCompulsary == undefined){
        console.log("settaskCompulsaryNotSlectedErr",itemStep.atLeastOneCompulsary)
        taskCompulsaryNotSlectedErr = "Please select at least one task as compulsory"
      }

      itemStep.tasks.map((itemtask, indextask) => {
        console.log("task name in validation =========",itemtask)
        if(itemtask.item == ""){
          taskNameErr = "Please Enter Task Name"
        }
      })
    })

    if( processNameErr || processShortcodeErr || processDescriptionErr || stepNameErr || 
        taskNameErr || taskCompulsaryNotSlectedErr){
      setprocessNameErr(processNameErr);
      setprocessShortcodeErr(processShortcodeErr);
      setprocessDescriptionErr(processDescriptionErr);
      setstepNameErr(stepNameErr);
      settaskNameErr(taskNameErr);
      settaskCompulsaryNotSlectedErr(taskCompulsaryNotSlectedErr);

      return false;
    }
    return true;
  }

  // const handlePublishProcess = () => {
   
  //   // handlePublishProcess2();
  // }

  const handlePublishProcess = () => {

    console.log("ExistingProcessStepDetails in publish",ExistingProcessStepDetails)
    // to check every steps todo has one cmplsry task
    setExistingProcessStepDetails(
      ExistingProcessStepDetails.map((f) => {
        console.log("in handle publish single step is",f)
        f.tasks.map((ftask)=>{
          console.log("in handle publish single task is",ftask)
          if(ftask.is_compulsory == true){
            f.atLeastOneCompulsary = true;
            console.log("in handle publish single task is compulsary")
          }
        })
        return f;
      })
    )
    console.log("ExistingProcessStepDetails in publish after",ExistingProcessStepDetails)

    const isValid = validateStepProcessEdit();
    if(isValid){

      if ( ShortCodeAlreadyExistForEditProcessErr == "") {

        // save as draft
        let ProcessDetailsToSubmit = {
          process_id: parseInt(selectedProcessToEdit),
          service_id: JSON.parse(service_id),
          shortcode: ExistingProcessDetails.shortcode,
          name: ExistingProcessDetails.name,
          description: ExistingProcessDetails.description,
          status: ExistingProcessDetails.status,
          is_current: ExistingProcessDetails.is_current,
          version: ExistingProcessDetails.version,
          business_id: business_id,
          device_token: device_token,
          session_id: session_id,
        }
        console.log("ProcessDetailsToSubmit",ProcessDetailsToSubmit);
  
        const requestOptionsSaveAsDraft = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(ProcessDetailsToSubmit )
        };
        fetch( process.env.REACT_APP_CREATE_PROCESS_URL , requestOptionsSaveAsDraft)
        .then((Response) => Response.json())
        .then( async(processDetailsSubmited) => {
          if( processDetailsSubmited.success == true ){
            let ProcessStepDetailsToSubmit = {
              process_id: processDetailsSubmited.data.process_info.process_id,
              process_type: "old",
              steps_in_order: ExistingProcessStepDetails,
              device_token: device_token,
              session_id: session_id,
            }
            console.log("ProcessStepDetailsToSubmit",ProcessStepDetailsToSubmit);
            const requestOptionsSteps = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify(ProcessStepDetailsToSubmit)
            };
            fetch( process.env.REACT_APP_CREATE_PROCESS_STEPS_AND_TASKS , requestOptionsSteps)
            .then((Response) => Response.json())
            .then( async(processStepDetailsSubmited) => {
              setShowProcedure(false);
              console.log("processStepDetailsSubmited edit",processStepDetailsSubmited);
  
              if(processStepDetailsSubmited.success == true)
              {
                console.log("processStepDetailsSubmited",processStepDetailsSubmited.data);
                
                // publish api
                const requestOptions = {
                  method: 'POST',
                  headers: authHeader(),
                  body: JSON.stringify({'process_id': parseInt(selectedProcessToEdit),
                  'device_token': device_token, 'session_id': session_id })
                };
                fetch( process.env.REACT_APP_MAKE_PROCESS_LIVE , requestOptions)
                .then((Response) => Response.json())
                .then( processLiveData => {
                    console.log(processLiveData, "process live");
                    if(processLiveData.success){
                        Swal.fire({
                          icon: 'success',
                          text: "Process made live.",
                          confirmButtonColor: 'primary',
                          confirmButtonText: 'OK'
                        })
          
                        setEditExistingProcess(false);
                        setExistingProcessStepDetails([]);
                        setExistingProcessDetails([]);
                        seteditProcessOrCreateProcess(false);
                        setselectedProcessToEdit("");
                        setProcessDetailsSubmited(false);
          
                        setFlagProcess(false);
          
                      } else {
                        Swal.fire({
                          icon: 'error',
                          text: processLiveData.errors.toString(),
                          confirmButtonColor: 'primary',
                          confirmButtonText: 'OK'
                        })
                      }
                })
                .catch(err => {
                  Swal.fire({
                    icon: 'error',
                    // text: "Something went wrong",
                    text: "Server Error. Please try again.",
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                  })
                });
  
                setEditExistingProcess(false);
                setExistingProcessStepDetails([]);
                setExistingProcessDetails([]);
                seteditProcessOrCreateProcess(false);
                setselectedProcessToEdit("");
                setProcessDetailsSubmited(false);
  
                setstepNameErr("");
                settaskNameErr("");
                settaskCompulsaryNotSlectedErr("");
  
                setprocessNameErr("");
                setprocessShortcodeErr("");
                setprocessDescriptionErr("");
  
                setAfterStep(1)
                setDeleteStep(1)
  
                setFlagProcess(false);
              }
              else{
                Swal.fire({
                  icon: 'error',
                  text: processStepDetailsSubmited.error,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              }
            })
            .catch(err => {
              Swal.fire({
                icon: 'error',
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
          }
          else{
            Swal.fire({
              icon: 'error',
              text: processDetailsSubmited.errors,
              confirmButtonColor: 'primary',
              confirmButtonText: 'OK'
            })
          }

        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

        
      }
      else{
        Swal.fire({
          // icon: 'error',
          // text: "Something went wrong",
          text: "Please change the shortcode",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      }
      
    }
  }

  const handelCancelEditStepProcess = () => {
    dispatch(userActions.checkSessionValidity());

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: classes.buttonSwalDelete,
        cancelButton: classes.buttonSwalCancel,
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      // title: 'Are you sure?',
      // text: "You won't be able to revert this. All data will clear",
      text: "Your data will be cleared",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        setEditExistingProcess(false);
        setstepNameErr("");
        settaskCompulsaryNotSlectedErr("");
        settaskNameErr("");
        setprocessNameErr("");
        setprocessShortcodeErr("");
        setprocessDescriptionErr("");
        setProcessDetailsSubmited(false);
        seteditProcessOrCreateProcess(false);
        setselectedProcessToEdit("");      
        setAfterStep(1)
        setDeleteStep(1)
      } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
        ) 
        {
          // swalWithBootstrapButtons.fire(
          //   'Cancelled',
          //   'Your imaginary file is safe :)',
          //   'error'
          // )
        }
      })
  }

  // process creation
  //
  const handleCreateNewProcess = () => {
    dispatch(userActions.checkSessionValidity());
    setCreateNewProcess(true);
    setEditExistingProcess(false);
    setselectedProcessToEdit("");
    seteditProcessOrCreateProcess(true);
  }

  const handelCancelEditorCreateProcessSelect = () => {
    dispatch(userActions.checkSessionValidity());
    seteditProcessOrCreateProcess(false);
    setselectedProcessToEdit("");
    setEditExistingProcess(false);
    setCreateNewProcess(false);
    setProcessDetailsSubmited(false);

    setShortCodeAlreadyExistForNewProcessErr("")
    setShortCodeAlreadyExistForEditProcessErr("")
    settaskNameErr("")
    setstepNameErr("")
    settaskCompulsaryNotSlectedErr("")


    setNewProcessDetails({
      name: "",
      service: "",
      shortcode: "",
      status: true,
      description: "",
      version: 1,
      is_current: false,
    })
    setstepCount("");

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
          'process_type': 'draft',
          'service_id': service_id,
          'device_token': device_token, 
          'session_id': session_id 
      })
    };
    fetch( process.env.REACT_APP_GET_SERVICE_PROCESS_URL , requestOptions)
    .then((Response) => Response.json())
    .then( async(processDetails) => {
      setShowProcedure(false);
      console.log("processDetails",processDetails);
      if(processDetails.success == true)
      {
        setProcessDetailsList(processDetails.data.processes);
      }
      else{
        setProcessDetailsList([]);
      }
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });
  }

  const validateProcess = () => {
    let processNameErr = '';
    let processShortcodeErr = '';
    let processDescriptionErr = '';
    let stepCountErr = '';

    if( !NewProcessDetails.name ){
      processNameErr = "Please Enter Process Name"
    }
    if( !NewProcessDetails.shortcode ){
      processShortcodeErr = "Please Enter Process Shortcode"
    }
    if( !NewProcessDetails.description ){
      processDescriptionErr = "Please Enter Process Description"
    }

    if( !stepCount ){
      stepCountErr = "Please Enter Step Count"
    }
    else if(stepCount){
      if( stepCount < 1 ){
        stepCountErr = "Step Count Must be Greater than 1"
      }
      if( stepCount >= 12){
        stepCountErr = "Step Count Must be Less than 12"
      }
    }

    if( processNameErr || processShortcodeErr || processDescriptionErr || stepCountErr ){
      setprocessNameErr(processNameErr);
      setprocessShortcodeErr(processShortcodeErr);
      setprocessDescriptionErr(processDescriptionErr);
      setstepCountErr(stepCountErr);

      return false;
    }
    return true;
  }

  const handelSubmitCreateNewProcess = () => {
    dispatch(userActions.checkSessionValidity());
    const isValid = validateProcess();
    if(isValid){

      if( ShortCodeAlreadyExistForNewProcessErr == "" ){
        console.log("create process clicked NewProcessDetails",NewProcessDetails);
        setProcessDetailsSubmited(true);

        // setShowProcedure(true);
        // console.log("handel Submit create Process",NewProcessDetails);
        // let ProcessDetailsToSubmit = {
        //   service_id: JSON.parse(service_id),
        //   shortcode: NewProcessDetails.shortcode,
        //   name: NewProcessDetails.name,
        //   description: NewProcessDetails.description,
        //   status: NewProcessDetails.status,
        //   is_current: NewProcessDetails.is_current,
        //   version: NewProcessDetails.version,
        //   business_id: business_id,
        //   device_token: device_token,
        //   session_id: session_id,
        // }
        // console.log("ProcessDetailsToSubmit new",ProcessDetailsToSubmit);

        // const requestOptions = {
        //   method: 'POST',
        //   headers: authHeader(),
        //   body: JSON.stringify(ProcessDetailsToSubmit )
        // };
        // fetch(' process.env.REACT_APP_CREATE_PROCESS_URL ', requestOptions)
        // .then((Response) => Response.json())
        // .then( async(processDetailsSubmited) => {
        //   setShowProcedure(false);
        //   console.log("processDetailsSubmited",processDetailsSubmited);
        //   if(processDetailsSubmited.success == true)
        //   {
        //     setExistingProcessDetails(processDetailsSubmited.data.process_info)
        //   }
        //   else{
        //     Swal.fire({
        //       icon: 'error',
        //       text: "Something went wrong",
        //       confirmButtonColor: '#d33',
        //       confirmButtonText: 'OK'
        //     })
        //   }
        // })
        // .catch(err => {
        //   Swal.fire({
        //     icon: 'error',
        //     text: "Server Error. Please try again.",
        //     confirmButtonColor: '#d33',
        //     confirmButtonText: 'OK'
        //   })
        // });
      }
      else{
        Swal.fire({
          // icon: 'error',
          // text: "Something went wrong",
          text: "Please change the shortcode",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      }

    }
  }

  const handleExistingProcessDetailsShortcode = async(value) =>{
    console.log("handleExistingProcessDetailsShortcode", value)
    await setExistingProcessDetails({...ExistingProcessDetails, shortcode:value })

    if ( value !== "" ){
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'shortcode': value, 'entity_type': "process", 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
      };
      return fetch( process.env.REACT_APP_SHORTCODE_VALIDATION, requestOptions)
      .then((Response) => Response.json())
      .then( checkShortcode => {
        console.log("checkShortcode is valid or not",checkShortcode)
        console.log("checkShortcode is valid or not",checkShortcode.data.is_valid)
        if( checkShortcode.success == true ){
          if( checkShortcode.data.is_valid == false ){
            setShortCodeAlreadyExistForEditProcessErr("Short code already Exist")
          }
          else if( checkShortcode.data.is_valid == true ){
            setShortCodeAlreadyExistForEditProcessErr("")
          }
        }
      })
    }

  }

  const handleIsCurrentSwitch = async() => {
    dispatch(userActions.checkSessionValidity());
    console.log("in handleIsCurrentSwitch")

    await setExistingProcessDetails({...ExistingProcessDetails, is_current: !ExistingProcessDetails.is_current })
    await setshowAntSwitch(false)
    await setshowAntSwitch(true)
    await setFlag(true)
  }

  const handelCancelCreateNewProcess = () => {
    dispatch(userActions.checkSessionValidity());
    console.log("cancel process clicked NewProcessDetails",NewProcessDetails);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: classes.buttonSwalDelete,
        cancelButton: classes.buttonSwalCancel,
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "Your data will be cleared",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        setProcessDetailsSubmited(false);
        setprocessNameErr("");
        setprocessShortcodeErr("");
        setprocessDescriptionErr("");
        setstepCountErr("");
        setShortCodeAlreadyExistForNewProcessErr("");

        setNewProcessDetails({
          name: "",
          service: "",
          shortcode: "",
          status: true,
          description: "",
          version: 1,
          is_current: false,
        })
        setstepCount("");
                    
      } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
        ) 
        {
          // swalWithBootstrapButtons.fire(
          //   'Cancelled',
          //   'Your imaginary file is safe :)',
          //   'error'
          // )
        }
      })
  }

  // new step creation
  const CreateNewStepStepClickedFunction = (i, index) => {
    dispatch(userActions.checkSessionValidity());
    console.log("i of selected step",i, index)
    setStepsToCreate(
      StepsToCreate.map((istep, insexstep)=>{
        if(insexstep == index){
          istep.isEditable = true;
        }
        return istep;
      })
    )

    setopenCreateStep(true);
  }

  const handelCreateStepClose = () => {
    dispatch(userActions.checkSessionValidity());
    setopenCreateStep(false);
    setStepsToCreate(
      StepsToCreate.map((istep, insexstep)=>{
        istep.isEditable = false;
        return istep;
      })
    )
    setFlag(true);
    setstepNameCreateErr("");
    settaskNameCreateErr("");

    StepsToCreate.map((i, index) =>{
      i.tasks.map((itask, indextask)=>{
        console.log("blank item to remove",itask)
        if( itask.item == '' && indextask !== 0 ) {
          i.tasks.splice(indextask)
        }
      })
    })
  }

  const handleChangeCreateStep = (e, index) => {
    dispatch(userActions.checkSessionValidity());
    console.log("in handleChangeCreateStep e, index",e.target.value, index)
    const { name, value } = e.target;
    setStepsToCreate(
      StepsToCreate.map((f,i)=>{
        if(i == index){
          f = {...f, [name]: value};
        }
        return f;
      })
    )
  }

  const handleChangeCreateStepTask = (e, index, indextask) => {
    dispatch(userActions.checkSessionValidity());
    console.log("in handleChangeCreateStepTask e, index, indextask",e.target.value, index, indextask)
    const { name, value } = e.target;
    setStepsToCreate(
      StepsToCreate.map((f,i) => {
        if(i == index){
          f.tasks[indextask] = {...f.tasks[indextask], [name]: value};
        }
        return f;
      })
    )
  }

  // StepsToCreate && StepsToCreate.map((i, index) =>(
  const handleIsCompulsoryChange = (itask, indextask) => {
    console.log("handle IsCompulsoryChange",indextask,itask)
    if( itask.is_compulsory == false){
      setStepsToCreate(
        StepsToCreate.map((i, index) => {
          if(i.isEditable == true){
            i.tasks[indextask] = {...i.tasks[indextask], is_compulsory: true };

          }
          return i;
        })
      )
    }
    if( itask.is_compulsory == true){
      setStepsToCreate(
        StepsToCreate.map((i, index) => {
          if(i.isEditable == true){
            i.tasks[indextask] = {...i.tasks[indextask], is_compulsory: false };

          }
          return i;
        })
      )
    }
  }

  const handelRemoveNewTask = (index, indextask) => {
    dispatch(userActions.checkSessionValidity());
    setStepsToCreate(
      StepsToCreate.map((f,i) => {
        if(i == index){
          f.tasks.splice(indextask, 1);
        }
        return f;
      })
    )
  }

  const handleAddMoreTaskInNew = (index) => {
    dispatch(userActions.checkSessionValidity());
    setStepsToCreate(
      StepsToCreate.map((f,i) => {
        if(i == index){
          f.tasks= [...f.tasks,
            {
              item_id: "",
              item : "",
              is_compulsory: false,
              item_type: "business",
              is_active: true,
              // todolist_id: "",
              // todolist_decription: "",
              // todolist_name: "",
            }
          ]
        }
        return f;
      })
    )
  }

  const validateStepCreate = () => {
    let stepNameCreateErr = '';
    let taskNameCreateErr = '';
    console.log(" in validation validateStepCreate")
    StepsToCreate.map((itemStep, indexStep) => {
      if( itemStep.isEditable == true){
        console.log(" in validation validateStepCreate itemStep.isEditable == true",itemStep)
        if(itemStep.name == "" || itemStep.name == "<Undefined>"){
          stepNameCreateErr = "Please Enter Step Name"
        }

        itemStep.tasks.map((itemtask, indextask) => {
          if(itemtask.item == ""){
            taskNameCreateErr = "Please Enter Task Name"
          }
        })
      }
    })


    if( stepNameCreateErr || taskNameCreateErr ){
      setstepNameCreateErr(stepNameCreateErr);
      settaskNameCreateErr(taskNameCreateErr);

      return false;
    }
    return true;
  }

  const handelCreateNewStepSubmit = () => {
    dispatch(userActions.checkSessionValidity());
    const isValid = validateStepCreate();

    let checkCompulsaory = "";
    console.log("checkCompulsaory before",checkCompulsaory)
    StepsToCreate.map((f) => {
      if( f.isEditable == true){
        console.log("f is",f)
        f.tasks.map((t)=>{
          console.log("t is",t)
          if(t.is_compulsory == true){
            checkCompulsaory = "true";
          }
        })
      }
    })
    console.log("checkCompulsaory after",checkCompulsaory)

      if(isValid){
        if( checkCompulsaory == "true" ){
          console.log("handelCreateNewStepSubmit clicked", StepsToCreate )
          setopenCreateStep(false);
          setStepsToCreate(
            StepsToCreate.map((istep, insexstep)=>{
              istep.isEditable = false;
              return istep;
            })
          )
        }
        else if(checkCompulsaory == ""){
          Swal.fire({
            icon: 'error',
            text: "Please Select At Least One Task As compulsory",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
          })
        }
      }
    

  }

  const BodyCreateStep = (
    <div className={classes.paperModalCreateStepandTasks}>
      <div className={classes.addNewBusinessHeaderBackgroud}>
        <Grid container item xs={12}>
            <Grid item xs={11} >
              <h2 style={{marginLeft:'32%'}}>Create Step and Tasks</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'4%',}}>
              <CloseIcon onClick={()=>handelCreateStepClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
        </Grid>
      </div>
      <div className={classes.modalBody} style={{position:'absolute'}}>
        {
          StepsToCreate && StepsToCreate.map((i, index) =>(
              ( i.isEditable == true )?
              (
                <Grid>
                  <Grid className={classes.subHeadingLabel}>
                    Service : {NameServiceSumbmited}
                  </Grid>

                  <Grid className={classes.subHeadingLabel}>
                    Process : {NewProcessDetails.name}
                  </Grid>

                  <Grid className={classes.ProcessLabel} style={{marginTop:'4%'}}>
                    Step Name*
                  </Grid>
                  <TextField
                    autoFocus
                    margin="normal"
                    required
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    // label="Step Name"
                    name="name"
                    value={i.name}
                    onChange={(e) => handleChangeCreateStep(e, index) }
                  />
                  <div className={classes.validation}>{( !i.name || i.name == "<Undefined>" )?(stepNameCreateErr):(<div></div>)}</div>

                  {
                    i.tasks.map((itask, indextask)=>(
                      <Grid>
                        {
                          ( indextask == 0)?
                          (
                            <Grid container item xs={12}>
                              <Grid item xs={11} >
                                <Grid className={classes.ProcessLabel} style={{marginTop:'2%'}}>
                                  Task Name*
                                </Grid>
                                <TextField
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="outlined-basic"
                                  variant="outlined"
                                  // label="Task Name"
                                  name="item"
                                  value={itask.item}
                                  onChange={(e) => handleChangeCreateStepTask(e, index, indextask) }
                                />
                                <div className={classes.validation}>{( !itask.item )?(taskNameCreateErr):(<div></div>)}</div>
                              </Grid>
                              <Grid>
                                Is Task Compulsory : &nbsp;&nbsp;
                                <AntSwitch
                                  checked={itask.is_compulsory}
                                  onChange={() => handleIsCompulsoryChange(itask,indextask)}
                                  size="small"
                                  name="ExistingProcessDetails.is_current"
                                  value="ExistingProcessDetails.is_current"
                                  color="primary"
                                />
                              </Grid>
                            </Grid>
                          )
                          :(
                            <Grid container item xs={12}>
                              <Grid item xs={11}>
                                <Grid className={classes.ProcessLabel} style={{marginTop:'2%'}}>
                                  Task Name*
                                </Grid>
                                <TextField
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="outlined-basic"
                                  variant="outlined"
                                  // label="Task Name"
                                  name="item"
                                  value={itask.item}
                                  onChange={(e) => handleChangeCreateStepTask(e, index, indextask) }
                                />
                                <div className={classes.validation}>{( !itask.item )?(taskNameCreateErr):(<div></div>)}</div>
                              </Grid>
                              <Grid item xs={1}>
                                <CloseIcon
                                  onClick={() => handelRemoveNewTask(index, indextask)}
                                  style={{fontSize: 20, cursor:'pointer', marginTop:'35%'}}
                                />
                              </Grid>
                              <Grid>
                                Is Task Compulsory : &nbsp;&nbsp;
                                <AntSwitch
                                  checked={itask.is_compulsory}
                                  onChange={() => handleIsCompulsoryChange(itask,indextask)}
                                  size="small"
                                  name="ExistingProcessDetails.is_current"
                                  value="ExistingProcessDetails.is_current"
                                  color="primary"
                                />
                              </Grid>
                            </Grid>
                          )
                        }
                      </Grid>

                    ))
                  }

                  <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',
                      cursor:'pointer'}}
                      onClick={() => handleAddMoreTaskInNew(index) }
                  >
                    {/* <Grid style={{color:'blue'}}>+ Add More Task</Grid> */}
                    <Grid style={{color:'blue'}}>+ Add Another Task</Grid>
                  </div>

                </Grid>
              )
              :(
                <Grid></Grid>
              )
          ))
        }
        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',marginTop:'8%'}}>
          <Button
            type="submit"
            //   fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelCreateNewStepSubmit}
            >
              Submit
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            // color="grey"
            className={classes.submit}
            onClick={handelCreateStepClose}
            >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )

  const validateStepProcessNew = () => {
    let processNameErr = '';
    let processShortcodeErr = '';
    let processDescriptionErr = '';
    let stepCountErr = '';
    // let stepNameNewErr = '';
    // let taskNameNewErr = '';

    if( !NewProcessDetails.name ){
      processNameErr = "Please Enter Process Name"
    }
    if( !NewProcessDetails.shortcode ){
      processShortcodeErr = "Please Enter Process Shortcode"
    }
    if( !NewProcessDetails.description ){
      processDescriptionErr = "Please Enter Process Description"
    }
    if( !stepCount ){
      stepCountErr = "Please Enter Step Count"
    }
    else if(stepCount){
      if( stepCount < 1 ){
        stepCountErr = "Step Count Must be Greater than 1"
      }
      if( stepCount >= 12){
        stepCountErr = "Step Count Must be Less than 12"
      }
    }

    // StepsToCreate.map((itemStep, indexStep) => {
    //   if(itemStep.name == ""){
    //     stepNameNewErr = "Please Enter Step Name"
    //   }

    //   itemStep.tasks.map((itemtask, indextask) => {
    //     if(itemtask.item == ""){
    //       taskNameNewErr = "Please Enter Task Name"
    //     }
    //   })
    // })

    if( processNameErr || processShortcodeErr || processDescriptionErr || stepCountErr ){
      setprocessNameErr(processNameErr);
      setprocessShortcodeErr(processShortcodeErr);
      setprocessDescriptionErr(processDescriptionErr);
      setstepCountErr(stepCountErr);
      // setstepNameNewErr(stepNameNewErr);
      // settaskNameNewErr(taskNameNewErr);

      return false;
    }
    return true;
  }

  const handelSubmitCreateStepProcess = () => {
    dispatch(userActions.checkSessionValidity());

    // let checkCompulsaory = "";
    // console.log("checkCompulsaory before",checkCompulsaory)
    // StepsToCreate.map((f) => {
    //   console.log("f is",f)
    //     f.tasks.map((t)=>{
    //       console.log("t is",t)
    //       if(t.is_compulsory == true){
    //         checkCompulsaory = "true";
    //       }
    //     })
    // })
    // console.log("checkCompulsaory after",checkCompulsaory)


    const isValid = validateStepProcessNew();
    if(isValid){
      if( ShortCodeAlreadyExistForNewProcessErr == "" ){
        console.log("handel Submi new StepProcess", StepsToCreate );
        console.log("create process clicked NewProcessDetails",NewProcessDetails);
        setProcessDetailsSubmited(true);
  
        setShowProcedure(true);
        console.log("handel Submit create Process",NewProcessDetails);
        let ProcessDetailsToSubmit = {
          service_id: JSON.parse(service_id),
          shortcode: NewProcessDetails.shortcode,
          name: NewProcessDetails.name,
          description: NewProcessDetails.description,
          status: NewProcessDetails.status,
          is_current: NewProcessDetails.is_current,
          version: NewProcessDetails.version,
          business_id: business_id,
          device_token: device_token,
          session_id: session_id,
          process_id : "",
        }
        console.log("ProcessDetailsToSubmit new",ProcessDetailsToSubmit);
  
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(ProcessDetailsToSubmit )
        };
        fetch( process.env.REACT_APP_CREATE_PROCESS_URL , requestOptions)
        .then((Response) => Response.json())
        .then( async(processDetailsSubmited) => {
          setShowProcedure(false);
          console.log("processDetailsSubmited new",processDetailsSubmited);
          if(processDetailsSubmited.success == true)
          {
            setExistingProcessDetails(processDetailsSubmited.data.process_info)
  
            let ProcessStepDetailsToSubmit = {
              process_id: processDetailsSubmited.data.process_info.process_id,
              process_type: "new",
              steps_in_order: StepsToCreate,
              device_token: device_token,
              session_id: session_id,
            }
            console.log("ProcessStepDetailsToSubmit new",ProcessStepDetailsToSubmit);
  
            const requestOptionsSteps = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify(ProcessStepDetailsToSubmit)
            };
            fetch( process.env.REACT_APP_CREATE_PROCESS_STEPS_AND_TASKS , requestOptionsSteps)
            .then((Response) => Response.json())
            .then( async(processStepDetailsSubmited) => {
              setShowProcedure(false);
              console.log("processStepDetailsSubmited",processStepDetailsSubmited);
              if(processStepDetailsSubmited.success == true)
              {
                console.log("processStepDetailsSubmited",processStepDetailsSubmited.data);
                // ExistingProcessStepDetails(processStepDetailsSubmited.data.process_info)
                Swal.fire({
                  icon: 'success',
                  text: "Process Created Successfully",
                  confirmButtonColor: 'primary',
                  confirmButtonText: 'OK'
                })
                setNewProcessDetails({
                  name: "",
                  service: "",
                  shortcode: "",
                  status: true,
                  description: "",
                  version: 1,
                  is_current: false,
                })
                setstepCount("");
                setCreateNewProcess(false);
                seteditProcessOrCreateProcess(false);
                setselectedProcessToEdit("");
                setProcessDetailsSubmited(false);
                setFlagProcess(false);
                setstepNameErr("");
                settaskCompulsaryNotSlectedErr("");
                settaskNameErr("");
                setprocessNameErr("");
                setprocessShortcodeErr("");
                setprocessDescriptionErr("");
                setShortCodeAlreadyExistForNewProcessErr("");
                setAfterStep(1)
                setDeleteStep(1)
              }
              else{
                Swal.fire({
                  icon: 'error',
                  text: processStepDetailsSubmited.error,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              }
            })
            .catch(err => {
              Swal.fire({
                icon: 'error',
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
  
          }
          else{
            Swal.fire({
              icon: 'error',
              text: processDetailsSubmited.errors.toString(),
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
      }
      else{
        Swal.fire({
          // icon: 'error',
          // text: "Something went wrong",
          text: "Please change the shortcode",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      }
      
      
    }
  }

  const handelSaveAsDraftCreateStepProcess = () => {
    console.lof("Save as draft new clicked")
  }

  const handelCancelCreateStepProcess = () => {
    dispatch(userActions.checkSessionValidity());

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: classes.buttonSwalDelete,
        cancelButton: classes.buttonSwalCancel,
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "Your data will be cleared",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

        StepsToCreate.map((item, index)=>{
          console.log("item step to create cancel", item)
          item.name = ""
          item.tasks.map(( itemtask, indextask )=>{
            console.log("itemtask, itemtask ========",itemtask, indextask)
            itemtask.item = ""
            itemtask.item_id = ""
            itemtask.is_compulsory = ""
          })
        })


        setProcessDetailsSubmited(false);
        setstepNameNewErr("");
        settaskNameNewErr("");
        setprocessNameErr("");
        setprocessShortcodeErr("");
        setprocessDescriptionErr("");
        setstepCountErr("");
        seteditProcessOrCreateProcess(false);
        setselectedProcessToEdit("");
                    
      } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
        ) 
        {
          // swalWithBootstrapButtons.fire(
          //   'Cancelled',
          //   'Your imaginary file is safe :)',
          //   'error'
          // )
        }
      })
  }



  return (
    console.log("NewProcessDetails",NewProcessDetails),
    console.log("ExistingProcessDetails",ExistingProcessDetails),
    console.log("ExistingProcessStepDetails",ExistingProcessStepDetails),
    // console.log("StepsToCreate in retunr",StepsToCreate),
    // console.log("ProcessDetailsList = ",ProcessDetailsList),
    console.log("NameServiceSumbmited",NameServiceSumbmited),
    <div className={classes.root}>
      {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
        <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
      </modal>: null}
        <div className={classes.wrapper}>
            <Grid container className={classes.pageHeaderContainer} style={{marginTop:'1%'}}>
              <Grid item xs={12} className={classes.pageHeaderLabel}>
                Process Creation
              </Grid>
            </Grid>

            <Accordion defaultExpanded style={{marginTop:'-2%'}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.CreateProcessLabel}>Select Service</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper className={classes.paper} style={{marginTop:'-2%'}}>
                  <Grid container item xs={12}>

                    {/* to select super category */}
                    <Grid item xs={4}>
                      <Grid className={classes.CategoryLabel} >
                        Super Category
                      </Grid>
                      <Grid>
                        <FormControl variant="outlined" className={classes.formControl}>
                        {/* <InputLabel id="demo-simple-select-filled-label">Category</InputLabel> */}
                        <Select
                          native
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={selectedSuperCategory}
                          disabled = { ServiceSumbmited == true ? true : false }
                          onChange = {(e) => handleChangeSuprCategoryDropdown(e)}
                        >
                          <option value="" style={{color:'grey'}}>Select Category</option>
                            {superCategoryList && superCategoryList.map(item =>
                              <option value={item.category_id}>{item.name}</option>
                              )}
                        </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {
                      ( SubCtegoryFetched == true )?
                      (
                        <Grid item xs={4}>
                          <Grid className={classes.CategoryLabel} >
                            Category or Service
                          </Grid>
                          <Grid>
                            <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                              native
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={selectedSubCategory}
                              disabled = { ServiceSumbmited == true ? true : false }
                              onChange = {(e) => handleChangeCategoryOrServiceDropdown(e)}
                            >
                              <option value="" style={{color:'grey'}}>Select Category or Service</option>
                                {subCategoryList && subCategoryList.map(item =>
                                  <option value={item.category_id}>{item.name}</option>
                                  )}
                            </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )
                      :(<div></div>)
                    }

                    {
                      (CategorySelected == true)?
                      (
                        <Grid item xs={4}>
                          <Grid className={classes.CategoryLabel} >
                            Service
                          </Grid>
                          <Grid>
                            <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                              native
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={selectedServiceFromCategory}
                              disabled = { ServiceSumbmited == true ? true : false }
                              onChange = {(e) => handleChangeServiceDropdown(e)}
                            >
                              <option value="" style={{color:'grey'}}>Select Service</option>
                                {subCategoryListForSelectedCategory && subCategoryListForSelectedCategory.map(item =>
                                  <option value={item.category_id}>{item.name}</option>
                                  )}
                            </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )
                      :(<div></div>)
                    }

                  </Grid>
                  <div style={{marginLeft:'2%'}} className={classes.validation}>{(ServiceSelected == false)?(serviceErr):(<div></div>)}</div>
                  <Grid style={{marginTop:'2%',display: 'flex', justifyContent:'center', alignItems:'center'}}>
                    {/* <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handelSubmitStepCountAndService}
                    >
                      Submit
                    </Button>
                    <div style={{marginLeft:'1%'}}></div> */}
                    {
                      ( ServiceSumbmited == true )?
                      (
                        <Button
                          type="submit"
                          variant="contained"
                          // color="primary"
                          className={classes.submit}
                          onClick={handelCancelStepCountAndService}
                        >
                          Cancel
                        </Button>
                      )
                      :(
                          <Grid></Grid>
                      )
                    }

                  </Grid>
              </Paper>
              </AccordionDetails>
            </Accordion>

            <div style={{marginTop:'3%'}}></div>

            {
              (ServiceSumbmited == true )?
              (
                <Grid>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.CreateProcessLabel}>Select Process</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Paper className={classes.paper}>
                      <Grid container item xs={12}>
                        <Grid item xs={6}>
                          <Grid className={classes.CategoryLabel} >
                            Select Process to Edit
                          </Grid>
                          <Grid>
                            <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                              native
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={selectedProcessToEdit}
                              disabled = { editProcessOrCreateProcess == true ? true : false }
                              onChange = {(e) => handleChangeProcessDropdown(e)}
                            >
                              <option value="" style={{color:'grey'}}>Select Process</option>
                                {ProcessDetailsList && ProcessDetailsList.map(item =>
                                  <option value={item.process_id}>
                                    {item.name}{(item.is_current == true ?  ' - ( Current Process )' : null)}
                                  </option>
                                  )}
                            </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          {
                            ( editProcessOrCreateProcess == true )?
                            (
                              <Grid style={{color:'grey', marginLeft:'15%',marginTop:'6%',fontSize:20, }}
                                // onClick = {() => handleCreateNewProcess()}
                              >
                                or Click Here to Create New Process
                              </Grid>
                            )
                            :(
                              <Grid style={{color:'blue', marginLeft:'15%',marginTop:'6%',fontSize:20,
                              cursor:'pointer'}}
                              onClick = {() => handleCreateNewProcess()}
                              >
                                or Click Here to Create New Process
                              </Grid>
                            )
                          }
                        </Grid>
                      </Grid>

                      <Grid style={{marginTop:'2%',display: 'flex', justifyContent:'center', alignItems:'center'}}>
                        {
                           ( editProcessOrCreateProcess == true )?
                           (
                              <Button
                                type="submit"
                                variant="contained"
                                className={classes.submit}
                                onClick={handelCancelEditorCreateProcessSelect}
                              >
                                Cancel
                              </Button>
                           )
                           :(
                             <Grid></Grid>
                           )
                        }

                      </Grid>
                    </Paper>
                  </AccordionDetails>
                </Accordion>
                </Grid>

              )
              :(<div></div>)
            }

            <div style={{marginTop:'3%'}}>
              {
                (EditExistingProcess == true )?
                (
                  <Grid>
                    <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.CreateProcessLabel}>Edit Process</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Paper className={classes.paper}>
                        <Grid>
                          <Grid className={classes.ProcessLabel} >
                            Process Name*
                          </Grid>
                          <Grid>
                            <TextField
                              margin="normal"
                              autoFocus
                              required
                              fullWidth
                              id="outlined-basic"
                              variant="outlined"
                              // label={ ExistingProcessDetails.name == "" ? "Process Name" : null}
                              name="name"
                              // disabled = { ProcessDetailsSubmited == true ? true : false }
                              value={ExistingProcessDetails.name}
                              onChange={(e) => setExistingProcessDetails({...ExistingProcessDetails, name:e.target.value }) }
                            />
                          </Grid>
                          <div className={classes.validation}>{( !ExistingProcessDetails.name )?(processNameErr):(<div></div>)}</div>

                          <Grid className={classes.ProcessLabel} style={{marginTop:'1%'}}>
                            Process Description*
                          </Grid>
                          <Grid>
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="outlined-basic"
                              variant="outlined"
                              name="description"
                              value={ExistingProcessDetails.description}
                              onChange={(e) => setExistingProcessDetails({...ExistingProcessDetails, description:e.target.value }) }
                            />
                          </Grid>
                          <div className={classes.validation}>{( !ExistingProcessDetails.description )?(processDescriptionErr):(<div></div>)}</div>

                          <Grid className={classes.ProcessLabel} style={{marginTop:'1%'}}>
                           Shortcode*
                          </Grid>
                          <Grid>
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="outlined-basic"
                              variant="outlined"
                              name="shortcode"
                              value={ExistingProcessDetails.shortcode}
                              // onChange={(e) => setExistingProcessDetails({...ExistingProcessDetails, shortcode:e.target.value }) }
                              onChange={(e) => handleExistingProcessDetailsShortcode(e.target.value) }
                            />
                          </Grid>
                          <div className={classes.validation}>{( !ExistingProcessDetails.shortcode )?(processShortcodeErr):(<div></div>)}</div>
                          
                          <div className={classes.validation}>{ShortCodeAlreadyExistForEditProcessErr}</div>
                          {/* <Grid container item xs={12} style={{marginTop:'2%'}}>
                            <Grid className={classes.ProcessLabel}>
                              Is Current
                            </Grid>
                            <Grid style={{marginLeft: '2%' }}>
                              {
                                ( showAntSwitch == true)?
                                (
                                  <AntSwitch
                                    checked={ExistingProcessDetails.is_current}
                                    onChange={() => handleIsCurrentSwitch()}
                                    // onChange={(e) => setExistingProcessDetails({...ExistingProcessDetails, is_current: !ExistingProcessDetails.is_current }) }
                                    size="small"
                                    name="ExistingProcessDetails.is_current"
                                    value="ExistingProcessDetails.is_current"
                                    color="primary"
                                  />
                                )
                                :(<Grid></Grid>)
                              }

                            </Grid>
                          </Grid> */}
                        </Grid>

                        {/* <Grid style={{marginTop:'2%',display: 'flex', justifyContent:'center', alignItems:'center'}}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handelSubmitEditProcess}
                          >
                            Submit
                          </Button>
                          <div style={{marginLeft:'1%'}}></div>
                          <Button
                            type="submit"
                            variant="contained"
                            // color="primary"
                            className={classes.submit}
                            onClick={handelCancelEditProcess}
                          >
                            Cancel
                          </Button>
                        </Grid> */}
                      </Paper>
                    </AccordionDetails>
                    </Accordion>

                    <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {/* <Typography className={classes.CreateProcessLabel}>Edit Step or Task</Typography> */}
                      <Typography className={classes.CreateProcessLabel}>Process Definition</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Paper className={classes.paper}>
                        <Grid className={classes.stepContainer} style={{marginTop:'-2%'}}>
                          <Grid className={classes.AddMoresStepsLabel}
                            // onClick={()=> AddAnotherStepForEditStep()}
                            id="MenuAddAnotherStep"
                            // onClick={handleToggleAddAnotherStep}
                          >
                            <br/>
                             {/* Actions */}
                            <br/>
                            <br/>
                          </Grid>
                        
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>

                        <div style={{ width: "48%"}}>

                          <Steps direction="vertical" current={ExistingProcessStepDetails && ExistingProcessStepDetails.length}
                            status="error" size="small">
                          {
                            ExistingProcessStepDetails && ExistingProcessStepDetails.map((isteps, indexsteps) =>(
                              <Step
                                title={
                                  <div style={{ color: "#4D47DD", cursor: "pointer"}}
                                    onClick={ () => EditStepClickedFunction(isteps, indexsteps) }
                                  >
                                    <Grid container>
                                      {parseInt(indexsteps)+1}.&nbsp;&nbsp;
                                      {
                                        // <Grid item xs={12} style={{width:"100%"}}>{isteps.name}</Grid>
                                        
                                        (isteps.name == "")?
                                        // (<Grid >Step{indexsteps + 1}</Grid> )
                                        // (<Grid >No step name</Grid> )
                                        (<Grid >{undefinedVariable}</Grid> )
                                        :(<Grid >{isteps.name}</Grid>)    
                                      }
                                    </Grid>
                                  </div>
                                }
                                // subTitle={
                                //   <div style={{ marginLeft: '-8px', marginTop: '5px', color: "#000000", cursor: "pointer"}}
                                //     id="stepIdDiv"
                                //     key={isteps.step_id}
                                //     value={isteps.step_id}
                                //     onClick={() => EditStepClickedFunction(isteps, indexsteps)}
                                //   >
                                //     {isteps.description}
                                //   </div>
                                // }
                                description={
                                  <Grid item xs={12}>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      >
                                      <Typography className={classes.CategoryLabel}>Tasks</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                          <Grid item xs={12} style={{marginTop:'-5%'}}>
                                            {/* {
                                              isteps.tasks.map((itask,indextask)=>( */}
                                                <Grid item xs={12}>
                                                  {/* {
                                                    (itask.item == "")?
                                                    (
                                                      <div className={classes.TaskLabelStyle}>No task added yet </div>
                                                    ):
                                                    (
                                                      <div item xs={12} className={classes.TaskLabelStyle}>{indextask + 1}. {itask.item}</div>
                                                    )
                                                  } */}
                                                  <Table className={classes.table} >
                                                      <TableHead>
                                                        <TableRow>
                                                          <TableCell align="center"
                                                            style={{fontWeight: 'bold', fontSize: 17}}
                                                          >
                                                            Name
                                                          </TableCell>
                                                          <TableCell align="center"
                                                            style={{fontWeight: 'bold', fontSize: 17}}
                                                          >
                                                            Required
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableHead>
                                                      <TableBody>
                                                       {isteps.tasks.map(row => (
                                                        // {item.step_todolist.map(row => (
                                                          console.log("row of task table",row),
                                                          <TableRow 
                                                            key={row.todolist_id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                            <TableCell align="center" component="th" scope="row">
                                                              {/* {row.item} */}
                                                              { row.item == "" ? "No task added yet" : row.item }
                                                            </TableCell>
                                                            <TableCell align="center">
                                                              {
                                                                ( row.item == "" )?
                                                                (
                                                                  ""
                                                                )
                                                                :(
                                                                  row.is_compulsory == true ? "Yes" : "No"
                                                                )
                                                              }
                                                              
                                                            </TableCell>
                                                          </TableRow>
                                                        ))}
                                                      </TableBody>
                                                    </Table>
                                                </Grid>
                                              {/* ))
                                            } */}
                                          </Grid>
                                      </AccordionDetails>
                                    </Accordion>
                                    {/* itemStep.name == "" || itemStep.name ==  "New Step" */}
                                    <div className={classes.validation}>{( isteps.name == "" || isteps.name ==  "New Step" || 
                                      isteps.name ==  "<Undefined>")?(stepNameErr):(<div></div>)}</div>
                                    {/* <div className={classes.validation}>{( !isteps.name )?(stepNameErr):(<div></div>)}</div> */}
                                    {/* <div className={classes.validation}>{stepNameErr}</div> */}
                                    <div className={classes.validation}>{
                                      isteps.tasks.map((itask,indextask)=>(
                                        <div>
                                          {
                                            ( !itask.item )?
                                            (taskNameErr)
                                            :(<div></div>)
                                          }
                                        </div>
                                      ))}
                                    </div>
                                    <div className={classes.validation}>
                                        {( isteps.atLeastOneCompulsary == undefined)?(taskCompulsaryNotSlectedErr):(<div></div>)}</div>
                                    {/* taskCompulsaryNotSlectedErr */}
                                  </Grid>
                                }
                              />
                              ))
                            }
                          </Steps>
                        </div>

                          <div style={{ width: "48%", display: "flex", flexDirection:"column"}}>
                                <span style={{cursor: 'pointer', color: "black", fontWeight:"bold", color: "blue", marginBottom:"24px"}}
                                  onClick={() => handleAddAnotherStepInEdit("First")}
                                >
                                 Add Step: At the start
                                </span>
                                <br/>
                                <br/>
                                <span style={{cursor: 'pointer',color: "black", fontWeight:"bold", color: "blue", marginBottom:"24px"}}
                                  onClick={() => handleAddAnotherStepInEdit("Last")}
                                >
                                 Add Step: At the end
                                </span>
                                <br/>

                                <br/>
                                <span style={{cursor: 'pointer',color: "black", fontWeight:"bold", color: "blue", marginBottom:"24px"}}
                                  // onClick={() => handleClickConfirmCase()}
                                >
                                    <span style={{color: 'black'}}>Add Step: After position</span> <input type="text" value={afterStep} onChange={(e)=>setAfterStep(e.target.value)} style={{width:"40px"}}  /> 

                                    <span style={{cursor: "pointer", padding: 8, color: "blue"}} onClick={() => handleAddAnotherStepInEdit("AddAfterStep")}>Go</span>
                                    <br/>
                                    <i style={{color:"gray", fontSize: "10px"}}>Step no should be either 1 or less than equal to {ExistingProcessStepDetails && ExistingProcessStepDetails.length}</i>
                                </span>
                                <span style={{cursor: 'pointer',color: "black", fontWeight:"bold", color: "blue", marginBottom:"24px"}}
                                  // onClick={() => handleClickConfirmCase()}
                                >
                                    <span style={{color: 'red'}}>Delete Step at position</span> <input type="text" value={deleteStep} onChange={(e)=>setDeleteStep(e.target.value)} style={{width:"40px"}}  /> 

                                    <span style={{cursor: "pointer", padding: 8, color: "blue"}} onClick={() => handleAddAnotherStepInEdit("DeleteStep")}>Go</span>
                                    <br/>
                                    <i style={{color:"gray", fontSize: "10px"}}>Step no should be either 1 or less than equal to {ExistingProcessStepDetails && ExistingProcessStepDetails.length}</i>
                                </span>

                                <br/><br/>
                                <span style={{color: "red"}}>{stepActionErrMessage}</span>
                          </div>

                    </div>

                        
                          <Grid style={{marginTop:'2%',display: 'flex', justifyContent:'center', alignItems:'center'}}>
                            <Button
                              autoFocus
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={classes.submit}
                              onClick={handelSubmitEditStepProcess}
                            >
                              Save As Draft
                            </Button>
                            <div style={{marginLeft:'1%'}}></div>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={classes.submit}
                              onClick={handlePublishProcess}
                            >
                             Publish
                            </Button>
                            <div style={{marginLeft:'1%'}}></div>
                            {/* <Button
                              type="submit"
                              variant="contained"
                              // color="primary"
                              className={classes.submit}
                              onClick={handelCancelEditStepProcess}
                            >
                              Cancel
                            </Button> */}
                          </Grid>
                        </Grid>
                      </Paper>
                    </AccordionDetails>
                    </Accordion>
                  </Grid>

                )
                :(<div></div>)
              }

            </div>

            <div style={{marginTop:'3%'}}></div>


            {
              (CreateNewProcess == true )?
              (
                <Grid>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.CreateProcessLabel}>Create New Process</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Paper className={classes.paper}>

                      <Grid item xs={12} style={{marginLeft:'5%', marginRight:'5%', marginTop:'-2%'}}>
                        <TextField
                          margin="normal"
                          autoFocus
                          required
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          label="Process Name"
                          name="name"
                          // disabled = { ProcessDetailsSubmited == true ? true : false }
                          value={NewProcessDetails.name}
                          onChange={(e) => setNewProcessDetails({...NewProcessDetails, name:e.target.value }) }
                        />
                        <div className={classes.validation}>{( !NewProcessDetails.name )?(processNameErr):(<div></div>)}</div>

                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          label="Description"
                          name="description"
                          // disabled = { ProcessDetailsSubmited == true ? true : false }
                          value={NewProcessDetails.description}
                          onChange={(e) => setNewProcessDetails({...NewProcessDetails, description:e.target.value }) }
                        />
                        <div className={classes.validation}>{( !NewProcessDetails.description )?(processDescriptionErr):(<div></div>)}</div>

                        <Grid container item xs={12}>
                          <Grid style={{width:'48%'}}>
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="outlined-basic"
                              variant="outlined"
                              label="Short Code for Process"
                              name="shortcode"
                              // disabled = { ProcessDetailsSubmited == true ? true : false }
                              value={NewProcessDetails.shortcode}
                              // onChange={(e) => setNewProcessDetails({...NewProcessDetails, shortcode:e.target.value }) }
                              onChange={(e) => handleNewProcessDetailsShortcode(e.target.value) }
                            />
                          <div className={classes.validation}>{( !NewProcessDetails.shortcode )?(processShortcodeErr):(<div></div>)}</div>
                          
                          <div className={classes.validation}>{ShortCodeAlreadyExistForNewProcessErr}</div>
                          </Grid>

                          <Grid style={{width:'48%', marginLeft:'4%'}}>
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="outlined-number"
                              variant="outlined"
                              label="Number of Steps(must be greater than 1 and less than 11)"
                              name="noOfSteps"
                              // type="number"
                              // inputProps={{
                              //   maxLength: 2
                              // }}
                              // min="2"
                              // max="11"
                              // maxlength="2"
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 2,
                                max: 11, min: 2 }}
                              disabled = { ProcessDetailsSubmited == true ? true : false }
                              value={stepCount}
                              onChange = {handleChangeSelectStepCountDropdown}
                            />
                            {/* <div className={classes.validation}>{( !stepCount )?(stepCountErr):(<div></div>)}</div> */}
                            <div className={classes.validation}>{stepCountErr}</div>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid style={{marginTop:'2%',display: 'flex', justifyContent:'center', alignItems:'center'}}>
                        {
                          ( ProcessDetailsSubmited == false)?
                          (
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={classes.submit}
                              onClick={handelSubmitCreateNewProcess}
                            >
                              Submit
                            </Button>
                          ):
                          (
                            <Button
                              type="submit"
                              variant="contained"
                              // color="primary"
                              className={classes.submit}
                              onClick={handelCancelCreateNewProcess}
                            >
                              Cancel
                            </Button>
                          )
                        }

                        {/* <div style={{marginLeft:'1%'}}></div> */}

                      </Grid>
                  </Paper>
                  </AccordionDetails>
                </Accordion>
                </Grid>

              )
              :(<div></div>)
            }

            <div style={{marginTop:'3%'}}></div>

            {
              (ProcessDetailsSubmited == true)?
              // ( NewProcessDetails.name !== "" && NewProcessDetails.description !== "" && NewProcessDetails.shortcode !== ""
              //   && stepCount !== "")?
              (
                <Grid>
                <Accordion defaultExpanded >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.CreateProcessLabel}>Create Steps</Typography>
                    {/* <TextField
                      value="Create Steps"
                      disabled
                      autoFocus
                    /> */}
                  </AccordionSummary>
                  <AccordionDetails>
                  <Paper className={classes.paper}>
                    {/* <Grid className={classes.CreateProcessLabel}>
                      Create Steps:
                    </Grid> */}
                    <Grid className={classes.stepContainer} style={{marginTop:'-2%'}}>
                        <Grid style={{marginLeft:'51%', marginTop:'1%', color:'grey', fontSize:'12px'}}>
                          Note: Number of steps and all steps and tasks configuration can be changed after draft is saved.
                        </Grid>

                          <Steps direction="vertical" current={StepsToCreate.length}
                            status="error" size="small" autoFocus>
                            {
                              StepsToCreate && StepsToCreate.map((i, index) =>(
                                <Step
                                title={
                                  <div style={{ color: "#4D47DD", cursor: "pointer"}}
                                    onClick={()=>CreateNewStepStepClickedFunction(i, index)}
                                  >
                                    {
                                      (i.name == "")?
                                      // (<Grid>Step{index + 1}</Grid> )
                                      // (<Grid>{index + 1}. No step name</Grid> )
                                      (<Grid >{index + 1}.&nbsp;&nbsp; {undefinedVariable} </Grid> )
                                      :(<Grid>{i.name}</Grid>)
                                    }
                                  </div>
                                }
                                // subTitle={
                                //   <div style={{ marginLeft: '-8px', marginTop: '5px', color: "#000000", cursor: "pointer"}}
                                //     id="stepIdDiv"
                                //     key={i.step_id}
                                //     value={i.step_id}
                                //     onClick={()=>CreateNewStepStepClickedFunction(i, index)}
                                //   >
                                //     {i.description}
                                //   </div>
                                // }
                                description={
                                  <div className={classes.rootDescription}>
                                    <Grid item xs={12}>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          focusVisibles={true}
                                        >
                                        <Typography className={classes.CategoryLabel}>Tasks</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Grid item xs={12} style={{marginTop:'-5%'}} >
                                            {/* {
                                              i.tasks.map((itask,indextask)=>( */}
                                                <Grid item xs={12}>
                                                  {/* {
                                                    (itask.item == "")?
                                                    (
                                                      <div className={classes.TaskLabelStyle}>No task added yet </div>
                                                    ):
                                                    (
                                                      <div item xs={12} className={classes.TaskLabelStyle}>{indextask + 1}. {itask.item}</div>
                                                    )
                                                  } */}
                                                  <Table className={classes.table} >
                                                      <TableHead>
                                                        <TableRow>
                                                          <TableCell align="center"
                                                            style={{fontWeight: 'bold', fontSize: 17}}
                                                          >
                                                            Name
                                                          </TableCell>
                                                          <TableCell align="center"
                                                            style={{fontWeight: 'bold', fontSize: 17}}
                                                          >
                                                            Required
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableHead>
                                                      <TableBody>
                                                       {i.tasks.map(row => (
                                                        // {item.step_todolist.map(row => (
                                                          console.log("row of task table",row),
                                                          <TableRow 
                                                            key={row.todolist_id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                            <TableCell align="center" component="th" scope="row">
                                                              {/* {row.item} */}
                                                              { row.item == "" ? "No task added yet" : row.item }
                                                            </TableCell>
                                                            <TableCell align="center">
                                                              {
                                                                ( row.item == "" )?
                                                                (
                                                                  ""
                                                                )
                                                                :(
                                                                  row.is_compulsory == true ? "Yes" : "No"
                                                                )
                                                              }
                                                              
                                                            </TableCell>
                                                          </TableRow>
                                                        ))}
                                                      </TableBody>
                                                    </Table>
                                                </Grid>
                                              {/* ))
                                            } */}
                                          </Grid>
                                        </AccordionDetails>
                                      </Accordion>
                                      <div className={classes.validation}>{( !i.name )?(stepNameNewErr):(<div></div>)}</div>
                                      {/* <div className={classes.validation}>{stepNameNewErr}</div> */}
                                      <div className={classes.validation}>{
                                        i.tasks.map((itask,indextask)=>(
                                          <div>
                                            {
                                              ( !itask.item )?
                                              (taskNameNewErr)
                                              :(<div></div>)
                                            }
                                          </div>
                                        ))}
                                      </div>
                                    </Grid>
                                  </div>
                                }
                              />
                              ))
                            }
                          </Steps>

                          <Grid style={{marginTop:'2%',display: 'flex', justifyContent:'center', alignItems:'center'}}>
                            <Button
                              autoFocus
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={classes.submit}
                              onClick={handelSubmitCreateStepProcess}
                            >
                              Save As Draft
                            </Button>
                            <div style={{marginLeft:'1%'}}></div>
                            <Button
                              type="submit"
                              variant="contained"
                              // color="primary"
                              className={classes.submit}
                              onClick={handelCancelCreateStepProcess}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        </Grid>
                  </Paper>
                </AccordionDetails>
                </Accordion>
                </Grid>
              )
              :(<div></div>)
            }

      </div>
        {/* edit step */}
        <Modal
          open={openEditStep}
          onClose={handelEditStepClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {BodyEditStep}
        </Modal>

        {/* new step */}
        <Modal
          open={openCreateStep}
          onClose={handelCreateStepClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {BodyCreateStep}
        </Modal>

        {/* tTo ask where to add new step */}
        <Modal
          open={openNewStepForEditPlace}
          onClose={handelNewStepForEditPlaceClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {BodyNewStepForEditPlace}
        </Modal>
    </div>
  );
}

export default injectIntl(TaxManagementProcessCreation);
