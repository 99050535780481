import React from 'react';
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { injectIntl, FormattedMessage } from "react-intl";
// import Link from "@material-ui/core/Link";
// import { Router, Route, Redirect, Link} from "react-router-dom"; 
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { history } from "../helpers";
import { Router, Route, Redirect,Link} from "react-router-dom";
import Swal from 'sweetalert2';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "react-phone-input-2/lib/material.css";
import OtpTimer from 'otp-timer';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../actions";
import { userConstants } from '../constants';
import { reset} from "../actions/logindetails.actions";
import { addBusinessId, addLogedInRole, addDeviceToken, addSessionId} from "../actions/logindetails.actions";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(../Image/loginBanner.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatarLocked: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  avatarSignIn: {
    margin: theme.spacing(1),
    backgroundColor: "#ffffff",
  },
  form: {
    width: "100%",
    // marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  leftAlign: {
    textAlign: "left",
  },
  errorBlock: {
    // marginLeft: "5%",
    // marginRight: "5%",
    width: "100%",
    paddingTop: "2%",
    textAlign: "left",
  },
  overFlowHidden: {
    overflow: "hidden",
  },
  dontHaveAccText: {
    cursor: "pointer",
    color: "#3f51b5",
  },
  verifyLabel: {
    width: "100%",
    marginTop: theme.spacing(5),
    // textAlign: "left",
    fontSize: 20,
  },
  verifyText: {
    width: "100%",
    marginTop: theme.spacing(2),
    textAlign: "left",
    fontSize: 15,
  },
  didntOtpText: {
    width: "100%",
    marginTop: theme.spacing(2),
    textAlign: "left",
    fontSize: 12,
  },
  resendText: {
    width: "100%",
    marginTop: "1%",
    textAlign: "left",
    fontSize: 12,
    cursor: "pointer",
    color: "#3f51b5",
  },
  leftAlignBackToHome: {
    textAlign: "left",
    marginTop: '1%',
    marginLeft: '1%',
    cursor:"pointer",
  },

}));



export default function SignUpForm() {
  const classes = useStyles();
  const [fullname, setfullname] = React.useState();
  const [phoneNumber, setphoneNumber] = React.useState();
  const [countryCodeNumber, setcountryCodeNumber] = React.useState("+91");
  const [email, setemail] = React.useState();
  const [panNo, setpanNo] = React.useState();
  const [submitedSignIn, setsubmitedSignIn] = React.useState(false);
  const [showVerification, setshowVerification] = React.useState(false);
  const [otp, setotp] = React.useState();
  const [showOtpTextBox, setshowOtpTextBox] = React.useState(true);
  const [submitedOTP, setsubmitedOTP] = React.useState(false);
  const [redirectVerification, setredirectVerification] = React.useState(false);
  const [redirectToLogin, setredirectToLogin] = React.useState(false);

  const [fullnameErr, setfullnameErr] = React.useState();
  const [emailErr, setemailErr] = React.useState();
  const [phoneNumberErr, setphoneNumberErr] = React.useState();
  const [panNoErr, setpanNoErr] = React.useState();

  const [CountryCode, setCountryCode] = React.useState("");
  const [onlyCountryArray, setonlyCountryArray] = React.useState([
    'in','us', 'gb', 'ca', 'ae', 'sg','np', 'jp', 'de', 'bt', 'au'
  ]);

  const [count, setCount] = React.useState(0);
  const [ShowTimer, setShowTimer] = React.useState(false);

  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));

  const dispatch = useDispatch();

  React.useEffect(() => {
    // dispatch(success({'username':"",'token':""}));
    // dispatch(failure()); 
    // dispatch(reset());

    // dispatch(failure()),
    // function failure() { return { type: userConstants.GETALL_FAILURE,  } }
    
    
    // function success(user) { return { type: userConstants.LOGIN_FAILURE, user } }
    // function failure() { return { type: userConstants.LOGIN_FAILURE,  } }

    // dispatch(addDeviceToken(""));
    // dispatch(addSessionId(""));
    // localStorage.setItem('device_token', "");
    // localStorage.setItem('session_id', "");

    console.log("device_token in useeffect login",device_token)
    console.log("session_id in useeffect login",session_id)
    
    let user = JSON.parse(localStorage.getItem('user'));
    console.log("user in useeffect login",user)


    if( device_token !== null && session_id !== null && user !== null){
      console.log("device token , session id, user info found in useeffect of login")
      // Swal.fire({
      //   icon: 'error',
      //   text: "You are already logged in in app",
      //   confirmButtonColor: '#d33',
      //   confirmButtonText: 'OK'
      // })
      dispatch(userActions.logout());
    }
    else{
      console.log("device token , session id, user info is empty in useeffect of login")
    }

  }, []);

  const validate = () => {
    let fullnameErr = '';
    let emailErr = '';
    let phoneNumberErr = '';
    let panNoErr = '';

    if(!fullname)  {
      fullnameErr = 'Name is required';
    }

    if(!email)  {
      emailErr = 'Email is required';
    }
    else if(typeof email !== "undefined"){
      let lastAtPos = email.lastIndexOf('@');
      let lastDotPos = email.lastIndexOf('.');
      if (!(lastAtPos < lastDotPos && lastAtPos > 0 )) {
        emailErr = "Email is not valid";
        if(emailErr) {
          setemailErr(emailErr);
        }
      }
      else {
        setemailErr();    
      }
    }
    else{
      setemailErr();
    }

    if(!phoneNumber)  {
      phoneNumberErr = 'Phone Number is required';
    }
    else if (typeof phoneNumber !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/)
      // if (!pattern.test(phoneNumber)) {
      //   phoneNumberErr = "Please enter only number";
      //   if(phoneNumberErr) {
      //     setphoneNumberErr(phoneNumberErr);
      //   }
      //   else {
      //     setphoneNumberErr();    
      //   }
      // }else if(phoneNumber.length != 10){
      //   phoneNumberErr = "Please enter valid phone number.";
      //   if(phoneNumberErr) {
      //     setphoneNumberErr(phoneNumberErr);
      //   }
      //   else {
      //     setphoneNumberErr();    
      //   }
      // }
      if (CountryCode == "in"){
        // if(phoneNumber.length !== 15){
        if(phoneNumber.length !== 10){
            phoneNumberErr = "Please enter valid phone number.";
          }
      }
      else if(CountryCode == "us"){
        // if(phoneNumber.length !== 17){
          if(phoneNumber.length !== 10){
            phoneNumberErr = "Please enter valid phone number.";
          }
      }
      else if(CountryCode == "gb"){
        // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
        if(phoneNumber.length !== 10){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCode == "ca"){
        // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
        if(phoneNumber.length !== 10){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCode == "ae"){
        // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
        if(phoneNumber.length !== 12){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCode == "sg"){
        // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
        if(phoneNumber.length !== 8){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCode == "np"){
        // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
        if(phoneNumber.length !== 12){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCode == "jp"){
        // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
        if(phoneNumber.length !== 10){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCode == "de"){
        // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
        if(phoneNumber.length !== 12){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCode == "bt"){
        // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
        if(phoneNumber.length !== 12){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
      else if(CountryCode == "au"){
        // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
        if(phoneNumber.length !== 13){
          phoneNumberErr = "Please enter valid phone number.";
        }
      }
    }

    if(!panNo)  {
      panNoErr = 'PAN number is required';
    }
    else if (typeof panNo !== "undefined") {
        var pattern = new RegExp(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/)
        if(panNo.length !== 10){
          console.log("in != 10")
          panNoErr = "PAN Number is Not Valid";
        }
        else if (!pattern.test(panNo)) {
          console.log("in string check")
          panNoErr = "PAN Number is Not Valid";
        } 
      }

    if(emailErr || fullnameErr || phoneNumberErr || panNoErr ){
      setfullnameErr(fullnameErr);
      setemailErr(emailErr);
      setphoneNumberErr(phoneNumberErr);
      setpanNoErr(panNoErr);
      return false;
    }

    return true;
  }

  const signUpFunc = () => {
    // setshowVerification(true)

    // this variable is for validation
    console.log("panNo",panNo)
    setsubmitedSignIn(true)
    const isValid = validate();
    if(isValid){
    // if( fullname && phoneNumber && email  ){
      
        // this variable is for showing next screen
        // setshowVerification(true)
        const signUpData = {
          'client_name': fullname,
          'client_email': email,
          'client_pan': panNo,
          'client_phone': phoneNumber,
          'country_code' : countryCodeNumber,
        }
        console.log("signUpData",signUpData)
        const requestOptions = {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify(signUpData)
        };
        fetch( process.env.REACT_APP_VERIFY_CLIENT_SIGNUP , requestOptions)
          .then((Response) => Response.json())
          .then( signUpClientData => {
              console.log("signUpClientData in submit sign up info",signUpClientData);
              console.log("signUpClientData in submit sign up info",signUpClientData.success);
              if(signUpClientData.success == true)
              {
                console.log(" IN signUpClientData TRUE ",signUpClientData.success);
                if( signUpClientData.data.client_info.client_state == "INVITED" )
                {
                  setshowVerification(true)
                  localStorage.setItem('client_signup_info', JSON.stringify(signUpClientData.data.client_info))
                }
                else if(signUpClientData.data.client_info.client_state == "REGISTERED"){
                  Swal.fire({
                    icon: 'error',
                    // text: signUpClientData.errors,
                    text: "This Client Is Already Registered",
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                  })
                  setredirectToLogin(true)
                }
                else {
                  Swal.fire({
                    icon: 'error',
                    text: "Client Is Not Invited Yet",
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                  })
                  setredirectToLogin(true)
                }              
              }
              if(signUpClientData.success == false){
                console.log(" IN signUpClientData false ",signUpClientData.success);
                Swal.fire({
                  icon: 'error',
                  text: signUpClientData.errors,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              }
              setfullnameErr();
              setemailErr();
              setphoneNumberErr();
              setpanNoErr();
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });
      // }
    }
  }

  const submitOTPFunc = () => {
    console.log("check otp",otp)
    // setredirectVerification(true)

    // this variable is for validation
    setsubmitedOTP(true)
    if( otp ){
      let client_info = JSON.parse(localStorage.getItem('client_signup_info'))
      console.log("client_id from local storage", client_info)
      let client_id = client_info.client_id
      console.log("client_id from local storage", client_id)

      const verifyEmailData = {
        'client_id': client_id,
        'email_verification_code': otp,
      }
      console.log("verifyEmailData",verifyEmailData)
      const requestOptions = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(verifyEmailData)
      };
      fetch( process.env.REACT_APP_VERIFY_CLIENT_EMAIL , requestOptions)
        .then((Response) => Response.json())
        .then( vefifyEmailData => {
            console.log("vefifyEmailData in submit otp",vefifyEmailData);
            if(vefifyEmailData.success == true)
            {
              if(vefifyEmailData.data.email_verification.verified == true)
              {
                setredirectVerification(true)
                // localStorage.setItem('client_signup_info', JSON.stringify(signUpClientData.data.client_info))
              }
              else if(vefifyEmailData.data.email_verification.verified == false){
                console.log(" in vefifyEmailData.data.email_verification.verified success false")
                Swal.fire({
                  icon: 'error',
                  text: "Email is not verified",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
                // setredirectToLogin(true)
                setCount(prevCount => prevCount + 1);
                checkIfCountIsThree();
              }
            }
            if(vefifyEmailData.success == false){
              console.log(" in vefifyEmailData. success false")
              Swal.fire({
                icon: 'error',
                text: vefifyEmailData.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
              setCount(prevCount => prevCount + 1);
              checkIfCountIsThree();
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
  }

  const checkIfCountIsThree = () => {
    setshowOtpTextBox(false);
    setsubmitedOTP(false);
    console.log("in checkIfCountIsThree",count)
    if( count == 2){
      console.log("count is three",count)
      setShowTimer(true);
      setotp();
      Swal.fire({
        icon: 'error',
        text: "Take a break. Check your email and try again",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    }
    setshowOtpTextBox(true);
  }

  const handleClick = () => {
    setshowOtpTextBox(false);
    console.log("in handleClick try again");
    setShowTimer(false);
    setCount(0);
    setshowOtpTextBox(true);
  }

  // const phoneNumberChange  = (usersNumber,e) => {
  //   // console.log("phoneNumberChange usersNumber",usersNumber)
  //   // console.log("phoneNumberChange e",e.countryCode)
  //   setCountryCode(e.countryCode)
  //   setphoneNumber(usersNumber)
  // }
  const phoneNumberChange = (clientNumber,e,formattedValue,value) => {
    console.log("phoneNumberChange clientNumber",clientNumber)
    console.log("phoneNumberChange e",e)
    console.log("phoneNumberChange value",value)
    let countryCodeIs = "+" + e.dialCode
    let splitNoIs = ""
    if(e.countryCode == "in"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "us"){
      // splitNoIs = value.split("(")[1];
      splitNoIs = value.substr(value.indexOf(' ')+1);
    }
    if(e.countryCode == "gb"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "ca"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "ae"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "sg"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "np"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "jp"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "de"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "bt"){
      splitNoIs = value.split(" ")[1];
    }
    if(e.countryCode == "au"){
      splitNoIs = value.split(" ")[1];
    }
    
    console.log("splitNoIs",splitNoIs)
    // if(formattedValue !== null){
    // console.log("formattedValue",formattedValue.target.value)
    // }
    let noHereIs = "+"+e.dialCode+"-"+splitNoIs;
    console.log("noHereIs",noHereIs)
    setCountryCode(e.countryCode)
    // setclientDetails({...clientDetails, phone:clientNumber })
    // setphoneNumber(noHereIs)
    setphoneNumber(splitNoIs)
    setcountryCodeNumber(countryCodeIs)
  }

  if(redirectVerification == true){
    return(
      <Redirect to={'/SignUpVerification'}/>
    )
      // return (<Redirect to={{
      //       pathname: '/SignUpVerification',
      //       state: {
      //           fullname: fullname ,
      //       }
      //   }} />)
    // return (
    //   <Redirect to={{ pathname: '/SignUpVerification', fullname: { fullname } }} />
    // )
  }
  else if(redirectToLogin == true){
    return(
      <Redirect to={'/login'}/>
    )
  }
  else{
  return (
    console.log("otp is",otp),
    console.log("SubmitCount on verifiction code, show timer",count, ShowTimer),
    <div>
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        className={classes.overFlowHidden}
      >
         <Grid item xs className={classes.leftAlignBackToHome}  >
          <Link to="HomePage" variant="body2" style={{color:'#551A88'}}>
            <FormattedMessage id="backToHomePageLabel" />
           </Link>
        </Grid>

        <div className={classes.paper}>
          
            <Avatar className={classes.avatarLocked}>
              <LockOutlinedIcon />
            </Avatar>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          <div>
          {
            ( showVerification == false)?
            (
              <div>
                <div className={classes.form}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="fullname"
                    label="Full Name"
                    name="fullname"
                    autoComplete="fullname"
                    autoFocus
                    value={fullname}
                    onChange={(e) => setfullname(e.target.value) }
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Id"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setemail(e.target.value) }
                  />                
                  {/* <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="phoneNumber"
                    label="Phone Number"
                    name="phoneNumber"
                    autoComplete="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => setphoneNumber(e.target.value) }
                  /> */}
                  <Grid style={{marginTop:'2%'}}></Grid>
                  <PhoneInput
                    inputStyle={{
                      width: "100%",
                      height: "50px",
                      fontSize: "15px",
                    }}
                    countryCodeEditable={false}
                    onlyCountries={onlyCountryArray}
                    specialLabel=""
                    country={'in'}
                    masks={{in: '..........', us: '..........', gb: '..........', ca: '..........', ae:'............',
                        sg: '........', np:'............', jp:'..........', de:'............', bt:'............',
                        au: '.............'  }}
                    // value={phoneNumber}
                    value={countryCodeNumber + phoneNumber}
                    onChange={(usersNumber,e,formattedValue,value) => phoneNumberChange(usersNumber,e,formattedValue,value)}
                  />

                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="panNo"
                    label="PAN Number"
                    name="panNo"
                    autoComplete="panNo"
                    placeholder="XXXXX0000X"
                    // helperText="Please enter your Pan Number"
                    inputProps={{ maxLength: 10, style: { textTransform: "uppercase" } }}
                    value={panNo}
                    onChange={(e) => setpanNo(e.target.value.toUpperCase()) }
                  />
                  

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={signUpFunc}
                  >
                    Sign up
                  </Button>
                </div>
                <Grid item xs={12}>
                  <div>
                    {/* { submitedSignIn == true && !fullname && (
                      <div className={classes.errorBlock}>
                        <Alert variant="filled" severity="error">
                          { submitedSignIn == true && !fullname && (
                            <div>
                              Name is required
                            </div>
                          )}
                        </Alert>
                      </div>
                    )} */}
                    {/* { submitedSignIn == true && !email && (
                      <div className={classes.errorBlock}>
                        <Alert variant="filled" severity="error">
                          { submitedSignIn == true && !email && (
                            <div>
                              Email is required
                            </div>
                          )}
                        </Alert>
                      </div>
                    )} */}
                    {
                      (submitedSignIn == true && fullnameErr)?
                      (
                        <div className={classes.errorBlock}>
                        <Alert variant="filled" severity="error">
                          <div>
                            {fullnameErr}
                          </div>
                        </Alert>
                      </div>
                      ):
                      (<div></div>)
                    }
                    {
                      (submitedSignIn == true && emailErr)?
                      (
                        <div className={classes.errorBlock}>
                        <Alert variant="filled" severity="error">
                          {/* { submitedSignIn == true && !email && ( */}
                            <div>
                              {emailErr}
                            </div>
                          {/* )} */}
                        </Alert>
                      </div>
                      ):
                      (<div></div>)
                    }
                    {/* { submitedSignIn == true && !phoneNumber && (
                      <div className={classes.errorBlock}>
                        <Alert variant="filled" severity="error">
                          { submitedSignIn == true && !phoneNumber && (
                            <div>
                              Phone Number is required
                            </div>
                          )}
                        </Alert>
                      </div>
                    )} */}
                    {
                      (submitedSignIn == true && phoneNumberErr)?
                      (
                        <div className={classes.errorBlock}>
                        <Alert variant="filled" severity="error">
                          <div>
                            {phoneNumberErr}
                          </div>
                        </Alert>
                      </div>
                      ):
                      (<div></div>)
                    }
                    {/* { submitedSignIn == true && !panNo && (
                      <div className={classes.errorBlock}>
                        <Alert variant="filled" severity="error">
                          { submitedSignIn == true && !panNo && (
                            <div>
                              PAN number is required
                            </div>
                          )}
                        </Alert>
                      </div>
                    )} */}
                    {
                      (submitedSignIn == true && panNoErr)?
                      (
                        <div className={classes.errorBlock}>
                        <Alert variant="filled" severity="error">
                          <div>
                            {panNoErr}
                          </div>
                        </Alert>
                      </div>
                      ):
                      (<div></div>)
                    }
                  </div>
                </Grid>
              </div>
            ):
            (
              <div>
                  <div className={classes.form}>
                    <Grid item xs={12}>
                      <Typography className={classes.verifyLabel}>
                        Verify
                      </Typography>

                      <Typography className={classes.verifyText}>
                        Enter the verification code(OTP) we sent to your email address and phone number.
                      </Typography>

                      {
                        ( showOtpTextBox == true )? 
                        (
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth                     
                            id="otp"
                            // label="Full Name"
                            disabled = { ShowTimer == true ? true : false }
                            name="otp"
                            autoComplete="otp"
                            autoFocus
                            value={otp}
                            onChange={(e) => setotp(e.target.value.trim()) }
                            // onChange={(e) => setotp(e.target.value) }
                          />
                        )
                        :(
                          <Grid></Grid>
                        )
                      }
                      

                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={submitOTPFunc}
                      >
                        {/* Submit OTP */}
                        Submit Verification Code
                      </Button>
                    </Grid>
                  </div>

                  {
                    ( ShowTimer == true )?
                    (
                      <OtpTimer 
                        seconds= {1} 
                        minutes={1} 
                        // text = ""
                        background = "#ffffff"
                        buttonColor = "blue"
                        ButtonText = "Try Again"
                        resend={ () => handleClick()} 
                      />
                    )
                    :(<Grid></Grid>)
                  }
                  
                  <div>
                    <Typography className={classes.didntOtpText}>
                      {/* Didn't receive a code? */}
                      Didn't receive a Verification Code?
                    </Typography>
                    <Grid className={classes.resendText}>
                      {/* Resend OTP */}
                      Resend Verification Code
                    </Grid>
                  </div>

                  <Grid item xs={12}>
                    <div>
                      { submitedOTP == true && !otp && (
                        <div className={classes.errorBlock}>
                          <Alert variant="filled" severity="error">
                            { submitedOTP == true && !otp && (
                              <div>
                                {/* OTP is required */}
                                Verification Code is required
                              </div>
                            )}
                          </Alert>
                        </div>
                      )}
                    </div>
                  </Grid>

                </div>
            )
          }
          </div> 
        </div>
      </Grid>
    </Grid>
    </div>  
  );
  }
}