import React, { Component } from "react";
import TaxManagementSection from "./TaxManagementSection";
import { Router, Route, Switch, BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";

import { history } from "./helpers";
import { alertActions } from "./actions";
import { PrivateRoute } from "./PrivateRoute";
import TaxManagementLogin from "./TaxManagementLogin";
import TaxManagementSignUp from "./TaxManagementSignUp/TaxManagementSignUp";
import SignUpVerification from "./TaxManagementSignUp/SignUpVerification";
import SignUpReferalCode from "./TaxManagementSignUp/SignUpReferalCode";
import TaxManagementForgotPassword from "./TaxManagementForgotPassword/TaxManagementForgotPassword";
import EnterOtpForgotPassword from "./TaxManagementForgotPassword/EnterOtpForgotPassword";
import ResetForgotPassword from "./TaxManagementForgotPassword/ResetForgotPassword"
import enTranslations from "./locales/en.json";
import { IntlProvider } from "react-intl";
import "./App.css";

import TaxManagementHomePage from "./TaxManagementHomePage";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: "en",
    };
    this.messages = {
      en: enTranslations,
    };
    const { dispatch } = this.props;
    // history.listen((location, action) => {
    //   dispatch(alertActions.clear());
    // });
  }

  render() {
    const { alert } = this.props;
      // localStorage.removeItem('user')
    return (
      <div>
        <div>
          <div>
              <IntlProvider
                locale={this.state.locale}
                messages={this.messages[this.state.locale]}
              >
                <div className="App">
                <Router history={history}>
                {/* <Router history={createHistory}> */}
                  <PrivateRoute  component={TaxManagementSection}/>

                  <Route path="/HomePage" 
                    render={(props) => (
                      <TaxManagementHomePage />
                    )}
                  />

                  <Route path="/login" 
                    render={(props) => (
                      <TaxManagementLogin {...props} alertObject={alert} />
                    )}
                  />
                  

                  <Route path="/TaxManagementSignUp" 
                    render={(props) => (
                      <TaxManagementSignUp />
                    )}
                  />
                  <Route path="/SignUpVerification" 
                    render={(props) => (
                      <SignUpVerification />
                    )}
                  />
                  <Route path="/SignUpReferalCode" 
                    render={(props) => (
                      <SignUpReferalCode />
                    )}
                  />

                  <Route path="/TaxManagementForgotPassword" 
                    render={(props) => (
                      <TaxManagementForgotPassword />
                    )}
                  />
                  <Route path="/EnterOtpForgotPassword" 
                    render={(props) => (
                      <EnterOtpForgotPassword />
                    )}
                  />
                  <Route path="/ResetForgotPassword" 
                    render={(props) => (
                      <ResetForgotPassword />
                    )}
                  />

                  
                </Router>
                </div>
              </IntlProvider>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert,
  };
}

export default connect(mapStateToProps)(App)
