import React from "react";
import { connect } from "react-redux";
import { userActions } from "../actions";
import LoginPageTemplate from "./LoginPageTemplate"
// import { history } from '../helpers';

class TaxManagementLogin extends React.Component {
  constructor(props) {
    super(props);
    // this.props.dispatch(userActions.logout());
    this.state = {
      username: "",
      password: "",
      submitted: false,
    };
  }

  handleChange = (e) => {
    // console.log("in handle change of usename",e.target.value)
    // console.log("in handle change of usename trim",e.target.value.trim())
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
    const { username, password } = this.state;
    // console.log("event of handle submit", this.state.username )
      const { dispatch } = this.props;
      if (username && password) {
        dispatch(userActions.login(username, password));
      }
  }

  render() {
    const { alertObject, loggingIn } = this.props;
    const { username, password, submitted } = this.state;
    return (
      <div>
        <LoginPageTemplate
          username={username}
          password={password}
          submitted={submitted}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          alertObject={alertObject}
          loggingIn={loggingIn}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  return {
    loggingIn,
  };
}

export default connect(mapStateToProps)(TaxManagementLogin);
