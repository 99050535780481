import React, {useEffect} from "react";
import Slide from "@material-ui/core/Slide";
import fetch from "cross-fetch";
import { injectIntl } from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage } from "react-intl";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import TaxManagementCaseDetails from "../TaxManagementCaseDetails";
import { modal } from 'reactstrap';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { PropagateLoader } from 'react-spinners';
import { userActions } from "../actions";
import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import Steps, { Step } from "rc-steps";
import "rc-steps/assets/index.css";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Modal from '@material-ui/core/Modal';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import { Multiselect } from 'react-widgets';
import "react-widgets/styles.css";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AntSwitch from '@material-ui/core/Switch';
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  pageHeaderContainer: {
    marginBottom: "5%",
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  CategoryLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
    marginLeft: "5%",
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  paperSteps: {
    padding: theme.spacing(2),
    height: '90%',
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginLeft:"2%",
    marginRight:"2%",
    overflowY:'scroll',
  },
  stepContainer: {
    textAlign: "left",
    color: "#4D47DD",
  },
  stateLableStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
    marginBottom:'2%',
  },
  TaskContainer: {
    textAlign: "left",
  },
  stepNameHeadingStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    marginTop:'4%',
  },
  TaskDetailsHeadingStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    marginTop:'1%',
    marginLeft: "2%",
  },
  TaskLabelStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    marginLeft: "4%",
    // marginTop:'1%',
  },
  questionContainer: {
    textAlign: "left",
  },
  questionsTypeHeadingStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    marginTop:'4%',
  },
  questionsTitleStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    marginTop:'2%',
  },
  questionsOptionStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    marginTop:'1%',
    marginLeft: '3%',
  },
  paperStepsHeading: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginLeft:"2%",
    marginRight:"2%",
  },
  paperStepsHeadingLabelStyle:{ 
    fontSize:18, 
    fontWeight:'600', 
    color:'black',
    marginTop:'-2%',
  },
  formControl: {
    // margin: theme.spacing(1),
    marginLeft: "5%",
    marginTop: '2%',
    width: '90%',
  },
  formControlModal: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
  },
  rootDescription: {
    display: "flex",
    justifyContent: "flex-end",
    minWidth: "500px",
    marginTop: "2px",
    marginBottom: "10px",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paperModal: {
    // position: 'absolute',
    // width: 500,
    // // height: 620,
    // backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    // // top:'2%',
    // display:'block'
    position: 'absolute',
    width: 500,
    height: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block',
    overflowY:'scroll',
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'12%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
    width:'100%',
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  moreVertIcon:{
    color: "black",
  },
  buttonSwalDelete: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
  },
  buttonSwalCancel: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
    marginRight: "2%",
  },
  formControlLevel: {
    // margin: theme.spacing(1),
    marginTop: '1%',
    width: '100%',
  },
}));

function TaxManagementServiceExplorer({ intl , props}) {
  const classes = useStyles();
  const[ShowProcedure, setShowProcedure ] = React.useState(false);
  const[business_id, setbusiness_id ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const[device_token, setdevice_token ] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const[session_id, setsession_id ] = React.useState(JSON.parse(localStorage.getItem('session_id')));

  const[superCategoryList, setsuperCategoryList] = React.useState([]);
  const[selectedSuperCategory, setselectedSuperCategory] = React.useState("");
  const[subCategoryList, setsubCategoryList] = React.useState([]);
  const[selectedSubCategory, setselectedSubCategory] = React.useState("");
  const[SubCtegoryFetched, setSubCtegoryFetched] = React.useState(false);
  const[CategorySelected, setCategorySelected] = React.useState(false);
  const[ServiceSelected, setServiceSelected] = React.useState(false);
  const[SelectedServicesId, setSelectedServicesId] = React.useState("");
  const[subCategoryListForSelectedCategory,setsubCategoryListForSelectedCategory] = React.useState([]);
  const[selectedServiceFromCategory, setselectedServiceFromCategory] = React.useState("");

  const[QuestionList, setQuestionList] = React.useState([]);
  const[DocTypeList, setDocTypeList] = React.useState([]);

  const[showAntSwitch, setshowAntSwitch] = React.useState(true);

  // add more doc
  const[openAddMoreDoc, setopenAddMoreDoc] = React.useState(false);
  const[EditDocCheck, setEditDocCheck] = React.useState(false);
  const [documentTypeDetails, setdocumentTypeDetails] = React.useState({
    service_id: "",
    name: "",
    description: "",
    doc_type_tag: "",
    file_type: "",
    allowed_types: "",
    file_size: "",
    is_default: true,
    is_optional: "",
    is_active: true,
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  });
  const [allowedTypesList, setallowedTypesList] = React.useState([
    "PDF",
    "Document",
    "Image",
  ]);
  const [serviceIdErr, setserviceIdErr] = React.useState();
  const [nameErr, setnameErr] = React.useState();
  const [descriptionErr, setdescriptionErr] = React.useState();
  const [docTypeErr, setdocTypeErr ] = React.useState();
  const [fileTypeErr, setfileTypeErr] = React.useState();
  const [allowedTypeErr, setallowedTypeErr] = React.useState();
  const [fileSizeErr, setfileSizeErr] = React.useState();
  // const [isDefaultErr, setisDefaultErr] = React.useState();
  const [isOptionalErr, setisOptionalErr] = React.useState();

  // question curd
  const [openAddMoreQue, setopenAddMoreQue] = React.useState(false);
  const [EditQuestionCheck, setEditQuestionCheck] = React.useState(false);

  const [questionDetails, setquestionDetails] = React.useState({
    service_id: "",
    title: "",
    qtype: "",
    level: "",
    is_active: "",
    is_default: true,
    is_optional: "",
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  });

  const [ ShowMultipleorSingleAns, setShowMultipleorSingleAns] = React.useState(true);
  // const [optionOne, setoptionOne] = React.useState("");
  // const [optionTwo, setoptionTwo] = React.useState("");
  // const [optionThree, setoptionThree] = React.useState("");
  // const [optionFour, setoptionFour] = React.useState("");
  const [optionOneId, setoptionOneId ] = React.useState();
  const [optionTwoId, setoptionTwoId ] = React.useState();
  const [optionThreeId, setoptionThreeId ] = React.useState();
  const [optionFourId, setoptionFourId ] = React.useState();
  const [optionsDetails, setoptionsDetails] = React.useState([]);

  const [optionArray, setoptionArray] = React.useState(["", ""]);
    
  const [ optionOneErr, setoptionOneErr ] = React.useState();
  // const [ optionTwoErr, setoptionTwoErr ] = React.useState();
  // const [ optionThreeErr, setoptionThreeErr ] = React.useState();
  // const [ optionFourErr, setoptionFourErr ] = React.useState();
  const [serviceIdQueErr, setserviceIdQueErr] = React.useState();
  const [titleErr, settitleErr] = React.useState();
  const [qtypeErr, setqtypeErr] = React.useState();
  const [levelErr, setlevelErr] = React.useState();
  const [isActiveErr, setisActiveErr] = React.useState();
  // const [isDefaultQueErr, setisDefaultQueErr] = React.useState();
  const [isOptionalQueErr, setisOptionalQueErr] = React.useState();

  // redux
  const dispatch = useDispatch();

  let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
  // console.log("user_permissions from local storage on header page::::::",user_permissions)

  const [ServiceIsLive, setServiceIsLive] = React.useState();

  useEffect(() => {
    dispatch(userActions.checkSessionValidity());

    localStorage.removeItem('view_clients_active_cases');

    let category_type = "supercategory"

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'category_type': category_type,'business_id':business_id,
      'device_token': device_token, 'session_id': session_id })
    };
    setShowProcedure(true);
    fetch( process.env.REACT_APP_GET_CATEGORIES , requestOptions)
      .then((Response) => Response.json())
      .then(superCategoryDetails => {
        setShowProcedure(false);
        console.log("super categoryDetails for category",superCategoryDetails.data.categories);
        if(superCategoryDetails.data.categories)
        {
          // setsuperCategoryList(superCategoryDetails.data.categories.filter(item => item.is_live == true ))
          setsuperCategoryList(superCategoryDetails.data.categories)
        }
        else
        {
          setsuperCategoryList([])
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

  }, []);

  const handleChangeSuprCategoryDropdown = (e) =>{
    dispatch(userActions.checkSessionValidity());
    console.log("e",e.target.value, e.target);
    setselectedSuperCategory(e.target.value);
    setServiceIsLive();
    // if category changes
    setCategorySelected(false);
    setsubCategoryListForSelectedCategory([]);
    setServiceSelected(false);
    setSelectedServicesId("");
    setQuestionList([]);
    setDocTypeList([]);

    // 
    if(e.target.value == "" ){
      setselectedServiceFromCategory()
      setselectedSubCategory()
    }

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'parent_category_id': e.target.value,
      'device_token': device_token, 'session_id': session_id })
    };
    setShowProcedure(true);
    fetch( process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY , requestOptions)
      .then((Response) => Response.json())
      .then( subCategoryDetails => {
        setShowProcedure(false);
        console.log("subCategoryDetails for list",subCategoryDetails.data.category_children);
        if(subCategoryDetails.data.category_children)
        {
          // setsubCategoryList(subCategoryDetails.data.category_children.filter(item => item.is_live == true ))
          setsubCategoryList(subCategoryDetails.data.category_children)
        }
        else
        {
          setsubCategoryList([])
        }
        setSubCtegoryFetched(true)
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  const handleChangeCategoryOrServiceDropdown = (e) => {
    dispatch(userActions.checkSessionValidity());
    console.log("e",e.target.value);
    setselectedSubCategory(e.target.value)
    setServiceSelected(false);
    setSelectedServicesId("");
    setCategorySelected(false);
    setQuestionList([]);
    setDocTypeList([]);

    let matchedCategory = "";

    // 
    if(e.target.value == "" ){
      setselectedServiceFromCategory()
    }
    subCategoryList.map((item, index) =>{
      if(e.target.value == item.category_id){
        console.log("ok this is matched of selected item",item)
        matchedCategory = item;

        // to check if it's live
        if(item.is_live === true){
          console.log("ok this item.is_live === true",item)
          setServiceIsLive(true)
        }
        else if(item.is_live === false){
          console.log("ok this item.is_live === false",item)
          setServiceIsLive(false)
        }
      }
    })
    console.log("matchedCategory",matchedCategory)

    if(matchedCategory.category_type == "category"){
      setCategorySelected(true);
      setShowProcedure(true);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'parent_category_id': e.target.value,
        'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY , requestOptions)
      .then((Response) => Response.json())
      .then( subCategoryDetailsForSelectedCategory => {
        setShowProcedure(false);
        console.log("subCategoryDetailsForSelectedCategory for list",subCategoryDetailsForSelectedCategory.data.category_children);
        if(subCategoryDetailsForSelectedCategory.data.category_children)
        {
          // setsubCategoryListForSelectedCategory(subCategoryDetailsForSelectedCategory.data.category_children.filter(item => item.is_live == true ))
          setsubCategoryListForSelectedCategory(subCategoryDetailsForSelectedCategory.data.category_children)
        }
        else
        {
          setsubCategoryListForSelectedCategory([])
        }

      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    }

    if(matchedCategory.category_type == "service"){
      setServiceSelected(true);
      setSelectedServicesId(e.target.value)
      setdocumentTypeDetails({...documentTypeDetails, service_id: JSON.parse(e.target.value) })
      setquestionDetails({...questionDetails, service_id: JSON.parse(e.target.value) })
      // functionGetTodoAndQuestions()
      const requestOptionsQuestions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'service_id': e.target.value,
        'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_QUESTIONS_FOR_CATEGORY , requestOptionsQuestions)
        .then((Response) => Response.json())
        .then(questionDetails => {
          // setShowProcedure(false);
          console.log("questionDetails for selected service",questionDetails.data.question_info);
          if(questionDetails.data.question_info)
          {
            // setQuestionList(questionDetails.data.question_info.filter(item => item.is_active == true))
            setQuestionList(questionDetails.data.question_info)
          }
          else
          {
            setQuestionList([])
          }
        })
        .catch(err => {
          // setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

        const requestOptionsDocTypes = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify({'service_id': e.target.value,
          'device_token': device_token, 'session_id': session_id })
        };
        fetch( process.env.REACT_APP_GET_SERVICE_DOC_TYPES , requestOptionsDocTypes)
          .then((Response) => Response.json())
          .then(docTypeDetails => {
            // setShowProcedure(false);
            console.log("docTypeDetails for selected service",docTypeDetails.data.doc_types);
            if(docTypeDetails.data.doc_types)
            {
              // setDocTypeList(docTypeDetails.data.doc_types.filter(item => item.is_active == true))
              setDocTypeList(docTypeDetails.data.doc_types)

            }
            else
            {
              setDocTypeList([])
            }
          })
          .catch(err => {
            // setShowProcedure(false);
            Swal.fire({
              icon: 'error',
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });
    }
  }

  const handleChangeServiceDropdown = (e) => {
    dispatch(userActions.checkSessionValidity());
    console.log("e",e.target.value);
    setselectedServiceFromCategory(e.target.value)
    setSelectedServicesId(e.target.value)
    setQuestionList([]);
    setDocTypeList([]);

    let matchedService = "";
    subCategoryListForSelectedCategory.map((item, index) =>{
      if(e.target.value == item.category_id){
        console.log("ok this is matched of selected item",item)
        matchedService = item;

        // to check if it's live
        if(item.is_live === true){
          console.log("ok this item.is_live === true",item)
          setServiceIsLive(true)
        }
        else if(item.is_live === false){
          console.log("ok this item.is_live === false",item)
          setServiceIsLive(false)
        }
      }
    })
    console.log("matchedService",matchedService)

    if(matchedService.category_type == "service"){
      setServiceSelected(true);
      setSelectedServicesId(e.target.value)
      setdocumentTypeDetails({...documentTypeDetails,  service_id: JSON.parse(e.target.value) })
      setquestionDetails({...questionDetails, service_id: JSON.parse(e.target.value) })
      // functionGetTodoAndQuestions()
      const requestOptionsQuestions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'service_id': e.target.value,
        'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_QUESTIONS_FOR_CATEGORY , requestOptionsQuestions)
        .then((Response) => Response.json())
        .then(questionDetails => {
          // setShowProcedure(false);
          console.log("questionDetails for selected service",questionDetails.data.question_info);
          if(questionDetails.data.question_info)
          {
            // setQuestionList(questionDetails.data.question_info.filter(item => item.is_active == true))
            setQuestionList(questionDetails.data.question_info)
          }
          else
          {
            setQuestionList([])
          }
        })
        .catch(err => {
          // setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

      const requestOptionsDocTypes = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'service_id': e.target.value,
        'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_SERVICE_DOC_TYPES , requestOptionsDocTypes)
        .then((Response) => Response.json())
        .then(docTypeDetails => {
          // setShowProcedure(false);
          console.log("docTypeDetails for selected service",docTypeDetails.data.doc_types);
          if(docTypeDetails.data.doc_types)
          {
            // setDocTypeList(docTypeDetails.data.doc_types.filter(item => item.is_active == true))
            setDocTypeList(docTypeDetails.data.doc_types)
          }
          else
          {
            setDocTypeList([])
          }
        })
        .catch(err => {
          // setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }

  }


  const functionGetTodoAndQuestions = (process_id_from_func) => {
    dispatch(userActions.checkSessionValidity());
    console.log("in functionGetTodoAndQuestions ",process_id_from_func)
    // to get questions
    const requestOptionsQuestions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'service_id': SelectedServicesId,
      'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_QUESTIONS_FOR_CATEGORY , requestOptionsQuestions)
      .then((Response) => Response.json())
      .then(questionDetails => {
        // setShowProcedure(false);
        console.log("questionDetails for selected service",questionDetails.data.question_info);
        if(questionDetails.data.question_info)
        {
          // setQuestionList(questionDetails.data.question_info.filter(item => item.is_active == true))
          setQuestionList(questionDetails.data.question_info)
        }
        else
        {
          setQuestionList([])
        }
      })
      .catch(err => {
        // setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

      const requestOptionsDocTypes = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'service_id': SelectedServicesId,
        'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_SERVICE_DOC_TYPES , requestOptionsDocTypes)
        .then((Response) => Response.json())
        .then(docTypeDetails => {
          // setShowProcedure(false);
          console.log("docTypeDetails for selected service",docTypeDetails.data.doc_types);
          if(docTypeDetails.data.doc_types)
          {
            // setDocTypeList(docTypeDetails.data.doc_types.filter(item => item.is_active == true))
            setDocTypeList(docTypeDetails.data.doc_types)
          }
          else
          {
            setDocTypeList([])
          }
        })
        .catch(err => {
          // setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

  }

  // documnet curd
  const AddMoreDocumentOpen = () => {
    console.log("AddMoreDocumentOpen clicked");
    setopenAddMoreDoc(true);
  }

  const handelAddMoreDocReset = () => {
    setdocumentTypeDetails({
      service_id: SelectedServicesId,
      name: "",
      description: "",
      doc_type_tag: "",
      file_type: "",
      allowed_types: "",
      file_size: "",
      is_default: true,
      is_optional: "",
      is_active: true,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })

    setserviceIdErr();
    setnameErr();
    setdescriptionErr();
    setdocTypeErr();
    setfileTypeErr();
    setallowedTypeErr();
    setfileSizeErr();
    // setisDefaultErr();
    setisOptionalErr();
  }

  const handelAddMoreDocClose = () => {
    console.log("AddMoreDocumentOpen clicked");
    setopenAddMoreDoc(false);
    setEditDocCheck(false);

    setdocumentTypeDetails({
      service_id: SelectedServicesId,
      name: "",
      description: "",
      doc_type_tag: "",
      file_type: "",
      allowed_types: "",
      file_size: "",
      is_default: true,
      is_active: true,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })

    setserviceIdErr();
    setnameErr();
    setdescriptionErr();
    setdocTypeErr();
    setfileTypeErr();
    setallowedTypeErr();
    setfileSizeErr();
    // setisDefaultErr();
    setisOptionalErr();
  }

  const validate = () => {
    let serviceIdErr = '';
    let nameErr = '';
    let descriptionErr = '';
    let docTypeErr = '';
    let fileTypeErr = '';
    let allowedTypeErr = '';
    let fileSizeErr = '';
    // let isDefaultErr = '';
    let isOptionalErr = '';

    if(!documentTypeDetails.service_id)  {
        serviceIdErr = 'Please Enter Service Id';
    }
    if(!documentTypeDetails.name)  {
        nameErr = 'Please Enter Name';
    }
    if(!documentTypeDetails.description)  {
        descriptionErr = 'Please Enter Description';
    }
    if(!documentTypeDetails.doc_type_tag)  {
        docTypeErr = 'Please Selecte Document Type';
    }
    if(!documentTypeDetails.file_type)  {
        fileTypeErr = 'Please Select File Type';
    }
    if(!documentTypeDetails.allowed_types)  {
        allowedTypeErr = 'Please Select Allowed Type';
    }

    if(!documentTypeDetails.file_size)  {
        fileSizeErr = 'Please Enter File Size';
    }else if (typeof documentTypeDetails.file_size !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/)
      if (!pattern.test(documentTypeDetails.file_size)) {
        fileSizeErr = "Please enter only numbers.";
      }
    }

    // if( documentTypeDetails.is_default  == "" && documentTypeDetails.is_default !== false && documentTypeDetails.is_default !== true )  {
    //   isDefaultErr = 'Please Select is default';
    // }

    if( documentTypeDetails.is_optional  == "" && documentTypeDetails.is_optional !== false && documentTypeDetails.is_optional !== true )  {
      isOptionalErr = 'Please Select is optional';
    }

    if(serviceIdErr || nameErr || descriptionErr || docTypeErr || fileTypeErr || allowedTypeErr 
        || fileSizeErr || isOptionalErr ){
      // setusernameErr(usernameErr);
      setserviceIdErr(serviceIdErr);
      setnameErr(nameErr);
      setdescriptionErr(descriptionErr);
      setdocTypeErr(docTypeErr);
      setfileTypeErr(fileTypeErr);
      setallowedTypeErr(allowedTypeErr);
      setfileSizeErr(fileSizeErr);
      // setisDefaultErr(isDefaultErr);
      setisOptionalErr(isOptionalErr);

      return false;
    }

    return true;

  } 

  const handelAddMoreDocSubmit = () => {
    console.log("handel AddMoreDocSubmit submit is clicked", documentTypeDetails);
      dispatch(userActions.checkSessionValidity());
      const isValid = validate();
      if(isValid){
        setShowProcedure(true);
        setopenAddMoreDoc(false);
        setEditDocCheck(false);
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(documentTypeDetails)
          };
          return fetch( process.env.REACT_APP_CREATE_DOC_TYPE , requestOptions)
              .then((Response) => Response.json())
              .then(documentTypeCreated => {
                  setShowProcedure(false);
                  console.log("documentTypeCreated on same page",documentTypeCreated.success);
                  if(documentTypeCreated.success == true){
                    refreshDocTypeList();
                    if( EditDocCheck == true ){
                      Swal.fire({
                        icon: 'success',
                        text: "Document Type Updated Succesfully",
                        confirmButtonColor: 'primary',
                        confirmButtonText: 'OK',
                        timer: 5000,
                      })    
                    }
                    else{
                      Swal.fire({
                        icon: 'success',
                        text: "Document Type Added Succesfully",
                        confirmButtonColor: 'primary',
                        confirmButtonText: 'OK',
                        timer: 5000,
                      })    
                    }
                                 
                    setdocumentTypeDetails({
                      //  username: "",
                        service_id: SelectedServicesId,
                        name: "",
                        description: "",
                        doc_type_tag: "",
                        file_type: "",
                        allowed_types: "",
                        file_size: "",
                        is_default: true,
                        is_active: true,
                        device_token: JSON.parse(localStorage.getItem('device_token')),
                        session_id: JSON.parse(localStorage.getItem('session_id')),
                    })
                    // setusernameErr();
                    setserviceIdErr();
                    setnameErr();
                    setdescriptionErr();
                    setdocTypeErr();
                    setfileTypeErr();
                    setallowedTypeErr();
                    setfileSizeErr();
                    // setisDefaultErr();
                    setisOptionalErr();
                  }
                  if(documentTypeCreated.success == false || documentTypeCreated.success == null ){
                    Swal.fire({
                      icon: 'error',
                      text: documentTypeCreated.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setopenAddMoreDoc(true);
                      setEditDocCheck(true);
                    })
                }
              })
              .catch(err => {
                setShowProcedure(false);
                Swal.fire({
                  icon: 'error',
                  text: "Server Error. Please try again.",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              });
      }
  }

  const bodyAddMoreDoc = (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <Grid container item xs={12}>
          {/* <Grid item xs={10} >
            {
              ( EditDocCheck == true )? 
              (
                <h2 style={{marginLeft:'40%'}}>Edit Document</h2>
              ) 
              :(
                <h2 style={{marginLeft:'40%'}}>Add More Document</h2>
              )
            }
          </Grid> */}
          <Grid item xs={11} >
            {
              ( EditDocCheck == true )? 
              (
                <h2 style={{marginLeft:'40%'}}>Edit Document</h2>
              ) 
              :(
                <h2 style={{marginLeft:'34%'}}>Add More Document</h2>
              )
            }
          </Grid>
          <Grid item xs={1} style={{marginTop:'4%',}}>
            <CloseIcon onClick={()=>handelAddMoreDocClose()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
        </Grid> 
      </div>
      <div className={classes.modalBody} style={{position:'absolute'}}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoComplete="name"
          value={documentTypeDetails.name}
          onChange={(e) => setdocumentTypeDetails({...documentTypeDetails, name:e.target.value }) }
        />
        <div className={classes.validation}>{(documentTypeDetails.name)?(<div></div>):(nameErr)}</div>
        <TextField
          margin="normal"
          required
          fullWidth
          id="description"
          label="Description"
          name="description"
          autoComplete="description"
          value={documentTypeDetails.description}
          onChange={(e) => setdocumentTypeDetails({...documentTypeDetails, description:e.target.value }) }
        />
        <div className={classes.validation}>{(documentTypeDetails.description)?(<div></div>):(descriptionErr)}</div>
        
        <FormControl className={classes.formControlModal}>
          <Grid style={{marginTop:"2%", color:"grey"}}>Document Type*</Grid>
          <Grid container item xs={12} style={{marginTop:"1%"}}>
            <RadioGroup
              row
              id ="doc_type_tag"
              aria-label="doc_type_tag"
              defaultValue={documentTypeDetails.doc_type_tag}
              onChange={ (e) => setdocumentTypeDetails({...documentTypeDetails, doc_type_tag: e.target.value })}
            >                  
            <div>
              <FormControlLabel
                value="PERSONAL"
                classes={{ label: classes.label }}
                control={<Radio size="small" color="primary" />}
                label= "Personal"
              />
            </div>
            <div>
              <FormControlLabel
                value="BUSINESS"
                classes={{ label: classes.label }}
                control={<Radio size="small" color="primary" />}
                label= "Business"
              />
            </div>
          </RadioGroup>
          </Grid>
        </FormControl>
        <div className={classes.validation}>{(documentTypeDetails.doc_type_tag)?(<div></div>):(docTypeErr)}</div>

        <FormControl className={classes.formControlModal}>
          <Grid style={{marginTop:"2%", color:"grey"}}>File Type*</Grid>
          <Select
            native
            labelId="file_type"
            id="file_type"
            value={documentTypeDetails.file_type}
            onChange={(e) => setdocumentTypeDetails({...documentTypeDetails, file_type:e.target.value }) }
          >
            <option value="" style={{color:'grey'}}>Select File Type</option>
            <option value="PDF" >PDF</option>
            <option value="Image" >Image</option>
            <option value="DOC" >DOC</option>
            </Select>
        </FormControl>
        <div className={classes.validation}>{(documentTypeDetails.file_type)?(<div></div>):(fileTypeErr)}</div>

        <FormControl className={classes.formControlModal}>
          <InputLabel id="allowed_types">Allowed Type*</InputLabel>
          <div style={{marginTop:"10%"}}>
          <Multiselect
            data={allowedTypesList}
            placeholder="Select Allowed Type"
            onChange={ async(event)=>{
              console.log(event);
              var joined=[];
              console.log("length : "+event.length);
              if(event.length === 0)
              {
                await setdocumentTypeDetails({...documentTypeDetails, allowed_types: "" })
              }
              event.map(async(data)=>{
                console.log("data in eve map : "+JSON.stringify(data));
                joined = joined.concat(data);
                console.log("data join : ",joined);
                await setdocumentTypeDetails({...documentTypeDetails, allowed_types: joined.toString() })
                console.log("check this documentTypeDetails",documentTypeDetails)
              })
            }}
          />
          </div>
        </FormControl>
        <div className={classes.validation}>{(documentTypeDetails.allowed_types)?(<div></div>):(allowedTypeErr)}</div>

        <TextField
          margin="normal"
          required
          fullWidth
          id="file_size"
          label="File Size(In mb)"
          name="ile_size"
          autoComplete="file_size"
          value={documentTypeDetails.file_size}
          onChange={(e) => setdocumentTypeDetails({...documentTypeDetails, file_size:e.target.value }) }
        />
        <div className={classes.validation}>{fileSizeErr}</div>

        {/* <FormControl className={classes.formControlModal}>
          <Grid style={{marginTop:"2%", color:"grey"}}>Is Default*</Grid>
          <Grid container item xs={12} style={{marginTop:"1%"}}>
            <RadioGroup
              row
              id ="is_default"
              aria-label="is_default"
              name="is_default"
              defaultValue={JSON.stringify(documentTypeDetails.is_default)}
              onChange={ (e) => setdocumentTypeDetails({...documentTypeDetails, is_default: JSON.parse(e.target.value) })}
            >                  
              <div>
                <FormControlLabel
                  value="true"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "Yes"
                />
              </div>
              <div>
                <FormControlLabel
                  value="false"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "No"
                />
              </div>
            </RadioGroup>
          </Grid>
        </FormControl> */}
        {/* <div className={classes.validation}>{(documentTypeDetails.is_default == "" && documentTypeDetails.is_default !== false && documentTypeDetails.is_default !== true)?(isDefaultErr):(<div></div>)}</div> */}

        <FormControl className={classes.formControlModal}>
          <Grid style={{marginTop:"2%", color:"grey"}}>Is Optional*</Grid>
          <Grid container item xs={12} style={{marginTop:"1%"}}>
            <RadioGroup
              row
              id ="docu_is_optional"
              aria-label="is_optional"
              name="is_optional"
              defaultValue={JSON.stringify(documentTypeDetails.is_optional)}
              onChange={ (e) => setdocumentTypeDetails({...documentTypeDetails, is_optional: JSON.parse(e.target.value) })}
            >                  
              <div>
                <FormControlLabel
                  value="true"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "Yes"
                />
              </div>
              <div>
                <FormControlLabel
                  value="false"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "No"
                />
              </div>
            </RadioGroup>
          </Grid>
        </FormControl>
        <div className={classes.validation}>{(documentTypeDetails.is_optional  == "" && documentTypeDetails.is_optional !== false && documentTypeDetails.is_optional !== true)?(isOptionalErr):(<div></div>)}</div>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          {
            ( EditDocCheck == true )? 
            (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handelAddMoreDocSubmit}
              >
                Update
              </Button>
            ) 
            :(
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handelAddMoreDocSubmit}
              >
                Add
              </Button>
            )
          }
          
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            // color="grey"
            className={classes.submit}
            onClick={handelAddMoreDocReset}
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
  )

  const refreshDocTypeList = () => {
    const requestOptionsDocTypes = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'service_id': SelectedServicesId,
      'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_SERVICE_DOC_TYPES , requestOptionsDocTypes)
      .then((Response) => Response.json())
      .then(docTypeDetails => {
        // setShowProcedure(false);
        console.log("docTypeDetails for selected service",docTypeDetails.data.doc_types);
        if(docTypeDetails.data.doc_types)
        {
          // setDocTypeList(docTypeDetails.data.doc_types.filter(item => item.is_active == true))
          // setDocTypeList(docTypeDetails.data.doc_types.filter(item => item.is_active == true))
          setDocTypeList(docTypeDetails.data.doc_types)
        }
        else
        {
          setDocTypeList([])
        }
      })
      .catch(err => {
        // setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  const handelEditDocType = async(item) => {
    console.log("handel EditDocType item",item)
    await setdocumentTypeDetails({
      //  username: "",
      service_id: item.service_id,
      name: item.name,
      description: item.description,
      doc_type_tag: item.doc_type_tag,
      file_type: item.file_type,
      allowed_types: item.allowed_types,
      file_size: item.file_size,
      is_default: item.is_default,
      is_optional: item.is_optional,
      // document_type_id: item.document_type_id,
      doc_type_id: item.document_type_id,
      is_active: item.is_active,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    setopenAddMoreDoc(true);
    setEditDocCheck(true);

  }

  // const handelDeleteDocType = async(item) => {
  //   console.log("handel DeleteDocType item",item);
  //   let idtoDelete =  item.address_id;
  //     await setdocumentTypeDetails({
  //       service_id: item.service_id,
  //       name: item.name,
  //       description: item.description,
  //       doc_type_tag: item.doc_type_tag,
  //       file_type: item.file_type,
  //       allowed_types: item.allowed_types,
  //       file_size: item.file_size,
  //       is_default: item.is_default,
  //       // document_type_id: item.document_type_id,
  //       doc_type_id: item.document_type_id,
  //       is_active: false,
  //       device_token: JSON.parse(localStorage.getItem('device_token')),
  //       session_id: JSON.parse(localStorage.getItem('session_id')),
  //     });
  //     console.log("documentTypeDetails",documentTypeDetails);

  //     let deleteDocTypeDetailsFinal = ({
  //       service_id: item.service_id,
  //       name: item.name,
  //       description: item.description,
  //       doc_type_tag: item.doc_type_tag,
  //       file_type: item.file_type,
  //       allowed_types: item.allowed_types,
  //       file_size: item.file_size,
  //       is_default: item.is_default,
  //       // document_type_id: item.document_type_id,
  //       doc_type_id: item.document_type_id,
  //       is_active: false,
  //       device_token: JSON.parse(localStorage.getItem('device_token')),
  //       session_id: JSON.parse(localStorage.getItem('session_id')),
  //     });
  //     console.log("deleteDocTypeDetailsFinal",deleteDocTypeDetailsFinal);
        
  //     const swalWithBootstrapButtons = Swal.mixin({
  //       customClass: {
  //         confirmButton: classes.buttonSwalDelete,
  //         cancelButton: classes.buttonSwalCancel,
  //       },
  //       buttonsStyling: false
  //     })
  //     swalWithBootstrapButtons.fire({
  //       title: 'Are you sure?',
  //       text: "You won't be able to revert this!",
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#d33',
  //       confirmButtonText: 'Yes',
  //       cancelButtonText: 'No, cancel',
  //       reverseButtons: true
  //     }).then((result) => {

  //       if (result.value) {
  //         setShowProcedure(true);
  //         const requestOptions = {
  //           method: 'POST',
  //           headers: authHeader(),
  //           body: JSON.stringify(deleteDocTypeDetailsFinal)
  //         };
  //         return fetch( process.env.REACT_APP_CREATE_DOC_TYPE , requestOptions)
  //           .then((Response) => Response.json())
  //           .then(documentDeleted => {
  //             setShowProcedure(false);
  //             console.log("documentDeleted",documentDeleted.success);
  //             console.log("documentDeleted",documentDeleted);
  //             if(documentDeleted.success == true){
  //               refreshDocTypeList();
  //               Swal.fire({
  //                 icon: 'success',
  //                 text: "Data Updated Succesfully",
  //                 confirmButtonColor: 'primary',
  //                 confirmButtonText: 'OK',
  //                 timer: 5000,
  //               })
  //               setdocumentTypeDetails({
  //                   service_id: SelectedServicesId,
  //                   name: "",
  //                   description: "",
  //                   doc_type_tag: "",
  //                   file_type: "",
  //                   allowed_types: "",
  //                   file_size: "",
  //                   is_default: "",
  //                   is_active: true,
  //                   device_token: JSON.parse(localStorage.getItem('device_token')),
  //                   session_id: JSON.parse(localStorage.getItem('session_id')),
  //               })
  //             }
  //             if(documentDeleted.success == false ){
  //               Swal.fire({
  //                 icon: 'error',
  //                 text: documentDeleted.errors,
  //                 confirmButtonColor: '#d33',
  //                 confirmButtonText: 'OK',
  //                 // timer: 5000,
  //               })
  //             }         
  //         })
  //         .catch(err => {
  //           setShowProcedure(false);
  //           Swal.fire({
  //             icon: 'error',
  //             text: "Server Error. Please try again.",
  //             confirmButtonColor: '#d33',
  //             confirmButtonText: 'OK'
  //           })
  //         });
                
  //       } else if (
  //         /* Read more about handling dismissals below */
  //         result.dismiss === Swal.DismissReason.cancel
  //       ) 
  //       {
  //         // swalWithBootstrapButtons.fire(
  //         //   'Cancelled',
  //         //   'Your imaginary file is safe :)',
  //         //   'error'
  //         // )
  //       }
  //     })
  // }

  const handelDeleteDocType = async(item) => {
    console.log("handel DeleteDocType item",item);
    let idtoDelete =  item.address_id;
      await setdocumentTypeDetails({
        service_id: item.service_id,
        name: item.name,
        description: item.description,
        doc_type_tag: item.doc_type_tag,
        file_type: item.file_type,
        allowed_types: item.allowed_types,
        file_size: item.file_size,
        is_default: item.is_default,
        is_optional: item.is_optional,
        // document_type_id: item.document_type_id,
        doc_type_id: item.document_type_id,
        is_active: !item.is_active,
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      });
      console.log("documentTypeDetails",documentTypeDetails);

      let deleteDocTypeDetailsFinal = ({
        service_id: item.service_id,
        name: item.name,
        description: item.description,
        doc_type_tag: item.doc_type_tag,
        file_type: item.file_type,
        allowed_types: item.allowed_types,
        file_size: item.file_size,
        is_default: item.is_default,
        is_optional: item.is_optional,
        // document_type_id: item.document_type_id,
        doc_type_id: item.document_type_id,
        is_active: !item.is_active,
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      });
      console.log("deleteDocTypeDetailsFinal",deleteDocTypeDetailsFinal);
        
          setShowProcedure(true);
          const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(deleteDocTypeDetailsFinal)
          };
          return fetch( process.env.REACT_APP_CREATE_DOC_TYPE , requestOptions)
            .then((Response) => Response.json())
            .then(documentDeleted => {
              setShowProcedure(false);
              console.log("documentDeleted",documentDeleted.success);
              console.log("documentDeleted",documentDeleted);
              if(documentDeleted.success == true){
                setshowAntSwitch(false);
                refreshDocTypeList();
                // Swal.fire({
                //   icon: 'success',
                //   text: "Data Updated Succesfully",
                //   confirmButtonColor: 'primary',
                //   confirmButtonText: 'OK',
                //   timer: 5000,
                // })
                setdocumentTypeDetails({
                    service_id: SelectedServicesId,
                    name: "",
                    description: "",
                    doc_type_tag: "",
                    file_type: "",
                    allowed_types: "",
                    file_size: "",
                    is_default: true,
                    is_optional: "",
                    is_active: true,
                    device_token: JSON.parse(localStorage.getItem('device_token')),
                    session_id: JSON.parse(localStorage.getItem('session_id')),
                })
                setshowAntSwitch(true);
              }
              if(documentDeleted.success == false ){
                Swal.fire({
                  icon: 'error',
                  text: documentDeleted.errors,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK',
                  // timer: 5000,
                })
              }         
          })
          .catch(err => {
            setShowProcedure(false);
            Swal.fire({
              icon: 'error',
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });
  }

  // curd questions
  const AddMoreQuestionsOpen = () => {
    setopenAddMoreQue(true);
  }

  const handelAddMoreQueReset = () => {
    setquestionDetails({
      service_id: SelectedServicesId,
      title: "",
      qtype: "",
      level: "",
      is_active: "",
      is_default: true,
      is_optional: "",
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    setserviceIdErr();
    settitleErr();
    setqtypeErr();
    setlevelErr();
    setisActiveErr();
    // setisDefaultQueErr();
    setisOptionalQueErr();
  }

  // const handleAddMoreQueMinimize = () => {
  //   setopenAddMoreQue(false);
  // }

  const handleAddMoreOptionForQue = () => {
    // const some_array = [...optionArray]
    // some_array = ""
    const some_array = [...optionArray, ""]
    setoptionArray(some_array)
    console.log("some_array====",some_array)
    console.log("optionArray====",optionArray)

    // let newValue = ""
    // let some_array = optionArray.push(newValue);
    // console.log("some_array====",some_array)

    // optionArray.push("");
    // console.log("optionArray====",optionArray)
  }

  const handleRemoveOptionForQue = (item, index) => {
    console.log("handleRemoveOptionForQue", item, index)
    const some_array = [...optionArray]
    some_array.splice(index, 1);
    setoptionArray(some_array)
    console.log("some_array====",some_array)
    console.log("optionArray====",optionArray)
  }

  const handleChangeOption = (e, index) => {
    console.log("in handleChangeOption", e.target.value, index)
    const { name, value } = e.target;
    const some_array = [...optionArray]
    some_array[index] = e.target.value
    setoptionArray(some_array)
  }

  const handelAddMoreQueClose = () => {
    setopenAddMoreQue(false);
    setEditQuestionCheck(false);
    setquestionDetails({
      service_id: SelectedServicesId,
      title: "",
      qtype: "",
      level: "",
      is_active: "",
      is_default: true,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    setserviceIdErr();
    settitleErr();
    setqtypeErr();
    setlevelErr();
    setisActiveErr();
    // setisDefaultQueErr();
    setisOptionalQueErr();

    // setoptionOne();
    // setoptionTwo();
    // setoptionThree();
    // setoptionFour();
    setoptionArray(["",""]);
    setoptionOneErr();
    // setoptionTwoErr();
    // setoptionThreeErr();
    // setoptionFourErr(); 
  }

  const validateQuestion = () => {
    let serviceIdErr = '';
    let titleErr = '';
    let qtypeErr = '';
    let levelErr = '';
    let isActiveErr = '';
    // let isDefaultQueErr = '';
    
    let optionOneErr = "";
    // let optionTwoErr = "";
    // let optionThreeErr = "";
    // let optionFourErr = ""; 
    let isOptionalQueErr = '';

    if(!questionDetails.service_id)  {
      serviceIdErr = 'Please Select Service';
    }
    if(!questionDetails.title)  {
        titleErr = 'Please Enter Title';
    }
    if(!questionDetails.qtype)  {
        qtypeErr = 'Please Select Question Type';
    }
    if(!questionDetails.level)  {
        levelErr = 'Please Enter Level';
    }
      else if (typeof questionDetails.level !== "undefined") {
        var pattern = new RegExp(/^[0-9\b]+$/)
        if (!pattern.test(questionDetails.level)) {
          // isValid = false;
          levelErr = "Please enter only number";
        }
      }

    if( questionDetails.is_active == "" && questionDetails.is_active !== false && questionDetails.is_active !== true )  {
        isActiveErr = 'Please Select Is Active';
    }
    // if( questionDetails.is_default == "" && questionDetails.is_default !== false && questionDetails.is_default !== true )  {
    //   isDefaultQueErr = 'Please Select Is Default';
    // }
    if( questionDetails.is_optional == "" && questionDetails.is_optional !== false && questionDetails.is_optional !== true )  {
      isOptionalQueErr = 'Please Select Is Optional';
    }

    if(questionDetails.qtype == "SINGLE-ANSWER" || questionDetails.qtype == "MULTIPLE-ANSWER")  {
      // if(!optionOne){
      //   optionOneErr = 'Please Add Option One';
      // }
      optionArray.map((item,index)=>{
        console.log("in openarray validation")
        if( item == ""){
          optionOneErr = 'Please Add Option One';
        }
      })
    }

    // if(questionDetails.qtype == "SINGLE-ANSWER" || questionDetails.qtype == "MULTIPLE-ANSWER")  {
    //   if(!optionTwo)  {
    //       optionTwoErr = 'Please Add Option Two';
    //   }
    // }

    // if(questionDetails.qtype == "SINGLE-ANSWER" || questionDetails.qtype == "MULTIPLE-ANSWER")  {
    //   if(!optionThree)  {
    //       optionThreeErr = 'Please Add Option Three';
    //   }
    // }

    // if(questionDetails.qtype == "SINGLE-ANSWER" || questionDetails.qtype == "MULTIPLE-ANSWER")  {
    //   if(!optionFour)  {
    //       optionFourErr = 'Please Add Option Four';
    //   }
    // }

    if( serviceIdErr || titleErr || qtypeErr || levelErr || isActiveErr || isOptionalQueErr
       || optionOneErr ){
      setserviceIdErr(serviceIdErr);
      settitleErr(titleErr);
      setqtypeErr(qtypeErr);
      setlevelErr(levelErr);
      setisActiveErr(isActiveErr);
      setisOptionalQueErr(isOptionalQueErr);
      // setisDefaultQueErr(isDefaultQueErr);

      setoptionOneErr(optionOneErr);
      // setoptionTwoErr(optionTwoErr);
      // setoptionThreeErr(optionThreeErr);
      // setoptionFourErr(optionFourErr);

      return false;
    }

    return true;

  }

  const handelAddMoreQuestion = () => {
    console.log("questionDetails = ", questionDetails)
    dispatch(userActions.checkSessionValidity());
      const isValid = validateQuestion();
      if(isValid){
        setShowProcedure(true);
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(questionDetails)
          };
          return fetch( process.env.REACT_APP_ADD_QUESTION , requestOptions)
              .then((Response) => Response.json())
              .then(questionCreated => {
                  setShowProcedure(false);
                  console.log("questionCreated on same page",questionCreated.data);
                  console.log("questionCreated on same page",questionCreated.success);
                  console.log("questionCreated on same page",questionCreated.data.question_info.question_id);
                  if(questionCreated.success == true){
                    refreshQuestions();
                    console.log("(questionCreated.success == true"); 
                    if(questionCreated.data.question_info.qtype == "SINGLE-ANSWER" || 
                        questionCreated.data.question_info.qtype == "MULTIPLE-ANSWER" )
                      { 
                        console.log("single or multiple ans"); 
                        const createOptionObj = {   
                          question_id: questionCreated.data.question_info.question_id,
                          options: optionArray,
                          // options: [optionOne, optionTwo, optionThree, optionFour ],
                          // options: [
                          //   { option: optionOne, option_id:  optionOneId}, 
                          //   { option: optionTwo, option_id:  optionTwoId}, 
                          //   { option: optionThree, option_id:  optionThreeId}, 
                          //   { option: optionFour, option_id:  optionFourId}, 
                          // ],
                          device_token: JSON.parse(localStorage.getItem('device_token')),
                          session_id: JSON.parse(localStorage.getItem('session_id')),
                        };
                        refreshQuestions();
                        // let createOptionObj = {}
                        // if( EditQuestionCheck == true ){
                        //    createOptionObj = {   
                        //     question_id: questionCreated.data.question_info.question_id,
                        //     // options: [optionOne, optionTwo, optionThree, optionFour ],
                        //     options: [
                        //       { option: optionOne, option_id:  optionOneId}, 
                        //       { option: optionTwo, option_id:  optionTwoId}, 
                        //       { option: optionThree, option_id:  optionThreeId}, 
                        //       { option: optionFour, option_id:  optionFourId}, 
                        //     ],
                        //     device_token: JSON.parse(localStorage.getItem('device_token')),
                        //     session_id: JSON.parse(localStorage.getItem('session_id')),
                        //   };
                        // }
                        // else{
                        //    createOptionObj = {   
                        //     question_id: questionCreated.data.question_info.question_id,
                        //     options: [optionOne, optionTwo, optionThree, optionFour ],
                        //     // options: [
                        //     //   { option: optionOne, option_id:  optionOneId}, 
                        //     //   { option: optionTwo, option_id:  optionTwoId}, 
                        //     //   { option: optionThree, option_id:  optionThreeId}, 
                        //     //   { option: optionFour, option_id:  optionFourId}, 
                        //     // ],
                        //     device_token: JSON.parse(localStorage.getItem('device_token')),
                        //     session_id: JSON.parse(localStorage.getItem('session_id')),
                        //   };
                        // }
                        
                        console.log("createOptionObj = =",createOptionObj)
                        const requestOptionsOptions = {
                          method: 'POST',
                          headers: authHeader(),
                          body: JSON.stringify(createOptionObj)
                        };                    
                        return fetch( process.env.REACT_APP_ADD_QUESTION_OPTIONS , requestOptionsOptions)
                          .then((Response) => Response.json())
                          .then(optionCreated => {
                              console.log("optionCreated on same page",optionCreated.success);
                              console.log("optionCreated on same page",optionCreated);
                              if(optionCreated.success == true){
                                if(EditQuestionCheck == true){
                                  refreshQuestions();
                                  Swal.fire({
                                    icon: 'success',
                                    text: "Question Edited Succesfully",
                                    confirmButtonColor: 'primary',
                                    confirmButtonText: 'OK',
                                    timer: 5000,
                                  })
                                }
                                else{
                                  Swal.fire({
                                    icon: 'success',
                                    text: "Question Added Succesfully",
                                    confirmButtonColor: 'primary',
                                    confirmButtonText: 'OK',
                                    timer: 5000,
                                  })
                                }
                                
                                setopenAddMoreQue(false);
                                setquestionDetails({
                                  service_id: SelectedServicesId,
                                  title: "",
                                  qtype: "",
                                  level: "",
                                  is_active: "",
                                  is_default: true,
                                  is_optional: "",
                                  device_token: JSON.parse(localStorage.getItem('device_token')),
                                  session_id: JSON.parse(localStorage.getItem('session_id')),
                                })
                                // setoptionOne();
                                // setoptionTwo();
                                // setoptionThree();
                                // setoptionFour();
                                setoptionArray(["",""]);

                                setserviceIdErr();
                                settitleErr();
                                setqtypeErr();
                                setlevelErr();
                                setisActiveErr();
                                // setisDefaultQueErr();
                                setisOptionalQueErr();

                                setoptionOneErr();
                                // setoptionTwoErr();
                                // setoptionThreeErr();
                                // setoptionFourErr(); 

                                refreshQuestions();
                              }
                              if(questionCreated.success == false){
                                Swal.fire({
                                  icon: 'error',
                                  text: optionCreated.errors,
                                  confirmButtonColor: '#d33',
                                  confirmButtonText: 'OK',
                                  timer: 5000,
                                }).then(function (result) {
                                  console.log("result of swal",result)
                                  setopenAddMoreQue(true);
                                })
                              }
                        })
                        .catch(err => {
                          setShowProcedure(false);
                          Swal.fire({
                            icon: 'error',
                            // text: "Something went wrong",
                            text: "Server Error. Please try again.",
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                          })
                        });
                    }
                    else if(questionCreated.data.question_info.qtype == "BOOLEAN" || 
                        questionCreated.data.question_info.qtype == "TEXT-ANSWER" )
                    {
                      refreshQuestions();
                      console.log("boolean or text ans");
                      if( EditQuestionCheck == true ){
                        Swal.fire({
                          icon: 'success',
                          text: "Question Updated Succesfully",
                          confirmButtonColor: 'primary',
                          confirmButtonText: 'OK',
                          timer: 5000,
                        })
                      setEditQuestionCheck(false);
                      }
                      else{
                        Swal.fire({
                          icon: 'success',
                          text: "Question Added Succesfully",
                          confirmButtonColor: 'primary',
                          confirmButtonText: 'OK',
                          timer: 5000,
                        })
                      }
                      setopenAddMoreQue(false);
                      setquestionDetails({
                        service_id: SelectedServicesId,
                        title: "",
                        qtype: "",
                        level: "",
                        is_active: "",
                        is_default: true,
                        is_optional: "",
                        device_token: JSON.parse(localStorage.getItem('device_token')),
                        session_id: JSON.parse(localStorage.getItem('session_id')),
                      })
                      // setoptionOne();
                      // setoptionTwo();
                      // setoptionThree();
                      // setoptionFour();''
                      setoptionArray(["",""]);

                      setserviceIdErr();
                      settitleErr();
                      setqtypeErr();
                      setlevelErr();
                      setisActiveErr();
                      // setisDefaultQueErr();
                      setisOptionalQueErr();

                      setoptionOneErr();
                      // setoptionTwoErr();
                      // setoptionThreeErr();
                      // setoptionFourErr();
                    } 
                        
                  }
                  else if(questionCreated.success == false)
                  {
                    setopenAddMoreQue(true);
                    Swal.fire({
                      icon: 'error',
                      text: questionCreated.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setopenAddMoreQue(true);
                    })
                  }
                })
                .catch(err => {
                  setShowProcedure(false);
                  Swal.fire({
                    icon: 'error',
                    // text: "Something went wrong",
                    text: "Server Error. Please try again.",
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                  })
                });
      }
  }
  
  const refreshQuestions = () => {
    const requestOptionsQuestions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'service_id': SelectedServicesId,
      'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_QUESTIONS_FOR_CATEGORY , requestOptionsQuestions)
      .then((Response) => Response.json())
      .then(questionDetails => {
        // setShowProcedure(false);
        console.log("questionDetails for selected service",questionDetails.data.question_info);
        if(questionDetails.data.question_info)
        {
          // setQuestionList(questionDetails.data.question_info.filter(item => item.is_active == true))
          setQuestionList(questionDetails.data.question_info)
        }
        else
        {
          setQuestionList([])
        }
      })
      .catch(err => {
        // setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

  }

  const bodyAddMoreQuestions = (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        {/* <Grid container item xs={12}>
          <Grid item xs={10} >
            {
              ( EditQuestionCheck == true )? 
              (
                <h2 style={{marginLeft:'40%'}}>Edit Question</h2>
              ) 
              :(
                <h2 style={{marginLeft:'40%'}}>Add More Question</h2>
              )
            }
          
          </Grid>
        </Grid>  */}
        <Grid container item xs={12}>
          <Grid item xs={11} >
            {
              ( EditQuestionCheck == true )? 
              (
                <h2 style={{marginLeft:'40%'}}>Edit Question</h2>
              ) 
              :(
                <h2 style={{marginLeft:'36%'}}>Add More Question</h2>
              )
            }
          </Grid>
          <Grid item xs={1} style={{marginTop:'4%',}}>
            <CloseIcon onClick={()=>handelAddMoreQueClose()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
        </Grid>
      </div>
      <div className={classes.modalBody} style={{position:'absolute'}}>

        <TextField
          margin="normal"
          required
          fullWidth
          id="title"
          label="Title"
          name="title"
          autoComplete="title"
          value={questionDetails.title}
          onChange={(e) => setquestionDetails({...questionDetails, title:e.target.value }) }
        />
        <div className={classes.validation}>{(questionDetails.title)?(<div></div>):(titleErr)}</div>

        <FormControl className={classes.formControlModal}>
          <Grid style={{marginTop:"2%", color:"grey"}}>Question Type*</Grid>
          <Select
            native
            labelId="qtype"
            id="qtype"
            value={questionDetails.qtype}
            onChange={(e) => setquestionDetails({...questionDetails, qtype:e.target.value }) }
          >
            <option value="" style={{color:'grey'}}>Select  Question Type</option>
            <option value="BOOLEAN">Yes / No</option>
            <option value="SINGLE-ANSWER">Single-Answer</option>
            <option value="MULTIPLE-ANSWER">Multiple-Answers</option>
            <option value="TEXT-ANSWER">Description-Answer</option>
          </Select>
        </FormControl>
        {
          ( questionDetails.qtype == "SINGLE-ANSWER" || questionDetails.qtype == "MULTIPLE-ANSWER" )?(
            <div style={{marginTop:"5%", borderTop: "1px solid grey" , borderBottom:"1px solid grey",
              borderLeft: "1px solid grey", borderRight:"1px solid grey"   }}>
            {
              <div>
                <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',
                  cursor:'pointer', marginTop:'2%',marginRight:'2%'}}
                  onClick={() => handleAddMoreOptionForQue() }
                >
                  <Grid style={{color:'blue'}}>+ Add Another Option</Grid>
                </div>
              <div style={{ marginLeft:"2%", marginRight:"2%", marginBottom: "2%" }}>
                {
                  ( ShowMultipleorSingleAns == true )?
                  (
                    <div>
                      {
                        optionArray.map((item, index) => (
                          <Grid>
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="optionOne"
                              label="Option"
                              name="option"
                              autoComplete="option"
                              value={item}
                              onChange={async(e) => await handleChangeOption(e, index)}
                            />
                            {
                              ( index > "1" )?
                              (
                                <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',
                                  cursor:'pointer',marginRight:'2%'}}
                                  onClick={() => handleRemoveOptionForQue(item, index) }
                                >
                                  <Grid style={{color:'blue'}}>- Remove Option</Grid>
                                </div>
                              ):
                              (<Grid></Grid>)
                            }
                          </Grid>
                          
                        ))
                      }
                      {/* <div className={classes.validation}>{(optionOneErr)?(<div></div>):(optionOneErr)}</div>  */}
                      <div className={classes.validation}>{optionOneErr}</div> 
                      

                      {/* <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="optionOne"
                        label="Option One"
                        name="optionOne"
                        autoComplete="optionOne"
                        value={optionOne}
                        onChange={async(e) => await setoptionOne(e.target.value)}
                      />
                      <div className={classes.validation}>{(optionOne)?(<div></div>):(optionOneErr)}</div> */}

                      {/* <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="optionTwo"
                        label="Option Two"
                        name="optionTwo"
                        autoComplete="optionTwo"
                        value={optionTwo}
                        onChange={async(e) => await setoptionTwo(e.target.value)}
                      />
                      <div className={classes.validation}>{(optionTwo)?(<div></div>):(optionTwoErr)}</div> */}

                      {/* <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="optionThree"
                        label="Option Three"
                        name="optionThree"
                        autoComplete="optionThree"
                        value={optionThree}
                        onChange={async(e) => await setoptionThree(e.target.value)}
                      />
                      <div className={classes.validation}>{(optionThree)?(<div></div>):(optionThreeErr)}</div> */}

                      {/* <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="optionFour"
                        label="Option Four"
                        name="optionFour"
                        autoComplete="optionFour"
                        value={optionFour}
                        onChange={async(e) => await setoptionFour(e.target.value)}
                      />
                      <div className={classes.validation}>{(optionFour)?(<div></div>):(optionFourErr)}</div> */}
                    </div>
                  )
                  :(<Grid></Grid>)
                }
              </div>
              </div>
            }
            </div>
            ):(
            <div></div>
          )
        }
        <div className={classes.validation}>{(questionDetails.qtype)?(<div></div>):(qtypeErr)}</div>

        <FormControl className={classes.formControlLevel}>
          {/* <TextField
            margin="normal"
            required
            fullWidth
            id="level"
            label="Level"
            name="level"
            autoComplete="level"
            value={questionDetails.level}
            onChange={(e) => setquestionDetails({...questionDetails, level:e.target.value }) }
          /> */}
          <Grid style={{marginTop:"2%", color:"grey"}}>Level*</Grid>
          <Grid container item xs={12} style={{marginTop:"1%"}}>
            <RadioGroup
              row
              id ="level"
              aria-label="level"
              name="level"
              defaultValue={JSON.stringify(questionDetails.level)}
              onChange={ (e) => setquestionDetails({...questionDetails, level: JSON.parse(e.target.value) })}
            >                  
              <div>
                <FormControlLabel
                  value="1"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "1"
                />
              </div>
              <div>
                <FormControlLabel
                  value="2"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "2"
                />
              </div>
              <div>
                <FormControlLabel
                  value="3"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "3"
                />
              </div>
            </RadioGroup>
          </Grid>
        </FormControl>
        <div className={classes.validation}>{levelErr}</div>

        <FormControl className={classes.formControlModal}>
          <Grid style={{marginTop:"2%", color:"grey"}}>Is Active*</Grid>
          <Grid container item xs={12} style={{marginTop:"1%"}}>
            <RadioGroup
              row
              id ="is_active"
              aria-label="is_active"
              name="is_active"
              defaultValue={JSON.stringify(questionDetails.is_active)}
              onChange={ (e) => setquestionDetails({...questionDetails, is_active: JSON.parse(e.target.value) })}
            >                  
              <div>
                <FormControlLabel
                  value="true"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "Yes"
                />
              </div>
              <div>
                <FormControlLabel
                  value="false"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "No"
                />
              </div>
            </RadioGroup>
          </Grid>
        </FormControl>
        <div className={classes.validation}>{(questionDetails.is_active == "" &&  questionDetails.is_active !== false && questionDetails.is_active !== true)?(isActiveErr):(<div></div>)}</div>

        {/* <FormControl className={classes.formControlModal}>
          <Grid style={{marginTop:"2%", color:"grey"}}>Is Default*</Grid>
          <Grid container item xs={12} style={{marginTop:"1%"}}>
            <RadioGroup
              row
              id ="is_default"
              aria-label="is_default"
              name="is_default"
              defaultValue={JSON.stringify(questionDetails.is_default)}
              onChange={ (e) => setquestionDetails({...questionDetails, is_default: JSON.parse(e.target.value) })}
            >                  
              <div>
                <FormControlLabel
                  value="true"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "Yes"
                />
              </div>
              <div>
                <FormControlLabel
                  value="false"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "No"
                />
              </div>
            </RadioGroup>
          </Grid>
        </FormControl>
        <div className={classes.validation}>{(questionDetails.is_default == "" && questionDetails.is_default !== false && questionDetails.is_default !== true)?(isDefaultQueErr):(<div></div>)}</div> */}

        <FormControl className={classes.formControlModal}>
          <Grid style={{marginTop:"2%", color:"grey"}}>Is Optional*</Grid>
          <Grid container item xs={12} style={{marginTop:"1%"}}>
            <RadioGroup
              row
              id ="is_optional"
              aria-label="is_optional"
              name="is_optional"
              defaultValue={JSON.stringify(questionDetails.is_optional)}
              onChange={ (e) => setquestionDetails({...questionDetails, is_optional: JSON.parse(e.target.value) })}
            >                  
              <div>
                <FormControlLabel
                  value="true"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "Yes"
                />
              </div>
              <div>
                <FormControlLabel
                  value="false"
                  classes={{ label: classes.label }}
                  control={<Radio size="small" color="primary" />}
                  label= "No"
                />
              </div>
            </RadioGroup>
          </Grid>
        </FormControl>
        <div className={classes.validation}>{(questionDetails.is_optional == "" && questionDetails.is_optional !== false && questionDetails.is_optional !== true)?(isOptionalQueErr):(<div></div>)}</div>


        
        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          {
            ( EditQuestionCheck == true )? 
            (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handelAddMoreQuestion}
              >
                Update
              </Button>
            ) 
            :(
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handelAddMoreQuestion}
              >
                Add
              </Button>
            )
          }
          
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            // color="grey"
            className={classes.submit}
            onClick={handelAddMoreQueReset}
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
  )

  const handelEditQuestion = async(item) => {
    console.log("handelEditQuestion = m=======",item)
    setEditQuestionCheck(true);

    if( item.question_type == "SINGLE-ANSWER" || item.question_type == "MULTIPLE-ANSWER" ){
      setShowMultipleorSingleAns(false);
      item.options.map( async( fo, io )=> {
        console.log("Options check", fo.option)
      //   if( io == 0){
      //     await setoptionOne(fo.option)
      //     await setoptionOneId(fo.option_id) not in use
      //   }
      //   if( io == 1){
      //     await setoptionTwo(fo.option)
      //     await setoptionTwoId(fo.option_id)
      //   }
      //   if( io == 2){
      //     await setoptionThree(fo.option)
      //     await setoptionThreeId(fo.option_id)
      //   }
      //   if( io == 3){
      //     await setoptionFour(fo.option)
      //     await setoptionFourId(fo.option_id)
      //   }
      })
      setShowMultipleorSingleAns(true);
      
    }

    await setquestionDetails({
      service_id: SelectedServicesId,
      question_id: item.question_id,
      title: item.title,
      qtype: item.question_type,
      optionArray: item.options,
      level: item.level,
      is_active: item.is_active,
      is_default: item.is_default,
      is_optional: item.is_optional,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })

    setopenAddMoreQue(true);

  }

  // const handelDeleteQuestion = async(item) => {
  //   console.log("handelDeleteQuestion",item)
  //   await setquestionDetails({
  //     service_id:  JSON.parse(SelectedServicesId),
  //     question_id: item.question_id,
  //     title: item.title,
  //     qtype: item.question_type,
  //     level: item.level,
  //     is_active: item.is_active,
  //     is_default: item.is_default,
  //     device_token: JSON.parse(localStorage.getItem('device_token')),
  //     session_id: JSON.parse(localStorage.getItem('session_id')),
  //   });
  //   console.log("questionDetails = =",questionDetails);

  //   let deleteQuestionDetailsFinal = ({
  //     service_id: JSON.parse(SelectedServicesId),
  //     question_id: item.question_id,
  //     title: item.title,
  //     qtype: item.question_type,
  //     level: item.level,
  //     is_active: false,
  //     is_default: item.is_default,
  //     device_token: JSON.parse(localStorage.getItem('device_token')),
  //     session_id: JSON.parse(localStorage.getItem('session_id')),
  //   });
  //   console.log("deleteQuestionDetailsFinal",deleteQuestionDetailsFinal);
        
  //     const swalWithBootstrapButtons = Swal.mixin({
  //       customClass: {
  //         confirmButton: classes.buttonSwalDelete,
  //         cancelButton: classes.buttonSwalCancel,
  //       },
  //       buttonsStyling: false
  //     })
  //     swalWithBootstrapButtons.fire({
  //       title: 'Are you sure?',
  //       text: "You won't be able to revert this!",
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#d33',
  //       confirmButtonText: 'Yes',
  //       cancelButtonText: 'No, cancel',
  //       reverseButtons: true
  //     }).then((result) => {

  //       if (result.value) {
  //         setShowProcedure(true);
  //         const requestOptions = {
  //           method: 'POST',
  //           headers: authHeader(),
  //           body: JSON.stringify(deleteQuestionDetailsFinal)
  //         };
  //         // return fetch( process.env.REACT_APP_ADD_QUESTION_OPTIONS , requestOptions)
  //         return fetch( process.env.REACT_APP_ADD_QUESTION , requestOptions)
  //           .then((Response) => Response.json())
  //           .then(questionDeleted => {
  //             setShowProcedure(false);
  //             console.log("questionDeleted",questionDeleted.success);
  //             console.log("questionDeleted",questionDeleted);
  //             if(questionDeleted.success == true){
  //               refreshQuestions();
  //               Swal.fire({
  //                 icon: 'success',
  //                 text: "Data Updated Succesfully",
  //                 confirmButtonColor: 'primary',
  //                 confirmButtonText: 'OK',
  //                 timer: 5000,
  //               })
  //               setquestionDetails({
  //                   service_id: SelectedServicesId,
  //                   title: "",
  //                   qtype: "",
  //                   level: "",
  //                   is_active: "",
  //                   is_default: "",
  //                   // document_type_id: "",
  //                   doc_type_id: "",
  //                   doc_type_tag: "",
  //                   device_token: JSON.parse(localStorage.getItem('device_token')),
  //                   session_id: JSON.parse(localStorage.getItem('session_id')),
  //               })
  //             }
  //             if(questionDeleted.success == false ){
  //               Swal.fire({
  //                 icon: 'error',
  //                 text: questionDeleted.errors,
  //                 confirmButtonColor: '#d33',
  //                 confirmButtonText: 'OK',
  //                 // timer: 5000,
  //               })
  //             }         
  //         })
  //         .catch(err => {
  //           setShowProcedure(false);
  //           Swal.fire({
  //             icon: 'error',
  //             text: "Server Error. Please try again.",
  //             confirmButtonColor: '#d33',
  //             confirmButtonText: 'OK'
  //           })
  //         });
                
  //       } else if (
  //         /* Read more about handling dismissals below */
  //         result.dismiss === Swal.DismissReason.cancel
  //       ) 
  //       {
  //         // swalWithBootstrapButtons.fire(
  //         //   'Cancelled',
  //         //   'Your imaginary file is safe :)',
  //         //   'error'
  //         // )
  //       }
  //     })
  // }

  const handelDeleteQuestion = async(item) => {
    console.log("handelDeleteQuestion",item)
    await setquestionDetails({
      service_id:  JSON.parse(SelectedServicesId),
      question_id: item.question_id,
      title: item.title,
      qtype: item.question_type,
      level: item.level,
      // is_active: item.is_active,
      is_active: !item.is_active,
      is_default: item.is_default,
      is_optional: item.is_optional,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    });
    console.log("questionDetails = =",questionDetails);

    let deleteQuestionDetailsFinal = ({
      service_id: JSON.parse(SelectedServicesId),
      question_id: item.question_id,
      title: item.title,
      qtype: item.question_type,
      level: item.level,
      is_active: !item.is_active,
      is_default: item.is_default,
      is_optional: item.is_optional,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    });
    console.log("deleteQuestionDetailsFinal",deleteQuestionDetailsFinal);
      
          setShowProcedure(true);
          const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(deleteQuestionDetailsFinal)
          };
          // return fetch( process.env.REACT_APP_ADD_QUESTION_OPTIONS , requestOptions)
          return fetch( process.env.REACT_APP_ADD_QUESTION , requestOptions)
            .then((Response) => Response.json())
            .then(questionDeleted => {
              setShowProcedure(false);
              setshowAntSwitch(false);
              console.log("questionDeleted",questionDeleted.success);
              console.log("questionDeleted",questionDeleted);
              if(questionDeleted.success == true){
                setshowAntSwitch(true);
                refreshQuestions();
                Swal.fire({
                  icon: 'success',
                  text: "Data Updated Succesfully",
                  confirmButtonColor: 'primary',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
                setquestionDetails({
                    service_id: SelectedServicesId,
                    title: "",
                    qtype: "",
                    level: "",
                    is_active: "",
                    is_default: true,
                    is_optional: "",
                    // document_type_id: "",
                    doc_type_id: "",
                    doc_type_tag: "",
                    device_token: JSON.parse(localStorage.getItem('device_token')),
                    session_id: JSON.parse(localStorage.getItem('session_id')),
                })
              }
              if(questionDeleted.success == false ){
                setshowAntSwitch(true);
                Swal.fire({
                  icon: 'error',
                  text: questionDeleted.errors,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK',
                  // timer: 5000,
                })
              }         
          })
          .catch(err => {
            setShowProcedure(false);
            Swal.fire({
              icon: 'error',
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });
  }

  return (
    // console.log("business_id,SelectedServicesId",business_id,SelectedServicesId),
    // console.log("DocTypeList",DocTypeList),
    // console.log("questionDetails in return",questionDetails),
    console.log("ServiceIsLive in return", ServiceIsLive),
    <div className={classes.root}>
      {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
        <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
      </modal>: null}
      <div className={classes.wrapper}>
          <Grid container className={classes.pageHeaderContainer} style={{marginTop:'1%'}}>
            <Grid item xs={12} className={classes.pageHeaderLabel}>
              Service Explorer
            </Grid>
          </Grid>

          <Paper className={classes.paper} style={{marginTop:'-2%'}}>
          <Grid container item xs={12}>

            {/* to select super category */}
            <Grid item xs={3}> 
              <Grid className={classes.CategoryLabel}>
                Super Category
              </Grid>
              <Grid>
                <FormControl variant="outlined" className={classes.formControl}>
                {/* <InputLabel id="demo-simple-select-filled-label">Category</InputLabel> */}
                <Select
                  native
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={selectedSuperCategory}
                  onChange = {(e) => handleChangeSuprCategoryDropdown(e)}
                >
                  <option value="" style={{color:'grey'}}>Select Category</option>
                    {superCategoryList && superCategoryList.map(item =>
                      <option value={item.category_id}>{item.name}</option>
                      )}
                </Select>
                </FormControl>
              </Grid>
            </Grid>
            
            {
              ( SubCtegoryFetched == true )?
              (
                <Grid item xs={3}> 
                  <Grid className={classes.CategoryLabel}>
                    Category or Service
                  </Grid>  
                  <Grid>
                    <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      native
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={selectedSubCategory}
                      onChange = {(e) => handleChangeCategoryOrServiceDropdown(e)}
                    >
                      <option value="" style={{color:'grey'}}>Select Category or Service</option>
                        {subCategoryList && subCategoryList.map(item =>
                          <option value={item.category_id}>{item.name}</option>
                          )}
                    </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )
              :(<div></div>)
            }

            {
              (CategorySelected == true)? 
              (
                <Grid item xs={3}> 
                  <Grid className={classes.CategoryLabel}>
                    Service
                  </Grid>  
                  <Grid>
                    <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      native
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={selectedServiceFromCategory}
                      onChange = {(e) => handleChangeServiceDropdown(e)}
                    >
                      <option value="" style={{color:'grey'}}>Select Service</option>
                        {subCategoryListForSelectedCategory && subCategoryListForSelectedCategory.map(item =>
                          <option value={item.category_id}>{item.name}</option>
                          )}
                    </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )
              :(<div></div>)
            }

          </Grid>
          </Paper>
          {/* ServiceSelected */}
           {/* show steps */}
           {
            (ServiceSelected == true)?
            (
              <Grid>
                <Grid container item xs={12} style={{marginTop:'2%'}}>
                  <Grid item xs={6}>
                    <Grid>
                      <Paper className={classes.paperStepsHeading}>
                        {/* <Grid className={classes.paperStepsHeadingLabelStyle}>Questions</Grid> */}
                        <Grid container item xs={12}>
                          <Grid item xs={10} className={classes.paperStepsHeadingLabelStyle} style={{marginTop:'1%'}}>Questions</Grid>
                          
                          {user_permissions && user_permissions.permissions.create_questions &&
                            <Grid>
                              {
                                (ServiceIsLive === false )?
                                (
                                  <Grid item xs={2}>
                                    <Button
                                      type="submit"
                                      fullWidth
                                      variant="contained"
                                      color="primary"
                                      className={classes.submit}
                                      onClick={AddMoreQuestionsOpen}
                                    >
                                      Add
                                    </Button>                          
                                  </Grid>
                                ):
                                (
                                  <Grid></Grid>
                                )
                              }
                            </Grid>
                          }

                        </Grid>
                      </Paper>
                    </Grid>
                    <Paper className={classes.paperSteps}>
                      <Grid className={classes.questionContainer} style={{marginTop:'-5%'}}>

                        {/* <Grid className={classes.questionsTypeHeadingStyle} >Text Answer Questions</Grid> */}
                        <Grid className={classes.questionsTypeHeadingStyle} >Description Answer Questions</Grid>
                        {
                          QuestionList.filter(item => item.question_type == "TEXT-ANSWER").map((item,index)=>(
                            // <Grid className={classes.questionsTitleStyle}>{index + 1}. {item.title}</Grid>
                            <Grid container item xs={12} style={{height:'10%'}}>
                              <Grid item xs={10} className={classes.questionsTitleStyle}>{index + 1}. {item.title}</Grid>

                              {user_permissions && user_permissions.permissions.create_questions &&
                                <Grid>
                                  {
                                    (ServiceIsLive === false )?
                                    (    
                                      <Grid item xs={1} style={{marginTop:'2%'}} className={classes.moreVertIcon}>
                                        <EditIcon
                                          onClick={() => handelEditQuestion(item)}
                                        />
                                      </Grid>
                                    ):
                                    (
                                      <Grid></Grid>
                                    )
                                  }
                                </Grid>
                              }

                              {user_permissions && user_permissions.permissions.create_questions &&
                                <Grid item xs={1} style={{marginTop:'2%'}} className={classes.moreVertIcon}>
                                  {/* <DeleteIcon
                                    onClick={() => handelDeleteQuestion(item)}
                                  /> */}
                                  {
                                    (ServiceIsLive === false )?
                                    (    
                                      <Grid>
                                        {
                                          ( showAntSwitch == true)? 
                                          (
                                            <AntSwitch 
                                              checked={item.is_active} 
                                              onChange={() => handelDeleteQuestion(item)}
                                              size="small" 
                                              name="item.is_active" 
                                              value="item.is_active"
                                              color="primary"
                                            />
                                          )
                                          :(<Grid></Grid>)
                                        }
                                      </Grid>
                                    ):
                                    (
                                      <Grid></Grid>
                                    )
                                  }

                                </Grid>
                              }

                            </Grid>
                          ))
                        }
                        <hr/>
                        <Grid className={classes.questionsTypeHeadingStyle} >Single Answer Questions</Grid>
                        {
                          QuestionList.filter(item => item.question_type == "SINGLE-ANSWER").map((item,index)=>(
                            // console.log("SINGLE-ANSWER item",item),
                            <Grid>
                              {/* <Grid className={classes.questionsTitleStyle}>{index + 1}. {item.title}</Grid> */}
                              <Grid container item xs={12} style={{height:'10%'}}>
                                <Grid item xs={10} className={classes.questionsTitleStyle}>{index + 1}. {item.title}</Grid>
                                
                                {user_permissions && user_permissions.permissions.create_questions &&
                                  <Grid>
                                    {
                                      (ServiceIsLive === false )?
                                      ( 
                                        <Grid item xs={1} style={{marginTop:'2%'}} className={classes.moreVertIcon}>
                                          <EditIcon
                                            onClick={() => handelEditQuestion(item)}
                                          />
                                        </Grid>
                                      ):
                                      (
                                        <Grid></Grid>
                                      )
                                    }
                                  </Grid>
                                }

                                {user_permissions && user_permissions.permissions.create_questions &&
                                  <Grid item xs={1} style={{marginTop:'2%'}} className={classes.moreVertIcon}>
                                    {/* <DeleteIcon
                                      onClick={() => handelDeleteQuestion(item)}
                                    /> */}

                                    {
                                      (ServiceIsLive === false )?
                                      ( 
                                        <Grid>
                                          {
                                            ( showAntSwitch == true)? 
                                            (
                                              <AntSwitch 
                                                checked={item.is_active} 
                                                onChange={() => handelDeleteQuestion(item)}
                                                size="small" 
                                                name="item.is_active" 
                                                value="item.is_active"
                                                color="primary"
                                              />
                                            )
                                            :(<Grid></Grid>)
                                          }
                                        </Grid>
                                      ):
                                      (
                                        <Grid></Grid>
                                      )
                                    }

                                  </Grid>
                                }
                              </Grid>
                              {
                                item.options.map((oi, oindex)=>(
                                  <Grid className={classes.questionsOptionStyle}>{oindex + 1}. {oi.option}</Grid>
                                ))
                              }
                            </Grid>
                          ))
                        }
                        <hr/>
                        <Grid className={classes.questionsTypeHeadingStyle} >Yes / No Type Questions</Grid>
                        {
                          QuestionList.filter(item => item.question_type == "BOOLEAN").map((item,index)=>(
                            // <Grid className={classes.questionsTitleStyle}>{index + 1}. {item.title}</Grid>
                            <Grid container item xs={12} style={{height:'10%'}}>
                                <Grid item xs={10} className={classes.questionsTitleStyle}>{index + 1}. {item.title}</Grid>
                                
                                {user_permissions && user_permissions.permissions.create_questions &&
                                  <Grid>
                                  {
                                    (ServiceIsLive === false )?
                                    ( 
                                      <Grid item xs={1} style={{marginTop:'2%'}} className={classes.moreVertIcon}>
                                        <EditIcon
                                          onClick={() => handelEditQuestion(item)}
                                        />
                                      </Grid>
                                    ):
                                    (
                                      <Grid></Grid>
                                    )
                                  }
                                  </Grid>
                                }

                                {user_permissions && user_permissions.permissions.create_questions &&
                                  <Grid item xs={1} style={{marginTop:'2%'}} className={classes.moreVertIcon}>
                                    {/* <DeleteIcon
                                      onClick={() => handelDeleteQuestion(item)}
                                    /> */}
                                    {
                                      (ServiceIsLive === false )?
                                      ( 
                                        <Grid>
                                          {
                                            ( showAntSwitch == true)? 
                                            (
                                              <AntSwitch 
                                                checked={item.is_active} 
                                                onChange={() => handelDeleteQuestion(item)}
                                                size="small" 
                                                name="item.is_active" 
                                                value="item.is_active"
                                                color="primary"
                                              />
                                            )
                                            :(<Grid></Grid>)
                                          }
                                        </Grid>
                                      ):
                                      (
                                        <Grid></Grid>
                                      )
                                    }
                                  </Grid>
                                }
                            </Grid>
                          ))
                        }
                        <hr/>
                        <Grid className={classes.questionsTypeHeadingStyle} >Multiple Answers Questions</Grid>
                        {
                          QuestionList.filter(item => item.question_type == "MULTIPLE-ANSWER").map((item,index)=>(
                            // console.log("MULTIPLE-ANSWER item",item),
                            <Grid>
                              {/* <Grid className={classes.questionsTitleStyle}>{index + 1}. {item.title}</Grid> */}
                              <Grid container item xs={12} style={{height:'10%'}}>
                                <Grid item xs={10} className={classes.questionsTitleStyle}>{index + 1}. {item.title}</Grid>
                                {user_permissions && user_permissions.permissions.create_questions &&
                                  <Grid>
                                  {
                                    (ServiceIsLive === false )?
                                    ( 
                                      <Grid item xs={1} style={{marginTop:'2%'}} className={classes.moreVertIcon}>
                                        <EditIcon
                                          onClick={() => handelEditQuestion(item)}
                                        />
                                      </Grid>
                                    ):
                                    (
                                      <Grid></Grid>
                                    )
                                  }
                                  </Grid>                                
                                }

                                {user_permissions && user_permissions.permissions.create_questions &&
                                  <Grid item xs={1} style={{marginTop:'2%'}} className={classes.moreVertIcon}>
                                    {/* <DeleteIcon
                                      onClick={() => handelDeleteQuestion(item)}
                                    /> */}
                                    {
                                      (ServiceIsLive === false )?
                                      ( 
                                        <Grid>
                                        {
                                          ( showAntSwitch == true)? 
                                          (
                                            <AntSwitch 
                                              checked={item.is_active} 
                                              onChange={() => handelDeleteQuestion(item)}
                                              size="small" 
                                              name="item.is_active" 
                                              value="item.is_active"
                                              color="primary"
                                            />
                                          )
                                          :(<Grid></Grid>)
                                        }
                                        </Grid>
                                      ):
                                      (
                                        <Grid></Grid>
                                      )
                                    }
                                  </Grid>
                                }
                              </Grid>
                              {
                                item.options.map((oi, oindex)=>(
                                  <Grid className={classes.questionsOptionStyle}>{oindex + 1}. {oi.option}</Grid>
                                ))
                              }
                            </Grid>
                          ))
                        }
                      </Grid>
                    </Paper>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid>
                      <Paper className={classes.paperStepsHeading}>
                        <Grid container item xs={12}>
                          <Grid item xs={10} className={classes.paperStepsHeadingLabelStyle} style={{marginTop:'1%'}}>Document Types</Grid>
                          
                          {user_permissions && user_permissions.permissions.create_document_types &&
                            <Grid item xs={2}>
                              {
                                (ServiceIsLive === false )?
                                ( 
                                  <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={AddMoreDocumentOpen}
                                  >
                                    Add
                                  </Button>
                                ):
                                (
                                  <Grid></Grid>
                                )
                              }
                            </Grid>
                          }

                        </Grid>
                      </Paper>
                    </Grid>
                    <Paper className={classes.paperSteps}>
                      <Grid className={classes.questionContainer} style={{marginTop:'-5%'}}>
                        {/* DocTypeList */}
                        <Grid className={classes.questionsTypeHeadingStyle} >Personal Documents</Grid>
                        {
                          DocTypeList.filter(item => item.doc_type_tag == "PERSONAL").map((item,index)=>(
                            <Grid container item xs={12} style={{height:'10%'}}>
                              <Grid item xs={10} className={classes.questionsTitleStyle}>{index + 1}. {item.name}</Grid>
                              
                              {user_permissions && user_permissions.permissions.create_document_types &&
                                <Grid>
                                {
                                  (ServiceIsLive === false )?
                                  ( 
                                    <Grid item xs={1} style={{marginTop:'2%'}} className={classes.moreVertIcon}>
                                      <EditIcon
                                        onClick={() => handelEditDocType(item)}
                                      />
                                    </Grid>
                                  ):
                                  (
                                    <Grid></Grid>
                                  )
                                }
                                </Grid>
                              }

                              {user_permissions && user_permissions.permissions.create_document_types &&
                                <Grid item xs={1} style={{marginTop:'2%'}} className={classes.moreVertIcon}>
                                  {/* <DeleteIcon
                                    onClick={() => handelDeleteDocType(item)}
                                  /> */}
                                  {
                                    (ServiceIsLive === false )?
                                    ( 
                                      <Grid>
                                      {
                                        ( showAntSwitch == true)? 
                                        (
                                          <AntSwitch 
                                            checked={item.is_active} 
                                            onChange={() => handelDeleteDocType(item)}  
                                            // onChange={(e) => setExistingProcessDetails({...ExistingProcessDetails, is_current: !ExistingProcessDetails.is_current }) }
                                            size="small" 
                                            name="item.is_active" 
                                            value="item.is_active"
                                            color="primary"
                                          />
                                        )
                                        :(<Grid></Grid>)
                                      }
                                      </Grid>
                                    ):
                                    (
                                      <Grid></Grid>
                                    )
                                  }
                                </Grid>
                              }
                            </Grid>
                          ))
                        }
                        <hr/>
                        <Grid className={classes.questionsTypeHeadingStyle} >Business Documents</Grid>
                        {
                          DocTypeList.filter(item => item.doc_type_tag == "BUSINESS").map((item,index)=>(
                            <Grid container item xs={12} style={{height:'10%'}}>
                              <Grid item xs={10} className={classes.questionsTitleStyle}>{index + 1}. {item.name}</Grid>
                              
                              {user_permissions && user_permissions.permissions.create_document_types &&
                                <Grid>
                                {
                                  (ServiceIsLive === false )?
                                  ( 
                                    <Grid item xs={1} style={{marginTop:'2%'}} className={classes.moreVertIcon}>
                                      <EditIcon
                                        onClick={() => handelEditDocType(item)}
                                      />
                                    </Grid>
                                  ):
                                  (
                                    <Grid></Grid>
                                  )
                                }
                                </Grid>
                              }

                              {user_permissions && user_permissions.permissions.create_document_types &&
                                <Grid item xs={1} style={{marginTop:'2%'}} className={classes.moreVertIcon}>
                                  {/* <DeleteIcon
                                    onClick={() => handelDeleteDocType(item)}
                                  /> */}
                                  {
                                    (ServiceIsLive === false )?
                                    ( 
                                      <Grid>
                                      {
                                        ( showAntSwitch == true)? 
                                        (
                                          <AntSwitch 
                                            checked={item.is_active} 
                                            onChange={() => handelDeleteDocType(item)}  
                                            // onChange={(e) => setExistingProcessDetails({...ExistingProcessDetails, is_current: !ExistingProcessDetails.is_current }) }
                                            size="small" 
                                            name="item.is_active" 
                                            value="item.is_active"
                                            color="primary"
                                          />
                                        )
                                        :(<Grid></Grid>)
                                      }
                                      </Grid>
                                    ):
                                    (
                                      <Grid></Grid>
                                    )
                                  }
                                </Grid>
                              }
                            </Grid>
                          ))
                        }
                      </Grid>
                    </Paper>

                  </Grid>


                </Grid>
              </Grid>
            )
            :(<Grid></Grid>)
            }
          

      </div>
        <Modal
          open={openAddMoreDoc}
          onClose={handelAddMoreDocClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyAddMoreDoc}
        </Modal>

        <Modal
          open={openAddMoreQue}
          onClose={handelAddMoreQueClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyAddMoreQuestions}
        </Modal>
    </div>
  );
}

export default injectIntl(TaxManagementServiceExplorer);
