import React, { useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import { injectIntl, FormattedMessage } from "react-intl";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { Link, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import Modal from '@material-ui/core/Modal';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { userActions } from "../actions";
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import HomeIcon from '@material-ui/icons/Home';
import WorkIcon from "@material-ui/icons/Work";
import GroupIcon from "@material-ui/icons/Group";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import TableSortLabel from '@material-ui/core/TableSortLabel';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(ClientAddressDetails, comparator) {
  const stabilizedThis = ClientAddressDetails && ClientAddressDetails.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  // { id: 'group_id', numeric: false, label: 'Group Id' ,},
  { id: 'document_id', numeric: false, label: 'Sr. No', },
  { id: 'address', numeric: false, label: 'Address', },
  { id: 'address_type', numeric: false, label: 'Address Type', },
  { id: 'actions', numeric: false, label: 'Actions', },
];
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          (headCell.id === "actions") ?
            (
              <TableCell
                key={headCell.id}
                align="center"
                style={{ fontWeight: 'bold', fontSize: 17, width: "1%" }}
              >
                {headCell.label}
              </TableCell>
            )
            : (
              <TableCell
                key={headCell.id}
                // align={headCell.numeric ? 'center' : 'center'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                align="center"
                style={{ fontWeight: 'bold', fontSize: 17, width: "10%" }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    lexGrow: 1,
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    height: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block',
    overflowY:'scroll',
  },
  paperModalAddAddress: {
    position: 'absolute',
    width: 500,
    // height: 810,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'1%',
    display:'block'
  },
  modalBodyAddAddress: {
    marginTop: '-2%',
    padding: theme.spacing(2, 4, 3),  
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },

}));

export default function ClientAddresses() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [flag, setflag] = React.useState(false);
  const [ShowProcedure, setShowProcedure] = React.useState(false);

  const [ClientAddressDetails, setClientAddressDetails] = React.useState([]);

  const [OpenEditAddress, setOpenEditAddress] = React.useState(false);
  const [editAddressDetails,seteditAddressDetails] = React.useState({
    address_id: "",
    full_address: "",
    address_type: "",
    is_active: true,
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')), 
    client_user_id: "",
    updated_by: "business",
  });
  const [editAddressError, seteditAddressError] = React.useState();
  const [editAddressTypeError, seteditAddressTypeError] = React.useState();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');

  // sort
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, ClientAddressDetails && ClientAddressDetails.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  useEffect(() => {
    
    setflag(true);
    let selected_Client_Showin_Ui = JSON.parse(localStorage.getItem('selected_Client_Showin_Ui'));
    let selected_Client_Showin_Ui_id = selected_Client_Showin_Ui.client_id;
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'client_id': selected_Client_Showin_Ui_id,
        'device_token': device_token, 'session_id': session_id
      })
    };
    fetch(process.env.REACT_APP_GET_CLIENT_DETAILS, requestOptions)
      .then((Response) => Response.json())
      .then(async (ClientAddressDetails) => {
        setShowProcedure(false);
        console.log("ClientAddressDetails from api", ClientAddressDetails.data.client_details.addresses.addresses,
          ClientAddressDetails.data.client_details.user_id);
        if (ClientAddressDetails.success == true) {
          setClientAddressDetails(ClientAddressDetails.data.client_details.addresses.addresses);

          seteditAddressDetails({ ...editAddressDetails, client_user_id: ClientAddressDetails.data.client_details.user_id })
        }
        else if (ClientAddressDetails.success == false) {
          setClientAddressDetails([]);
          Swal.fire({
            icon: 'error',
            text: ClientAddressDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 9000,
          })
        }
      })
    // .catch(err => {
    //   setShowProcedure(false);
    //   Swal.fire({
    //     icon: 'error',
    //     text: "Server Error. Please try again.",
    //     confirmButtonColor: '#d33',
    //     confirmButtonText: 'OK'
    //   })
    // });

  }, [flag]);

  const handleEditAddress = (item) => {
    console.log("edit address is clieck", item);
    setOpenEditAddress(true);
    seteditAddressDetails(
      { ...editAddressDetails, 
        address_id: item.address_id,
        full_address: item.address,
        address_type: item.address_type,
      }
    )
  }

  const  handleCloseAddress = () => {
    setOpenEditAddress(false);

    seteditAddressDetails(
      { ...editAddressDetails, 
        address_id: '',
        full_address: '',
        address_type: '',
      }
    )

    seteditAddressError();
    seteditAddressTypeError();
  }

  const validateEditAddress = () => {
    let editAddressError = '';
    let editAddressTypeError = '';

    if(!editAddressDetails.full_address)  {
      editAddressError = 'Please Enter Address';
    }

    if(!editAddressDetails.address_type)  {
      editAddressTypeError = 'Please Select Address Type';
    }

    // if( NewAddressActive == "" &&  NewAddressActive !== false  && NewAddressActive !== true)  {
    //   newAddressActiveErr = 'Please Select Is Active';
    // }
    

    if(editAddressError || editAddressTypeError ){
      seteditAddressError(editAddressError);
      seteditAddressTypeError(editAddressTypeError);
      // setnewAddressActiveErr(newAddressActiveErr);
      
      return false;
    }

    return true;
  }

  const handleEditAddressSubmit = () => {
    console.log("handleEditAddressSubmit",editAddressDetails);

    const isValid = validateEditAddress();
    if(isValid){

      setOpenEditAddress(false);
      setShowProcedure(true);

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(editAddressDetails)
      };
      fetch( process.env.REACT_APP_CREATE_ADDRESS , requestOptions)
        .then((Response) => Response.json())
        .then(addressEdited => {
          setShowProcedure(false)
          console.log("addressEdited",addressEdited);
          console.log("addressEdited",addressEdited.success);

          if(addressEdited.success == true){
            Swal.fire({
              icon: 'success',
              text: "Address Edited Succesfully",
              confirmButtonColor: 'primary',
              confirmButtonText: 'OK',
              timer: 5000,
            })
            setShowProcedure(false);
            
            seteditAddressDetails(
              { ...editAddressDetails, 
                address_id: '',
                full_address: '',
                address_type: '',
              }
            )

            seteditAddressError();
            seteditAddressTypeError();

          }
          if(addressEdited.success == false ){
            setOpenEditAddress(true);
            Swal.fire({
              icon: 'error',
              text: addressEdited.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              // timer: 5000,
            })
          } 
        setflag(false);

        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

    }
  }

  const bodyEditAddress  = (
    <div className={classes.paperModalAddAddress}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <Grid container item xs={12}>
          <Grid item xs={10} >
            <h2 style={{marginLeft:'43%'}}>Edit Address</h2>
          </Grid>
          {/* <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
            <MinimizeIcon onClick={()=>handelAddAddressMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
          <Grid item xs={1} style={{marginTop:'1%',}}>
            <CloseIcon onClick={()=>handleAddAddressClose()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid> */}
        </Grid> 
      </div>

      <div className={classes.modalBodyAddAddress}>

        <Grid item xs={12}>
          <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Full Address"
              name="address"
              autoComplete="address"
              autoFocus
              value={editAddressDetails.full_address}
              onChange={(e) => seteditAddressDetails({...editAddressDetails, full_address: e.target.value })}
          />
          <div className={classes.validation}>{(editAddressDetails.full_address) ? (<div></div>) : (editAddressError)}</div>

          <FormControl className={classes.formControl}>
              <InputLabel id="address_type">Address Type*</InputLabel>
              <Select
                labelId="address_type"
                id="address_type"
                value={editAddressDetails.address_type}
                onChange={(e) => seteditAddressDetails({...editAddressDetails, address_type: e.target.value })}
              >
                {/* {yesNo.map(item => */}
                  <MenuItem value="home">Home</MenuItem>
                  <MenuItem value="work">Work</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                {/* )} */}
              </Select>
            </FormControl>
            <div className={classes.validation}>{(editAddressDetails.address_type)?(<div></div>):(editAddressTypeError)}</div>

        </Grid>
        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end', marginTop:'10%'}}>
          <Button
            type="submit"
            //   fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleEditAddressSubmit}
            >
              Update
            </Button>
            <div style={{marginLeft:'2%'}}></div>
            <Button
              type="submit"
              variant="contained"
              // color="grey"
              className={classes.submit}
              onClick={handleCloseAddress}
            >
              Cancel
            </Button>
        </div>

      </div>

      </div>


)


  return (
    console.log("ClientAddress in new page", ClientAddressDetails, editAddressDetails),
    <div className={classes.root}>
      <Grid>
        <TableContainer style={{ width: '95%' }} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <EnhancedTableHead
              // classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={ClientAddressDetails && ClientAddressDetails.length}
            />
            <TableBody>
              {stableSort(ClientAddressDetails && ClientAddressDetails, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  // console.log("row in table map",item)
                  return (
                    <TableRow
                      key={item.state_id}
                      hover={true} classes={{ hover: classes.hover }}
                      // hover
                      tabIndex={-1}
                      align="center"
                    >
                      <TableCell width="10%" align="center">{index + 1}</TableCell>
                      <TableCell width="10%" align="center">{item.address}</TableCell>
                      <TableCell width="10%" align="center">{item.address_type}</TableCell>
                      <TableCell width="10%" align="center">
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={() => handleEditAddress(item)}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}

            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: ClientAddressDetails && ClientAddressDetails.length }]}
                  colSpan={8}
                  count={ClientAddressDetails && ClientAddressDetails.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
      <Modal
          open={OpenEditAddress}
          onClose={handleCloseAddress}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyEditAddress}
        </Modal>
    </div>
  );
}
