// UserRoleBased
import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../actions";
import useCheckSessionApi from "../SessionValidity";
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import Button from "@material-ui/core/Button";
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import AddIcon from "@material-ui/icons/Add";
import MinimizeIcon from '@material-ui/icons/Minimize';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TableSortLabel from '@material-ui/core/TableSortLabel';

moment.defaultFormat = "DD/MM/YYYY HH:mm"


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(usersList, comparator) {
  const stabilizedThis = usersList && usersList.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, label: 'Name', },
  { id: 'role', numeric: false, label: 'Role', },
  { id: 'email', numeric: false, label: 'EmailID' ,},
  { id: 'username', numeric: false, label: 'User Name' , },
  { id: 'actions', numeric: false, label: 'Actions', },
];
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
            ( headCell.id === "actions")?
            (
              <TableCell
                key={headCell.id}
                align="center"
                style={{fontWeight: 'bold', fontSize: 17,  width: "1%"}}
              >
                {headCell.label}
              </TableCell>
            )
            :(
              <TableCell
                key={headCell.id}
                // align={headCell.numeric ? 'center' : 'center'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                align="center"
                style={{fontWeight: 'bold', fontSize: 17,  width: "10%"}}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
           )
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
//   intl: PropTypes.shape.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridContianer: {
    padding: theme.spacing(2),
  },
  leftAlign: {
    color: 'rgba(0, 0, 0, 0.32)',
    textAlign: 'left'
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  userLabelStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    padding: 2,
    lineHeight: "24px",
    marginTop: "1%",
    marginBottom: "3%",
    textTransform: "none",
    color: "black",
    fontSize: "16px",
    fontWeight: 500,
    cursor:'pointer',
    textAlign:"left",
    hover: true,
    "&:hover": {
      color: "#4D47DD",
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paperModal: {
    // position: 'absolute',
    // width: 500,
    // // height: 620,
    // backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    // // top:'2%',
    // display:'block'
    position: 'absolute',
    width: 500,
    height: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block',
    overflowY:'scroll',
  },
  paperModalAddUser: {
    position: 'absolute',
    width: 500,
    // height: 810,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    // backgroundColor: '#23c1f7',
    height:'12%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
    width:'100%',
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '2%',
    width: '100%',
  },

}));

export default function UserRoleBased() {
  const classes = useStyles();
  const [ ShowProcedure, setShowProcedure ] = React.useState(false);
  const [flag, setflag] = React.useState(false);
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id , setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('selected_business_id')));
  const [usersList, setusersList] = React.useState([]);
  const [UserSelectedForPermission, setUserSelectedForPermission] = React.useState();
  const [UserPermissionDetailsList, setUserPermissionDetailsList] = React.useState([]);
  const [openSetUserPermissions, setopenSetUserPermissions] = React.useState(false);
  const dispatch = useDispatch();

  // add new user
  const [AddNewUserOpen, setAddNewUserOpen] = React.useState(false);
  const [LogedInBusinessShortCodeIs, setLogedInBusinessShortCodeIs] = React.useState();
  const [userDetails, setuserDetails] = React.useState({
    name: "",
    username: "",
    email: "",
    password: "",
    role: "",
    is_active: "",
    business_shortcode: "",
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  });
  
  const [nameErr, setnameErr] = React.useState();
  const [userNameErr, setuserNameErr] = React.useState();
  const [emailErr, setemailErr] = React.useState();
  const [passwordErr, setpasswordErr] = React.useState();
  const [roleErr, setroleErr] = React.useState();
  const [activeErr, setactiveErr] = React.useState();

  const [userListForNow, setuserListForNow] = React.useState([
    {
      "name": "test maste admin ",
      "email": "testma@gmail.com",
      "username": "test-ma",
      "role": "trpadmin",
      "is_active": true,
      "user_id": 9
    },
    {
      "name": "test maste admin ",
      "email": "testma@gmail.com",
      "username": "test-ma",
      "role": "trpadmin",
      "is_active": true,
      "user_id": 9
    },
    {
      "name": "test maste admin ",
      "email": "testma@gmail.com",
      "username": "test-ma",
      "role": "trpadmin",
      "is_active": true,
      "user_id": 9
    },
  ])
  const [UserPermissions, setUserPermissions]  = React.useState([
    {
      "action_id": 1,
      "action_name": "Action One",
      "has_permission": true,
    },
    {
      "action_id": 2,
      "action_name": "Action Two",
      "has_permission": true,
    },
    {
      "action_id": 3,
      "action_name": "Action Three",
      "has_permission": true,
    },
    {
      "action_id": 4,
      "action_name": "Action Four",
      "has_permission": true,
    }
  ]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(false);
    // sort
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // pagination
    const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, usersList && usersList.length - page * rowsPerPage);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

  React.useEffect(() => {
    let users = JSON.parse(localStorage.getItem('users'));
    if(users.data.user_details.business){
      console.log("users in role based access",users)
      let business_shortcode = users.data.user_details.business.business_shortcode
      console.log("users in role based access",business_shortcode)
      setLogedInBusinessShortCodeIs(business_shortcode)
      setuserDetails({ ...userDetails, business_shortcode: business_shortcode})
    }
    
    setShowProcedure(true);
    setflag(true);
    dispatch(userActions.checkSessionValidity());
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id,'device_token': device_token, 
        'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_BUSINESS_TEAM , requestOptions)
      .then((Response) => Response.json())
      .then(allUsersDetails => {
        setShowProcedure(false);
        console.log("allUsersDetails on user roled based access",allUsersDetails.data.business_team);
        if(allUsersDetails.data.business_team)
        {
          setusersList(allUsersDetails.data.business_team)
        }
        if(allUsersDetails.success == false){
          setusersList([])
          Swal.fire({
            icon: 'error',
            text: allUsersDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }, [flag]);

  const handleUserNameClick = (item) => {
    console.log("user name clicked", item)
    setUserSelectedForPermission(item.user_id)
    const requestOptionUserPermission = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'user_id': item.user_id,
        'device_token': device_token, 'session_id': session_id })
    };
    // fetch( process.env.REACT_APP_GET_BUSINESS_TEAM , requestOptionsUsers)
    fetch( process.env.REACT_APP_GET_USERS_PERMISSION , requestOptionUserPermission)
      .then((Response) => Response.json())
      .then( async(UserPermissionDetails) => {
        console.log("UserPermissionDetails======",UserPermissionDetails);
        if(UserPermissionDetails.success == true)
        {
          setUserPermissionDetailsList(UserPermissionDetails.data.user_permissions);
        }
        else if(UserPermissionDetails.success == false){
          setUserPermissionDetailsList([]);
          Swal.fire({
            icon: 'error',
            text: UserPermissionDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    handleSetUserPermissionsOpen()
  }

  // User Permission modal
  const handleSetUserPermissionsOpen = () => {
    setopenSetUserPermissions(true)
  }

  const handelSetUserPermissionsClose = () => {
    setopenSetUserPermissions(false)
  }

  const handleUserPermissionCheckboxClick = (item) => {
    console.log("user pesrmission set clicked",item)

    let userSetPermissionObj = { 
      // "user_permission_id": 2,
      "action_id": item.action_id,
      "user_id": UserSelectedForPermission,
      "has_permission": !item.has_permission,
      "device_token": device_token, 
      "session_id": session_id,
    }

    setUserPermissions(
      UserPermissions.map(( itemHere , index ) =>{
        if(item.action_id == itemHere.action_id){
          itemHere.has_permission = !itemHere.has_permission
        }
        return itemHere
      })
    )
    console.log("userSetPermissionObj, UserPermissions",userSetPermissionObj, UserPermissions)

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(userSetPermissionObj)
    };
    fetch( process.env.REACT_APP_CREATE_USER_PERMISSION , requestOptions)
      .then((Response) => Response.json())
      .then( async(PermissionChangeForUser) => {
        console.log("PermissionChangeForUser",PermissionChangeForUser)
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  const bodySetUserPermissions = (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <Grid container item xs={12}>
          <Grid item xs={11} >
            <h2 style={{marginLeft:'34%'}}> User Permissions</h2>
          </Grid>
          {/* <Grid item xs={1} style={{marginTop:'4%',}}>
            <CloseIcon onClick={()=>handelSetUserPermissionsClose()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid> */}
        </Grid> 
      </div>
      <div className={classes.modalBody} style={{position:'absolute'}}>
        <Grid>
          {/* to set permissions */}
          <Grid item xs={12}>
            <ul style={{listStyle: "none"}}>
            {
              UserPermissions.map(( item , index ) =>
                <li key={item.action_id}>
                  <Grid container style={{ fontSize: '15px', marginTop:'2%'}} >
                      <Grid  >
                        <input
                          type="checkbox"
                          style={{zoom: 1.5}}
                          size='10px'
                          checked={item.has_permission}
                          // onClick={ () => handleUserPermissionCheckboxClick(item)}
                        />
                      </Grid>
                      <Grid style={{marginLeft:'2%', marginTop:'1%'}} >
                        {item.action_name}
                      </Grid>
                  </Grid>
                </li>
              )
            }
            </ul>
          </Grid>
        </Grid>
        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            // color="grey"
            className={classes.submit}
            onClick={handelSetUserPermissionsClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
  
  // Add new user functions
  const handelAddNewUserOpen = () => {
    setAddNewUserOpen(true)
  }

  const handelAddNewUserClose = () => {
    setAddNewUserOpen(false)
    setuserDetails({
      name: "",
      username: "",
      email: "",
      password: "",
      role: "",
      is_active: "",
      // business_shortcode: "",
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    setnameErr();
    setuserNameErr();
    setemailErr();
    setpasswordErr();
    setroleErr();
    setactiveErr();
  }

  const handelAddNewUserMinimize = () => {
    setAddNewUserOpen(false)
  }

  const validate = () => {
    let nameErr = '';
    let userNameErr = '';
    let emailErr = '';
    let passwordErr = '';
    let roleErr = '';
    let activeErr = '';
    // let shortCodeErr = '';

    if(!userDetails.name)  {
        nameErr = 'Please Enter Name';
    }
    if(!userDetails.username)  {
        userNameErr = 'Please Enter User Name';
    }
    if(!userDetails.email)  {
        emailErr = 'Please Enter Email';
    }
      else if(typeof userDetails.email !== "undefined"){
          let lastAtPos = userDetails.email.lastIndexOf('@');
          let lastDotPos = userDetails.email.lastIndexOf('.');
          if (!(lastAtPos < lastDotPos && lastAtPos > 0 )) {
              emailErr = "Email is not valid";
              if(emailErr) {
                setemailErr(emailErr);
              }
          }
        else {
            setemailErr();    
          }
      }
    else{
          setemailErr();
    }


    if(!userDetails.password)  {
        passwordErr = 'Please Enter Password';
    }
    else if(userDetails.password)  {
      if(userDetails.password.length < 8){
        passwordErr = 'Password must contain atleast 8 characters';
      }
    }

    if(!userDetails.role)  {
        roleErr = 'Please Enter Role';
    }

    if(userDetails.is_active == "" && userDetails.is_active !== false && userDetails.is_active !== true )  {
        activeErr = 'Please Select is Active';
    }
    // if(!userDetails.business_shortcode)  {
    //     shortCodeErr = 'Please Enter Business Shortcode';
    // }

    if(nameErr || userNameErr || emailErr || passwordErr || roleErr || activeErr ){
      setnameErr(nameErr);
      setuserNameErr(userNameErr);
      setemailErr(emailErr);
      setpasswordErr(passwordErr);
      setroleErr(roleErr);
      setactiveErr(activeErr);
      // setshortCodeErr(shortCodeErr);
      return false;
    }

    return true;
  }

  const handelSubmitAddNewUser = () => {
    console.log("handle add new user is clicked",userDetails)
    dispatch(userActions.checkSessionValidity());
      const isValid = validate();
      if(isValid){
        setShowProcedure(true);
        setAddNewUserOpen(false);
        const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(userDetails)
        };
        return fetch( process.env.REACT_APP_CREATE_USER , requestOptions)
            .then((Response) => Response.json())
            .then(userAdded => {
                setShowProcedure(false);
                console.log("userAdded on same page",userAdded.success);
                  console.log("userAdded on same page",userAdded);
                  if(userAdded.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "User Added Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })  
                    setuserDetails({
                      name: "",
                      username: "",
                      email: "",
                      password: "",
                      role: "",
                      is_active: "",
                      // business_shortcode: "",
                      device_token: JSON.parse(localStorage.getItem('device_token')),
                      session_id: JSON.parse(localStorage.getItem('session_id')),
                    })
                    setnameErr();
                    setuserNameErr();
                    setemailErr();
                    setpasswordErr();
                    setroleErr();
                    setactiveErr();
                    // setshortCodeErr();
                  }
                  if(userAdded.success == false || userAdded.success == null ){
                    Swal.fire({
                      icon: 'error',
                      text: userAdded.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setAddNewUserOpen(true);
                    })
                }
                setflag(false);
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
        
      }
  }

  const bodyAddNewUserOpen = (
    <div className={classes.paperModalAddUser}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <Grid container item xs={12}>
          <Grid item xs={10} >
            <h2 style={{marginLeft:'40%'}}>Add New User</h2>
          </Grid>
          <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
            <MinimizeIcon onClick={()=>handelAddNewUserMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
          <Grid item xs={1} style={{marginTop:'1%',}}>
            <CloseIcon onClick={()=>handelAddNewUserClose()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
        </Grid> 
      </div>
      <div className={classes.modalBody}>
        <Grid>
          {/* to add new user */}
          <TextField
            //   variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              autoFocus
              value={userDetails.name}
              onChange={(e) => setuserDetails({...userDetails, name:e.target.value }) }
            />
            <div className={classes.validation}>{(userDetails.name)?(<div></div>):(nameErr)}</div>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              value={userDetails.username}
              onChange={(e) => setuserDetails({...userDetails, username:e.target.value }) }
            />
            <div className={classes.validation}>{(userDetails.username)?(<div></div>):(userNameErr)}</div>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="EmailID"
              name="email"
              autoComplete="email"
              value={userDetails.email}
              onChange={(e) => setuserDetails({...userDetails, email:e.target.value }) }
            />
            {/* <div className={classes.validation}>{(userDetails.email)?(<div></div>):(emailErr)}</div> */}
            <div className={classes.validation}>{emailErr}</div>
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              name="password"
              autoComplete="password"
              value={userDetails.password}
              onChange={(e) => setuserDetails({...userDetails, password:e.target.value }) }
            />
            {/* <div className={classes.validation}>{(userDetails.password)?(<div></div>):(passwordErr)}</div> */}
            <div className={classes.validation}>{(passwordErr)?(passwordErr):(<div></div>)}</div>

            <FormControl className={classes.formControl}>
              <Grid style={{marginTop:"2%", color:"grey"}}>Role*</Grid>
              <Select
                native
                labelId="role"
                id="role"
                value={userDetails.role}
                onChange={(e) => setuserDetails({...userDetails, role:e.target.value }) }
              >
                <option value="" style={{color:'grey'}}>Select Role</option>
                <option value="trp" style={{color:'grey'}}>TRP</option>
                <option value="trpdoc" style={{color:'grey'}}>TRP Doc</option>
                <option value="trpadmin" style={{color:'grey'}}>TRP Admin</option>
              </Select>
            </FormControl>
            <div className={classes.validation}>{(userDetails.role)?(<div></div>):(roleErr)}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="is_active">Is Active*</InputLabel> */}
              <Grid id="is_active" style={{marginTop:"2%", color:"grey"}}>Is Active*</Grid>
              <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="is_active"
                  aria-label="is_active"
                  name="is_active"
                  defaultValue={JSON.stringify(userDetails.is_active)}
                  onChange={ (e) => setuserDetails({...userDetails, is_active: JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>
            </FormControl>
            <div className={classes.validation}>{(userDetails.is_active == "" && userDetails.is_active !== false && userDetails.is_active !== true)?(activeErr):(<div></div>)}</div>

        </Grid>
        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitAddNewUser}
          >
            Add
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            // color="grey"
            className={classes.submit}
            onClick={handelAddNewUserClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )



  return (
    <div className={classes.root}>
      {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
       <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
      </modal>: null}
      <Grid container>
        Users : 
      </Grid>
      <Grid style={{textAlign:"right"}}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddIcon />}
          onClick={handelAddNewUserOpen}
        >
          Add User
        </Button>
      </Grid>
      <Grid item xs={12}>
        {
          usersList.map(( item , index ) =>(
            // userListForNow.map(( item , index ) =>(
            // console.log("item of user list",item),
            <Grid className={classes.userLabelStyle} 
              onClick={ () => handleUserNameClick(item)}
            >
              {item.name}
            </Grid>
          ))
        }
        <TableContainer className={classes.tableWrapper} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={usersList && usersList.length}
            />
            <TableBody>
              {stableSort(usersList && usersList, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => {
                // console.log("row in table map",item)
                return (
                  <TableRow 
                    key={index}
                    hover={true} classes={{hover: classes.hover}}
                    tabIndex={-1}
                    align="center"
                  >
                    <TableCell align="center" >{item.name}</TableCell>
                    <TableCell align="center" >{item.role}</TableCell>
                    <TableCell align="center" >{item.email}</TableCell>
                    <TableCell align="center" >{item.username}</TableCell>
                    <TableCell width="15%" align="center" >
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => handleSetUserPermissionsOpen(item)}
                      >
                        Show Permissions
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: usersList && usersList.length }]}
                    // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={8}
                    count={usersList && usersList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
      <Modal
        open={openSetUserPermissions}
        onClose={handelSetUserPermissionsClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodySetUserPermissions}
      </Modal>
      {/*  */}
      <Modal
        open={AddNewUserOpen}
        onClose={handelAddNewUserClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyAddNewUserOpen}
      </Modal>
    </div>
  );
}
