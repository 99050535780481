import React, {useEffect} from "react";
import Slide from "@material-ui/core/Slide";
import fetch from "cross-fetch";
import { injectIntl } from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage } from "react-intl";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import SearchViewCases from "./SearchViewCases";
import SearchViewClients from "./SearchViewClients";
import SearchViewMessages from "./SearchViewMessages";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import TaxManagementCaseDetails from "../TaxManagementCaseDetails";
import { Link, useParams } from "react-router-dom";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#4D47DD",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#4D47DD",
      opacity: 1,
    },
    "&$selected": {
      color: "#4D47DD",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#4D47DD",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  pageHeaderContainer: {
    marginBottom: "5%",
  },
  tableDataContainer: {
    backgroundColor: "#ffffff",
  },
  sortByClasses: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  hover: {
    cursor: "pointer",
  },
  sortByContainer: {
    lineHeight: "48px",
    position: "fixed",
  },
  rightAlignContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "2%",
  },
  casesdisplay: {
    display: "block",
  },
  casesdisplayNone: {
    display: "none",
  },
  linkBackToCase:{
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: "600",
    textTransform: "none",
    marginTop: "5%",
    marginBottom: "2%",
    textAlign: "right"
  },
  linkNoUnderline: {
    textDecoration: "none"
  },
  backButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  backArrow: {
    marginRight: "10%",
  },
}));

function TaxManagementSearchResult({ intl , props}) {
  const classes = useStyles();
  // const [searchString, setSerachString] = React.useState();
  // const { searchString } = useParams();
  const [searchResult, setSearchResult] = React.useState(JSON.parse(localStorage.getItem('searchStringIs')));
  const [value, setValue] = React.useState(0);
  const [checked, setChecked] = React.useState(false);

  // const [searchListWhole, setsearchListWhole] = React.useState([]);
  // const [searchListCases, setsearchListCases] = React.useState([]);
  // const [searchListMessages, setsearchListMessages] = React.useState([]);
  // const [searchListClients, setsearchListClients] = React.useState([]);

  const [business_id, setbusiness_id ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const deviceTokenFromRedux = useSelector(state => state.loginDetails.deviceToken);
  const sessionIdFromRedux = useSelector(state => state.loginDetails.sessionId);
  const businessIdFromRedux = useSelector(state => state.loginDetails.businessId);

  // useEffect(() => {
  //   let search_string =JSON.parse(localStorage.getItem('searchStringIs'));
  //   // let search_string = search_string_array.name
  //   console.log("search_string in search result",search_string)
  //   console.log("client search device_token,session_id,businessIdFromRedux,business_id ",
  //     deviceTokenFromRedux,sessionIdFromRedux,businessIdFromRedux,business_id);

  //   if( search_string !== "" ){
  //     const requestOptions = {
  //       method: 'POST',
  //       headers: authHeader(),
  //       body: JSON.stringify({'search_str': search_string, 'business_id': businessIdFromRedux, 
  //       'device_token': deviceTokenFromRedux, 'session_id': sessionIdFromRedux,
  //       'sort_by': '', 'priority': '', 'tags': [], 'assigned_users': []  })
  //     };

  //     fetch( process.env.REACT_APP_SEARCH_CASES , requestOptions)
  //       .then((Response) => Response.json())
  //       .then( async(SearchDetails) => {
  //         console.log("SearchDetails in search",SearchDetails.data);
  //         console.log("SearchDetails in search search_results",SearchDetails.data.search_results);
  //         if(SearchDetails.data.search_results)
  //         {
  //           setsearchListWhole(SearchDetails.data.search_results);
  //           setsearchListCases(SearchDetails.data.search_results.cases);
  //           setsearchListMessages(SearchDetails.data.search_results.messages);
  //           setsearchListClients(SearchDetails.data.search_results.clients);

  //           // await localStorage.setItem('GlobalSearchedCasesList', JSON.stringify(SearchDetails.data.search_results.cases));
  //           // await localStorage.setItem('GlobalSearchedClientList', JSON.stringify(SearchDetails.data.search_results.clients));
  //           // await localStorage.setItem('GlobalSearchedMessageList', JSON.stringify(SearchDetails.data.search_results.messages));
  //         }
  //         if(SearchDetails.success == false){
  //           setsearchListWhole([]);
  //           setsearchListCases([]);
  //           setsearchListMessages([]);
  //           setsearchListClients([]);
  //           // Swal.fire({
  //           //   icon: 'error',
  //           //   text: SearchDetails.errors,
  //           //   confirmButtonColor: '#d33',
  //           //   confirmButtonText: 'OK'
  //           // })
  //         }
  //       })
  //       .catch(err => {
  //         Swal.fire({
  //           icon: 'error',
  //           text: "Something went wrong",
  //           confirmButtonColor: '#d33',
  //           confirmButtonText: 'OK'
  //         })
  //       });
  //   }
  // }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSlideChange = () => {
    setChecked((prev) => !prev);
  };


  return (
    console.log("searchResult is in here",searchResult),
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Slide direction="right" in={!checked} mountOnEnter unmountOnExit>
          <div
            className={
              !checked ? classes.casesdisplay : classes.casesdisplayNone
            }
          >
            <Grid container>
              <Grid item xs={1} className={classes.pageHeaderLabel} >
                <Button href="/search" className={classes.backButton}>
                    <span className={classes.backArrow}>
                    <ArrowBackIcon fontSize="small" />
                  </span>
                  Back
                </Button>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.pageHeaderContainer} style={{marginTop:'1%'}}>
              <Grid item xs={12} className={classes.pageHeaderLabel}>
                <FormattedMessage id="searchResultLabel" />
              </Grid>
            </Grid>
            <Grid container className={classes.linkBackToCase}>
            <Grid item xs={12}>
              <Link to="/search" className={classes.linkNoUnderline}>
                <FormattedMessage id="navigateToSearch" />
              </Link>
            </Grid>
          </Grid>
          </div>
        </Slide>
          <div>
                <Grid className={classes.tableDataContainer}>
                  <Grid item xs={12}>
                    <AntTabs
                      value={value}
                      onChange={handleChange}
                      aria-label="search-result"
                    >
                      <AntTab 
                        label={intl.formatMessage({ id: "casesLink" })} 
                      />
                      <AntTab
                        label={intl.formatMessage({ id: "clientsLink" })}
                      />
                      <AntTab
                        label={intl.formatMessage({ id: "messageLink" })}
                      />
                    </AntTabs>
                    <TabPanel value={value} index={0}>
                      <SearchViewCases handleChange={handleSlideChange} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <SearchViewClients handleChange={handleSlideChange} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <SearchViewMessages handleChange={handleSlideChange} />
                    </TabPanel>
                  </Grid>
                </Grid>
              </div>
      </div>
    </div>
  );
}

export default injectIntl(TaxManagementSearchResult);
