import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { authHeader } from '../../helpers';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector , useDispatch} from 'react-redux';
import useCheckSessionApi from "../../SessionValidity";
import { userActions } from "../../actions";
import Swal from 'sweetalert2';
import { Button } from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from '@material-ui/core/Checkbox';
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridContianer: {
    padding: theme.spacing(2),
  },
  leftAlign: {
    color: 'rgba(0, 0, 0, 0.32)',
    textAlign: 'left'
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    // height: 620,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  paperModalStepTodoList: {
    position: 'absolute',
    width: 550,
    // height: 620,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top:'2%',
    display:'block'
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'12%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
    marginTop: '1%',
  },
  validationMaxTopMargin: {
    color: 'red',
    fontSize: 12,
    marginTop: '2%',
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
    // minWidth: 170,
  },
}));

export default function TaxManagementCasesDocuments() {
  const classes = useStyles();
  const [ ShowProcedure, setShowProcedure ] = React.useState(false);
  const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('selected_business_id')));
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [caseForDetailsFromAsync, setcaseForDetailsFromAsync] = React.useState(JSON.parse(localStorage.getItem('selected_case_for_details')));
  const [caseBusinessDocuments, setcaseBusinessDocuments] = React.useState([]);
  const [casePersonalDocuments, setcasePersonalDocuments] = React.useState([]);

  // request more documents
  const [Document_type, setDocument_type ] = React.useState([
    { option: 'AADHAR', selected: false, label: 'Adhar Card' },
    { option: 'PAN-CARD', selected: false, label: 'PAN Card' },
    { option: 'PASSPORT', selected: false, label: 'Passport' },
    { option: 'VOTER-ID', selected: false, label: 'Voting Card' },
    { option: 'DRIVING-LICENSE', selected: false, label: 'Driving License' }
  ]);
  const [openMoreDocuments, setopenMoreDocuments] = React.useState(false);
  const [moreDocumentType, setmoreDocumentType] = React.useState([]);
  const [moreDocTypeErr, setmoreDocTypeErr] = React.useState("");

  // add more documents
  const [allDocTypeDetails, setallDocTypeDetails] = React.useState([]);
  const[serviceDocTypeDetails, setserviceDocTypeDetails] = React.useState([]);
  const [openAddDocuments, setopenAddDocuments] = React.useState(false);

  const [documentType, setdocumentType] = React.useState("");
  const [documentNumber, setdocumentNumber] = React.useState("");
  const [docHavePass, setdocHavePass] = React.useState("");
  const [documentPassword, setdocumentPassword] = React.useState("");
  const [DocumentFile, setDocumentFile] = React.useState("");
  const [DocumentName, setDocumentName] = React.useState("");

  const [docTypeErr, setdocTypeErr] = React.useState("");
  const [docHavePassErr, setdocHavePassErr] = React.useState("");
  const [docPasswordErr, setdocPasswordErr] = React.useState("");
  const [docFileErr, setdocFileErr] = React.useState("");

  const [flag, setflag] = React.useState(false);
  const dispatch = useDispatch();

  let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
  // console.log("user_permissions from local storage on header page::::::",user_permissions)

  useEffect(() => {
    setShowProcedure(true);
    setflag(true);
    dispatch(userActions.checkSessionValidity());
    // readItemFromStorage();
    let case_id = caseForDetailsFromAsync.case_id;
    // let device_token = JSON.parse(localStorage.getItem('device_token'));
    // let session_id = JSON.parse(localStorage.getItem('session_id'));

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      // body: JSON.stringify({'case_id':case_id ,'stage':"BUSINESS", 
      // 'device_token': device_token, 'session_id': session_id })
      body: JSON.stringify({'case_id':case_id , 
      'device_token': device_token, 'session_id': session_id })
    };
      fetch( process.env.REACT_APP_GET_CASE_UPLOADED_DOCUMENTS , requestOptions)
      .then((Response) => Response.json())
      .then( caseDocuments => {
        setShowProcedure(false);
        console.log("caseDocuments business = ",caseDocuments.data.case_documents);
        if(caseDocuments.success == true)
        {
            // setcaseBusinessDocuments(caseDocuments.data.case_documents)
            var joinedBusiness = [];
            var joinedPersonal = [];
            caseDocuments.data.case_documents.map((i, e)=> {
              if(i.doc_type_tag == "BUSINESS"){
                joinedBusiness = joinedBusiness.concat(i);
                console.log("data joinedBusiness : ",joinedBusiness);
                setcaseBusinessDocuments(joinedBusiness);
              }
              else if(i.doc_type_tag == "PERSONAL"){
                joinedPersonal = joinedPersonal.concat(i);
                console.log("data joinedPersonal : ",joinedPersonal);
                setcasePersonalDocuments(joinedPersonal);
              }
            })
        }
        else{
          setcaseBusinessDocuments([])
          setcasePersonalDocuments([])
        }
    })
    .catch(err => {
      setShowProcedure(false);
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });

    // to get all document types of business
    const requestOptionsAllDocType = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id':business_id,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_ALL_DOC_TYPES , requestOptionsAllDocType)
        .then((Response) => Response.json())
        .then(allDocTypeDetails => {
            console.log("allDocTypeDetails on same page",allDocTypeDetails.data.doc_types);
            if(allDocTypeDetails.data.doc_types)
            {
              setallDocTypeDetails(allDocTypeDetails.data.doc_types)
            }
            if(allDocTypeDetails.success == false){
              setallDocTypeDetails([])
              Swal.fire({
                icon: 'error',
                text: allDocTypeDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 5000,
              })
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

    // to get service document types of business
    console.log("selectedServiecId foe get doc types", caseForDetailsFromAsync.service_id)
    const requestOptionsServiceDocType = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'service_id': caseForDetailsFromAsync.service_id,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_SERVICE_DOC_TYPES , requestOptionsServiceDocType)
        .then((Response) => Response.json())
        .then(serviceDocTypeDetails => {
            console.log("serviceDocTypeDetails on same page",serviceDocTypeDetails.data.doc_types);
            if(serviceDocTypeDetails.data.doc_types)
            {
              setserviceDocTypeDetails(serviceDocTypeDetails.data.doc_types)
            }
            if(serviceDocTypeDetails.success == false){
              setserviceDocTypeDetails([])
              Swal.fire({
                icon: 'error',
                text: serviceDocTypeDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 5000,
              })
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

  }, [flag]);

  // request more doc functions

  const handleRequestDocumentsOpen = () => {
    setopenMoreDocuments(true);
    dispatch(userActions.checkSessionValidity());
  }

  const handleRequestDocumentsClose = async() => {
    setopenMoreDocuments(false);
    await setDocument_type(
      Document_type.map((f)=> {
        f.selected = false;
        return f ;
      })
    )
    setmoreDocumentType([]);
    setmoreDocTypeErr("");
    dispatch(userActions.checkSessionValidity());

  }

  const validate = () => {
    let moreDocTypeErr = '';

    if( moreDocumentType.length == 0){
      moreDocTypeErr = "Please Select File Type"
    }
    
    if(moreDocTypeErr){
      setmoreDocTypeErr(moreDocTypeErr);
      return false;
    }
    return true;
  }

  const handleCheckMoreDocuments = async(option, e) =>{
    console.log("handleCheckMoreDocuments option, e",option, e);
    await setDocument_type(
      Document_type.map((f)=> {
        if(f.option === option){
          f.selected = !f.selected;
        }
        return f ;
      })
    )
    var joined = moreDocumentType.concat(option);
    setmoreDocumentType(joined)
  }

  const handelSubmitRequestMoreDocuments = () => {
    dispatch(userActions.checkSessionValidity());
    const isValid = validate();
    if(isValid){
      console.log("handelSubmitRequestMoreDocuments",Document_type,moreDocumentType)
      console.log("handelSubmitRequestMoreDocuments moreDocumentType",moreDocumentType)
    }
  }

  const body = (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <h2>Request More Documents</h2>
      </div>
      <div className={classes.modalBody}>
        <FormControl className={classes.formControl}>
          <Grid style={{fontSize: 15, fontWeight:'bold'}}>Select Document Type*</Grid>
          {/* <InputLabel id="moreDocumentType">Document Type*</InputLabel> */}
          {/* <Select
            labelId="moreDocumentType"
            id="moreDocumentType"
            value={moreDocumentType}
            onChange={(e) => setmoreDocumentType(e.target.value) }
          >
            <MenuItem value={'AADHAR'}>Adhar Card</MenuItem>
            <MenuItem value={'PAN-CARD'}>PAN Card</MenuItem>
            <MenuItem value={'PASSPORT'}>Passport</MenuItem>
            <MenuItem value={'VOTER-ID'}>Voting Card</MenuItem>
            <MenuItem value={'DRIVING-LICENSE'}>Driving License</MenuItem>
          </Select> */}
          {                                
            Document_type.map(( o, j ) => (
              <Grid item xs={12}>                                          
                <FormControlLabel
                  control={<Checkbox color="primary"/> }
                  // checked={item.selected == true}
                  checked={o.selected == true}  
                  onChange={(e)=> handleCheckMoreDocuments(o.option, e)}                                                  
                  name={o.option}                                                 
                  label={o.label}
                  value={o.option}
                  labelPlacement="end"
                />                                          
              </Grid>
            ))
        }
        </FormControl>
        {/* <div className={classes.validation}>{(moreDocumentType)?(<div></div>):(moreDocTypeErr)}</div> */}
        <div className={classes.validation}>{moreDocTypeErr}</div>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',marginTop:'2%'}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitRequestMoreDocuments}
          >
            Request
          </Button>
          <div style={{marginLeft:'2%'}}></div>
            <Button
              type="submit"
              variant="contained"
              className={classes.submit}
              onClick={handleRequestDocumentsClose}
            >
              Cancel
            </Button>
          </div>
      </div>
    </div>
  )

  // add more doc functions
  const handleAddMoreDocumentsOpen = () => {
    setopenAddDocuments(true)
  }

  const handleAddMoreDocumentsReset = () => {
    setdocumentType("");
    setdocumentNumber("");
    setdocHavePass("");
    setdocumentPassword("");
    setDocumentFile("");
    setDocumentName("");
    setdocTypeErr("");
    setdocHavePassErr("");
    setdocPasswordErr("");
    setdocFileErr("");
  }

  const handleAddMoreDocumentsMinimize = () => {
    setopenAddDocuments(false)
  }

  const handleAddMoreDocumentsClose = () => {
    setopenAddDocuments(false)
    setdocumentType("");
    setdocumentNumber("");
    setdocHavePass("");
    setdocumentPassword("");
    setDocumentFile("");
    setDocumentName("");
    setdocTypeErr("");
    setdocHavePassErr("");
    setdocPasswordErr("");
    setdocFileErr("");
  }

  const changeRadioDocumentHavePass = (e) => {
    // setdocHavePass(JSON.parse(e.target.value));
    setdocHavePass(e.target.value);
  }

  const onUploadDocument= (event) => {
    console.log("event.target.files[0]",event.target.files[0])
    console.log("event.target.files[0] name",event.target.files[0].name)
    console.log("uri",event.target.files.uri)
    setDocumentName(event.target.files[0].name);
    setDocumentFile(event.target.files[0])
  }

  const validateAddMoreDoc = () => {
    let docTypeErr = '';
    let docHavePassErr = '';
    let docPasswordErr = '';
    let docFileErr = '';

    if ( !documentType ){
      docTypeErr = "Please Select Document Type";
    }

    if( !docHavePass ){
      docHavePassErr = "Please Select If Document Have Password or Not ";
    }

    if( docHavePass == "true" ) {
      if( !documentPassword ){
        docPasswordErr = "Please Enter Document Password";
      }
    }

    if( !DocumentFile ){
      docFileErr = "Please Select Document To Upload";
    }


    if( docTypeErr || docHavePassErr || docPasswordErr || docFileErr){
        setdocTypeErr(docTypeErr);
        setdocHavePassErr(docHavePassErr);
        setdocPasswordErr(docPasswordErr);
        setdocFileErr(docFileErr);

        return false;
      }
      return true;
  }

  const refreshDocuments = () => {
    setflag(false);
    let case_id = caseForDetailsFromAsync.case_id;
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'case_id':case_id ,'stage':"BUSINESS", 
      'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_CASE_UPLOADED_DOCUMENTS , requestOptions)
      .then((Response) => Response.json())
      .then( caseDocuments => {
        setShowProcedure(false);
        console.log("caseDocuments business = ",caseDocuments.data.case_documents);
        if(caseDocuments.success == true)
        {
            // setcaseBusinessDocuments(caseDocuments.data.case_documents)
            var joinedBusiness = [];
            var joinedPersonal = [];
            caseDocuments.data.case_documents.map((i, e)=> {
              if(i.doc_type_tag == "BUSINESS"){
                joinedBusiness = joinedBusiness.concat(i);
                console.log("data joinedBusiness : ",joinedBusiness);
                setcaseBusinessDocuments(joinedBusiness);
              }
              else if(i.doc_type_tag == "PERSONAL"){
                joinedPersonal = joinedPersonal.concat(i);
                console.log("data joinedPersonal : ",joinedPersonal);
                setcasePersonalDocuments(joinedPersonal);
              }
            })
        }
        else{
          setcaseBusinessDocuments([])
          setcasePersonalDocuments([])
        }
    })
    .catch(err => {
      setShowProcedure(false);
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });
  }

  const handelSubmitAddtMoreDocuments = () => {
    console.log("documentType,documentNumber,docHavePass,documentPassword,DocumentFile",
      documentType,documentNumber,docHavePass,documentPassword,DocumentFile);
    let user = JSON.parse(localStorage.getItem('user'));
    const isValid = validateAddMoreDoc();
    if(isValid){
      setopenAddDocuments(false);
      setShowProcedure(true);

      const requestOptionsSecond = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          'doc_type': 'case_document', 'doc_name': DocumentFile.name, 'doc_extra_info': '',
          'device_token': device_token, 'session_id': session_id
        })
      }
      return fetch( process.env.REACT_APP_GET_UPLOAD_INFO , requestOptionsSecond)
        .then((Response) => Response.json())
        .then(checkResponseOfS3Upload => {
          console.log("checkResponseOfS3Upload", checkResponseOfS3Upload)

          if (checkResponseOfS3Upload.success === true) {
            console.log("in success checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.public_url.url)

            const formData = new FormData();
            Object.keys(checkResponseOfS3Upload.data.upload_info.fields).forEach(key => {
              formData.append(key, checkResponseOfS3Upload.data.upload_info.fields[key]);
            });
            formData.append("file", DocumentFile);

            const xhr = new XMLHttpRequest();
            xhr.open("POST", checkResponseOfS3Upload.data.upload_info.url, true);
            xhr.send(formData);

            xhr.onload = function () {
              if (this.status === 204) {
                console.log("-----SUCCESSFULLY UPLOADED");
                console.log("in success 204 checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.public_url.url)
                
                let case_id = caseForDetailsFromAsync.case_id;
                // let formdata = new FormData();
                // formdata.append('case_id', case_id);
                // formdata.append('doc_type_id', documentType);
                // formdata.append('doc_no', documentNumber);
                // formdata.append('is_protected', JSON.parse(docHavePass));
                // formdata.append('password', documentPassword);
                // formdata.append('document', checkResponseOfS3Upload.data.public_url.url);
                // formdata.append('device_token', device_token);
                // formdata.append('session_id', session_id);

                const requestOptions = {
                  method: 'POST',
                  headers:  {
                    Authorization: "Token " + user.key,
                  },
                  // body: formdata,
                  body: JSON.stringify({
                    'case_id': case_id, 'doc_type_id': documentType, 'doc_no': documentNumber, 'is_protected': JSON.parse(docHavePass),
                    'password': documentPassword, 'document': checkResponseOfS3Upload.data.public_url.url, 
                    'device_token': device_token, 'session_id': session_id
                  })
                };

                console.log("requestOptions of add more doc",requestOptions)
                return fetch( process.env.REACT_APP_UPLOAD_USER_DOCUMENT , requestOptions)
                  .then((Response) => Response.json())
                  .then( documentUploaded => {
                    setShowProcedure(false);
                    console.log("documentUploaded",documentUploaded.success, documentUploaded);
                      if(documentUploaded.success == true){
                        setopenAddDocuments(false);
                        Swal.fire({
                          icon: 'success',
                          text: "File Uploaded Succesfully",
                          confirmButtonColor: 'primary',
                          confirmButtonText: 'OK',
                          timer: 5000,
                        })
                        setdocumentType("");
                        setdocumentNumber("");
                        setdocHavePass("");
                        setdocumentPassword("");
                        setDocumentFile("");
                        setDocumentName("");
                        setdocTypeErr("");
                        setdocHavePassErr("");
                        setdocPasswordErr("");
                        setdocFileErr("");
                        refreshDocuments();
                      }
                      if(documentUploaded.success == false){
                        setopenAddDocuments(true);
                        Swal.fire({
                          icon: 'error',
                          text: documentUploaded.errors,
                          confirmButtonColor: '#d33',
                          confirmButtonText: 'OK',
                          timer: 5000,
                        })
                      }           
                  })
                  .catch(err => {
                    setShowProcedure(false);
                    Swal.fire({
                      icon: 'error',
                      // text: "Something went wrong",
                      text: "Server Error. Please try again.",
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK'
                    })
                  });
              }
            }
          }
          else {
            setShowProcedure(false);
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error (s3). Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error (s3). Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        })

      // let case_id = caseForDetailsFromAsync.case_id;
      // let formdata = new FormData();
      // formdata.append('case_id', case_id);
      // formdata.append('doc_type_id', documentType);
      // formdata.append('doc_no', documentNumber);
      // formdata.append('is_protected', JSON.parse(docHavePass));
      // formdata.append('password', documentPassword);
      // formdata.append('document', DocumentFile);
      // formdata.append('device_token', device_token);
      // formdata.append('session_id', session_id);

      // const requestOptions = {
      //   method: 'POST',
      //   headers:  {
      //     Authorization: "Token " + user.key,
      //   },
      //   body: formdata,
      // };

      // console.log("requestOptions of add more doc",requestOptions)
      // return fetch( process.env.REACT_APP_UPLOAD_USER_DOCUMENT , requestOptions)
      //   .then((Response) => Response.json())
      //   .then( documentUploaded => {
      //     setShowProcedure(false);
      //     console.log("documentUploaded",documentUploaded.success, documentUploaded);
      //       if(documentUploaded.success == true){
      //         setopenAddDocuments(false);
      //         Swal.fire({
      //           icon: 'success',
      //           text: "File Uploaded Succesfully",
      //           confirmButtonColor: 'primary',
      //           confirmButtonText: 'OK',
      //           timer: 5000,
      //         })
      //         setdocumentType("");
      //         setdocumentNumber("");
      //         setdocHavePass("");
      //         setdocumentPassword("");
      //         setDocumentFile("");
      //         setDocumentName("");
      //         setdocTypeErr("");
      //         setdocHavePassErr("");
      //         setdocPasswordErr("");
      //         setdocFileErr("");
      //         refreshDocuments();
      //       }
      //       if(documentUploaded.success == false){
      //         setopenAddDocuments(true);
      //         Swal.fire({
      //           icon: 'error',
      //           text: documentUploaded.errors,
      //           confirmButtonColor: '#d33',
      //           confirmButtonText: 'OK',
      //           timer: 5000,
      //         })
      //       }           
      //   })
      //   .catch(err => {
      //     setShowProcedure(false);
      //     Swal.fire({
      //       icon: 'error',
      //       // text: "Something went wrong",
      //       text: "Server Error. Please try again.",
      //       confirmButtonColor: '#d33',
      //       confirmButtonText: 'OK'
      //     })
      //   });
      
    }
  }

  const bodyAddMoreDoc = (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        {/* <h2>Add More Documents</h2> */}
        <Grid container item xs={12}>
          <Grid item xs={10} >
            <h2 style={{marginLeft:'35%'}}>Add More Documents</h2>
          </Grid>
          <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
            <MinimizeIcon onClick={()=>handleAddMoreDocumentsMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
          <Grid item xs={1} style={{marginTop:'1%',}}>
            <CloseIcon onClick={()=>handleAddMoreDocumentsClose()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
        </Grid>
      </div>
      <div className={classes.modalBody}>

        <FormControl className={classes.formControl}>
          <Grid style={{marginTop:"2%", color:"grey"}}>Document Type*</Grid>
          <Select
            native
            labelId="documentType"
            id="documentType"
            value={documentType}
            onChange={(e) => setdocumentType(e.target.value) }
          >
            <option value="" style={{color:'grey'}}>Select Document Type</option>
                {/* {allDocTypeDetails && allDocTypeDetails.map(item => */}
                {serviceDocTypeDetails && serviceDocTypeDetails.map(item =>
                  <option value={item.document_type_id}>{item.name}</option>
              )}
          </Select>
        </FormControl>
        <div className={classes.validation}>{(documentType)?(<div></div>):(docTypeErr)}</div>

        <TextField
          margin="normal"
          fullWidth
          id="documentNumber"
          label="Document Number"
          name="documentNumber"
          autoComplete="documentNumber"
          value={documentNumber}
          onChange={(e) => setdocumentNumber(e.target.value) }
        />
        <Grid style={{color:"grey", fontSize: 10, marginTop:"-2%"}}>
          Optional Field
        </Grid>

        <Grid container style={{marginTop:'2%'}}>
          <Grid item xs={12} className={classes.subHeadingLabel}>
            Does the document have password?
          </Grid>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            value={docHavePass}
            defaultValue={docHavePass}
            onChange={changeRadioDocumentHavePass}
          >
            <div className={classes.customRadioButton}>
              <FormControlLabel
                value="true"
                classes={{ label: classes.label }}
                control={<Radio size="small" color="primary" />}
                label= "Yes"
              />
            </div>
            <div className={classes.customRadioButton}>
              <FormControlLabel
                value="false"
                classes={{ label: classes.label }}
                control={<Radio size="small" color="primary" />}
                label= "No"
              />
            </div>
          </RadioGroup>
        </Grid>
        <div className={classes.validation}>{(docHavePass)?(<div></div>):(docHavePassErr)}</div>

        <Grid style={{marginTop:'-3%'}}>
        {
          (docHavePass == "true")?
          (
            <div>
              <TextField
                margin="normal"
                fullWidth
                id="documentPassword"
                label="Document Password"
                name="documentPassword"
                autoComplete="documentPassword"
                value={documentPassword}
                onChange={(e) => setdocumentPassword(e.target.value) }
              />
              <div  className={classes.validation}>{(documentPassword)?(<div></div>):(docPasswordErr)}</div>
            </div>
          )
          :(<div></div>)
        }
        </Grid>

        <div style={{marginTop:"8%"}}>
          <input type='file' 
            // accept=".xlsx"
            id="myBulk"
            onChange={ (e) => onUploadDocument(e)} 
          />
        </div>
        <div className={classes.validationMaxTopMargin}>{(DocumentFile)?(<div></div>):(docFileErr)}</div>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',marginTop:'2%'}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitAddtMoreDocuments}
          >
            Upload
          </Button>
          <div style={{marginLeft:'2%'}}></div>
            <Button
              type="submit"
              variant="contained"
              className={classes.submit}
              onClick={handleAddMoreDocumentsReset}
            >
              {/* Cancel */}
              Reset
            </Button>
          </div>
      </div>
    </div>
  )


  let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));
  console.log("role_of_user",role_of_user)

  // if(role_of_user == "client" || role_of_user== "trp"){
    if(role_of_user == "client"){
    return (
      console.log("caseBusinessDocuments,casePersonalDocuments",caseBusinessDocuments,casePersonalDocuments),
      <div className={classes.root}>
        {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
         <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
        </modal>: null}

        {
        // (caseForDetailsFromAsync && caseForDetailsFromAsync.case_curr_state == "IN-PROGRESS" 
        // || caseForDetailsFromAsync.case_curr_state == "CREATED" || caseForDetailsFromAsync.case_curr_state == "PENDING"
        // || caseForDetailsFromAsync.case_curr_state == "COMPLETED" || caseForDetailsFromAsync.case_curr_state == "CLOSED")?
        (caseForDetailsFromAsync && caseForDetailsFromAsync.case_curr_state == "IN-PROGRESS" 
        || caseForDetailsFromAsync.case_curr_state == "CREATED" || caseForDetailsFromAsync.case_curr_state == "PENDING")?
        (

          // (user_permissions && user_permissions.permissions.add_documents )?
          // (<Grid style={{textAlign:"right", fontSize: 13, fontWeight: "bold", marginTop:"3%",color:'#551A8B',}}
          // >
          //   <text style={{cursor:'pointer'}} 
          //     onClick={ () => handleAddMoreDocumentsOpen()}> 
          //     Add More Documents?
          //   </text>
           
          // </Grid>
          // ):
          // (<Grid></Grid>)

          <Grid style={{textAlign:"right", fontSize: 13, fontWeight: "bold", marginTop:"3%",color:'#551A8B',}}
          >
            <text style={{cursor:'pointer'}} 
              onClick={ () => handleAddMoreDocumentsOpen()}> 
              Add More Documents?
            </text>
           
          </Grid>

        ):
        (<div></div>)
      }
      <Modal
        open={openAddDocuments}
        onClose={handleAddMoreDocumentsClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyAddMoreDoc}
      </Modal>

      <Grid style={{marginTop:"2%"}}>
        <Grid style={{textAlign:"left", fontSize: 18, fontWeight: "bold"}}>Business Documents:</Grid>
        { 
          caseBusinessDocuments.map((item, index) =>(
            <Grid container>
            <Grid item xs={12}>
              <Grid container className={classes.gridContianer}>
                <Grid item xs={1}>
                  <PictureAsPdfIcon color="disabled"/>
                </Grid>
                <Grid item xs={11}>
                  <Grid container>
                    <Grid item xs={12} className={classes.leftAlign}>
                        <a
                          // href={item.user_uploaded.document}
                          // href={ process.env.REACT_APP_MEDIA +item.user_uploaded.document}
                          href={ item.user_uploaded.document}
                          target = "_blank"
                        >
                          <Grid style={{color:"#551A8B",listStyle: "none",}}>{item.user_uploaded.document.substring(item.user_uploaded.document.lastIndexOf('/')+1)}</Grid>
                        </a>
                      </Grid>
                    <Grid item xs={12} className={classes.leftAlign}>
                      {item.doc_type_name}
                    </Grid>
                    <Grid item xs={12} className={classes.leftAlign}>
                      Document Number : {item.user_uploaded.document_no}
                    </Grid>
                    <Grid item xs={12} className={classes.leftAlign}>
                      Document Password : {item.user_uploaded.password}
                    </Grid>
                    <Grid item xs={12} className={classes.leftAlign}>
                      {'24 Jan'}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
                  <Divider />
            </Grid>
            </Grid>
          ))
        }
  
        <Grid style={{textAlign:"left", fontSize: 18, fontWeight: "bold", marginTop:"3%"}}>Personal Documents:</Grid>
        { 
          casePersonalDocuments.map((item, index) =>(
            <Grid container>
            <Grid item xs={12}>
              <Grid container className={classes.gridContianer}>
                <Grid item xs={1}>
                  <PictureAsPdfIcon color="disabled"/>
                </Grid>
                <Grid item xs={11}>
                  <Grid container>
                    <Grid item xs={12} className={classes.leftAlign}>
                        <a
                          // href={item.user_uploaded.document}
                          // href={ process.env.REACT_APP_MEDIA +item.user_uploaded.document}
                          href={ item.user_uploaded.document}
                          target = "_blank"
                        >
                          <Grid style={{color:"#551A8B",listStyle: "none",}}>{item.user_uploaded.document.substring(item.user_uploaded.document.lastIndexOf('/')+1)}</Grid>
                        </a>
                      </Grid>
                    <Grid item xs={12} className={classes.leftAlign}>
                      {item.doc_type_name}
                    </Grid>
                    <Grid item xs={12} className={classes.leftAlign}>
                      Document Number : {item.user_uploaded.document_no}
                    </Grid>
                    <Grid item xs={12} className={classes.leftAlign}>
                      Document Password : {item.user_uploaded.password}
                    </Grid>
                    <Grid item xs={12} className={classes.leftAlign}>
                      {'24 Jan'}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
                  <Divider />
            </Grid>
            </Grid>
          ))
        }
      </Grid>

      {/* request more documents */}
      {/* <Grid style={{textAlign:"left", fontSize: 18, fontWeight: "bold", marginTop:"3%"}}>More Documents Requested:</Grid> */}
      
    </div>
    );
  }

  // else if(role_of_user == "trpadmin" || role_of_user== "trpdoc"){
    else {
  return (
    console.log("caseBusinessDocuments,casePersonalDocuments,caseForDetailsFromAsync in doc",
        caseBusinessDocuments,casePersonalDocuments,caseForDetailsFromAsync),
    <div className={classes.root}>
      {
        ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
        <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
        </modal>: null
      }
    
      
      {/* request more documents */}
      {/* {
        (caseForDetailsFromAsync && caseForDetailsFromAsync.case_curr_state == "CONFIRMED")?
        (
          <Grid style={{textAlign:"right", fontSize: 13, fontWeight: "bold", marginTop:"3%",color:'#551A8B', }}
          
          >
            <text style={{cursor:'pointer'}} 
            onClick={ () => handleRequestDocumentsOpen()}>
              Request More Documents?
            </text>
            
          </Grid>
        ):
        (<div></div>)
      }
      <Modal
        open={openMoreDocuments}
        onClose={handleRequestDocumentsClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>   */}

      {/* Add more documents */}
      {
        // (caseForDetailsFromAsync && caseForDetailsFromAsync.case_curr_state == "IN-PROGRESS" 
        // || caseForDetailsFromAsync.case_curr_state == "CREATED" || caseForDetailsFromAsync.case_curr_state == "PENDING" 
        // || caseForDetailsFromAsync.case_curr_state == "PAYMENT-PENDING"
        // || caseForDetailsFromAsync.case_curr_state == "COMPLETED" )?
        (caseForDetailsFromAsync && caseForDetailsFromAsync.case_curr_state == "IN-PROGRESS" 
        || caseForDetailsFromAsync.case_curr_state == "CREATED" || caseForDetailsFromAsync.case_curr_state == "PENDING" 
        || caseForDetailsFromAsync.case_curr_state == "PAYMENT-PENDING")?
        (

          (user_permissions && user_permissions.permissions.add_documents )?
          (
            <Grid style={{textAlign:"right", fontSize: 13, fontWeight: "bold", marginTop:"3%",color:'#551A8B',}}
            >
              <text style={{cursor:'pointer'}} 
                onClick={ () => handleAddMoreDocumentsOpen()}> 
                Add More Documents?
              </text>
            
            </Grid>
          ):
          (<Grid></Grid>)
        ):
        (<div></div>)
      }
      <Modal
        open={openAddDocuments}
        onClose={handleAddMoreDocumentsClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyAddMoreDoc}
      </Modal>

    <Grid style={{marginTop:"2%"}}>
      <Grid style={{textAlign:"left", fontSize: 18, fontWeight: "bold"}}>Business Documents:</Grid>
      { 
        caseBusinessDocuments.map((item, index) =>(
          <Grid container>
          <Grid item xs={12}>
            <Grid container className={classes.gridContianer}>
              <Grid item xs={1}>
                <PictureAsPdfIcon color="disabled"/>
              </Grid>
              <Grid item xs={11}>
                <Grid container>
                  <Grid item xs={12} className={classes.leftAlign}>
                      <a
                        // href={item.user_uploaded.document}
                        // href={ process.env.REACT_APP_MEDIA +item.user_uploaded.document}
                        href={ item.user_uploaded.document}
                        target = "_blank"
                      >
                        <Grid style={{color:"#551A8B",listStyle: "none",}}>{item.user_uploaded.document.substring(item.user_uploaded.document.lastIndexOf('/')+1)}</Grid>
                      </a>
                    </Grid>
                  <Grid item xs={12} className={classes.leftAlign}>
                    {item.doc_type_name}
                  </Grid>
                  <Grid item xs={12} className={classes.leftAlign}>
                    Document Number : {item.user_uploaded.document_no}
                  </Grid>
                  <Grid item xs={12} className={classes.leftAlign}>
                    Document Password : {item.user_uploaded.password}
                  </Grid>
                  <Grid item xs={12} className={classes.leftAlign}>
                    {'24 Jan'}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
                <Divider />
          </Grid>
          </Grid>
        ))
      }

      <Grid style={{textAlign:"left", fontSize: 18, fontWeight: "bold", marginTop:"3%"}}>Personal Documents:</Grid>
      { 
        casePersonalDocuments.map((item, index) =>(
          <Grid container>
          <Grid item xs={12}>
            <Grid container className={classes.gridContianer}>
              <Grid item xs={1}>
                <PictureAsPdfIcon color="disabled"/>
              </Grid>
              <Grid item xs={11}>
                <Grid container>
                  <Grid item xs={12} className={classes.leftAlign}>
                      <a
                        // href={item.user_uploaded.document}
                        // href={ process.env.REACT_APP_MEDIA +item.user_uploaded.document}
                        href={ item.user_uploaded.document}
                        target = "_blank"
                      >
                        <Grid style={{color:"#551A8B",listStyle: "none",}}>{item.user_uploaded.document.substring(item.user_uploaded.document.lastIndexOf('/')+1)}</Grid>
                      </a>
                    </Grid>
                  <Grid item xs={12} className={classes.leftAlign}>
                    {item.doc_type_name}
                  </Grid>
                  <Grid item xs={12} className={classes.leftAlign}>
                    Document Number : {item.user_uploaded.document_no}
                  </Grid>
                  <Grid item xs={12} className={classes.leftAlign}>
                    Document Password : {item.user_uploaded.password}
                  </Grid>
                  <Grid item xs={12} className={classes.leftAlign}>
                    {'24 Jan'}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
                <Divider />
          </Grid>
          </Grid>
        ))
      }
    </Grid>

    
  </div>
  );
}
}
