import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Link } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import FormControl from '@material-ui/core/FormControl';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import useCheckSessionApi from "../SessionValidity";
import { userActions } from "../actions";

import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import HomeIcon from "@material-ui/icons/Home";
import CallIcon from "@material-ui/icons/Call";
import PublishIcon from "@material-ui/icons/Publish";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import EditIcon from "@material-ui/icons/Edit";
import BusinessIcon from "@material-ui/icons/Business";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center'
  },
  wrapper: {
    margin: "10%",
    marginRight: "10%",
    marginTop: "8%",
    marginBottom: "3%",
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  profileImgContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2%',
  },
  profileImg: {
    height: '100%',
    width: '15%',
  },
  IconColor: {
    color: 'black',
  },
  itemContainer: {
    display: 'flex',
    // justifyContent: 'center',

  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    // height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display: 'block'
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height: '15%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  validationBulk: {
    marginTop: '2%',
    color: 'red',
    fontSize: 12,
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },

}));

export default function UsersProfile() {
  const classes = useStyles();
  const [ShowProcedure, setShowProcedure] = React.useState(false);
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [open, setopen] = React.useState(false);
  const [newPassword, setnewPassword] = React.useState("");
  const [confirmPassword, setconfirmPassword] = React.useState("");
  const [newPassErr, setnewPassErr] = React.useState("");
  const [confirmPassErr, setconfirmPassErr] = React.useState("");

  // ProfilePic
  const [openProfilePic, setopenProfilePic] = React.useState(false);
  const [profilepic, setprofilepic] = React.useState("");
  const [profilepicName, setprofilepicName] = React.useState("");
  const [proflePicErr, setproflePicErr] = React.useState("");

  const dispatch = useDispatch();

  const [flag, setflag] = React.useState(false);

  useEffect(() => {
    setflag(true);
    dispatch(userActions.checkSessionValidity());

    localStorage.removeItem('view_clients_active_cases');
    
    // readItemFromStorage();
  }, [flag]);

  // const readItemFromStorage = async() => {
  //   try {
  //     setDeviceToken(deviceTokenFromRedux)
  //     setSessionId(sessionIdFromRedux)
  //     setflag(false)    
  //   } catch (error) {
  //     console.log("ERROR:",error);        }
  // };

  const handelResetPasswordOpen = () => {
    setopen(true);
  }

  const handelResetPasswordClose = () => {
    setopen(false);
    setnewPassErr("");
    setconfirmPassErr("");
  }

  const validate = () => {
    let newPassErr = '';
    let confirmPassErr = '';

    if (!newPassword) {
      newPassErr = 'Please Enter Password';
    }
    else if(newPassword)  {
      if(newPassword.length < 8){
        newPassErr = 'Password must contain atleast 8 characters';
      }
    }

    if (!confirmPassword) {
      confirmPassErr = 'Please Enter Confirm Password';
    }
    if (newPassword !== confirmPassword) {
      confirmPassErr = 'Passwords not matched';
    }

    if (newPassErr || confirmPassErr) {
      setnewPassErr(newPassErr);
      setconfirmPassErr(confirmPassErr);

      return false;
    }
    return true;
  }

  const handelSubmitResetPassword = () => {
    const isValid = validate();
    if (isValid) {
      setopen(false);
      setShowProcedure(true);
      console.log("newPassword, confirmPassword", newPassword, confirmPassword);
      let changePasswordDetails = {
        newpassword: confirmPassword,
        device_token: device_token,
        session_id: session_id,
      }
      console.log("changePasswordDetails", changePasswordDetails)

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(changePasswordDetails)
      };
      return fetch(process.env.REACT_APP_CHANGE_PASSWORD, requestOptions)
        .then((Response) => Response.json())
        .then(passwordChangedDetails => {
          setShowProcedure(false);
          console.log("passwordChangedDetails", passwordChangedDetails.success);
          console.log("passwordChangedDetails", passwordChangedDetails);
          if (passwordChangedDetails.success == true) {
            Swal.fire({
              icon: 'success',
              text: "Password Changed Succesfully",
              confirmButtonColor: 'primary',
              confirmButtonText: 'OK',
              timer: 5000,
            })
            setopen(false);
            setnewPassword("");
            setconfirmPassword("");
            setnewPassErr("");
            setconfirmPassErr("");
          }
          if (passwordChangedDetails.success == false) {
            setopen(true);
            Swal.fire({
              icon: 'error',
              text: passwordChangedDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          }
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
  }

  const body = (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}>
        <h2>Change Password</h2>
      </div>
      <div className={classes.modalBody}>
        <TextField
          autofocus
          margin="normal"
          required
          fullWidth
          id="newPassword"
          label="New Password"
          name="newPassword"
          autoComplete="newPassword"
          value={newPassword}
          onChange={(e) => setnewPassword(e.target.value)}
        />
        {/* <div className={classes.validation}>{(newPassword) ? (<div></div>) : (newPassErr)}</div> */}
        <div className={classes.validation}>{(newPassErr) ? (newPassErr) : (<div></div>)}</div>

        <TextField
          margin="normal"
          required
          fullWidth
          id="confirmPassword"
          label="Confirm Password"
          name="confirmPassword"
          autoComplete="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setconfirmPassword(e.target.value)}
        />
        {/* <div className={classes.validation}>{(confirmPassword)?(<div></div>):(confirmPassErr)}</div> */}
        <div className={classes.validation}>{confirmPassErr}</div>

        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitResetPassword}
          >
            Change
          </Button>
          <div style={{ marginLeft: '2%' }}></div>
          <Button
            type="submit"
            variant="contained"
            // color="grey"
            className={classes.submit}
            onClick={handelResetPasswordClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )

  // Profile Pic Functions
  const handelChangeProfilePhotoOpen = () => {
    setopenProfilePic(true);
  }

  const handelChangeProfilePhotoClose = () => {
    setopenProfilePic(false);
    setproflePicErr("");
  }

  const onUploadProfilePic = (event) => {
    console.log("event.target.files[0]", event.target.files[0])
    console.log("event.target.files[0] name", event.target.files[0].name)
    setprofilepicName(event.target.files[0].name);
    setprofilepic(event.target.files[0])
  }

  const validateAddProfilePic = () => {
    let proflePicErr = '';

    if (!profilepic) {
      proflePicErr = 'Please Select Profile Photo';
    }

    if (proflePicErr) {
      setproflePicErr(proflePicErr);
      return false;
    }
    return true;
  }

  const handelSubmitResetProfilePic = () => {
    console.log("Submit Profile Pic Clicked, session_id, device_token", profilepic, session_id, device_token);
    console.log("Submit Profile Pic Clicked 222222", profilepic);
    const isValid = validateAddProfilePic();
    if (isValid) {
      setopenProfilePic(false);
      setShowProcedure(true);

      // 12 dec for s3
      // users/getuploadinfo/

      const requestOptionsSecond = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          'doc_type': 'profile_picture', 'doc_name': profilepic.name, 'doc_extra_info': '',
          'device_token': device_token, 'session_id': session_id
        })
      }
      return fetch( process.env.REACT_APP_GET_UPLOAD_INFO , requestOptionsSecond)
        .then((Response) => Response.json())
        .then(checkResponseOfS3Upload => {
          console.log("checkResponseOfS3Upload", checkResponseOfS3Upload)
          console.log("checkResponseOfS3Upload.data", checkResponseOfS3Upload.data)
          console.log("checkResponseOfS3Upload.data.public_url", checkResponseOfS3Upload.data.public_url)
          console.log("checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.public_url.url)

          console.log("checkResponseOfS3Upload.success", checkResponseOfS3Upload.success)
          if (checkResponseOfS3Upload.success === true) {
            console.log("in success checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.public_url.url)

            const formData = new FormData();
            Object.keys(checkResponseOfS3Upload.data.upload_info.fields).forEach(key => {
              formData.append(key, checkResponseOfS3Upload.data.upload_info.fields[key]);
            });
            formData.append("file", profilepic);

            const xhr = new XMLHttpRequest();
            xhr.open("POST", checkResponseOfS3Upload.data.upload_info.url, true);
            xhr.send(formData);

            xhr.onload = function () {
              if (this.status === 204) {
                console.log("-----SUCCESSFULLY UPLOADED");

                console.log("in success 204 checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.public_url.url)
                
                let user = JSON.parse(localStorage.getItem('user'));
                // let formdata = new FormData();
                // formdata.append('profilepic', checkResponseOfS3Upload.data.public_url.url);
                // formdata.append('device_token', device_token);
                // formdata.append('session_id', session_id);
                const requestOptions = {
                  method: 'POST',
                  headers: { 'Authorization': 'Token ' + user.key },
                  // headers: authHeader(),
                  // body: formdata,
                  body: JSON.stringify({
                    'profilepic': checkResponseOfS3Upload.data.public_url.url,
                    'device_token': device_token, 'session_id': session_id
                  })
                };
                return fetch( process.env.REACT_APP_UPLOAD_PROFILE_PIC , requestOptions)
                  .then((Response) => Response.json())
                  .then( profilePicSaved => {
                    setShowProcedure(false);
                    console.log("profilePicSaved ",profilePicSaved.success);
                    console.log("profilePicSaved ",profilePicSaved);
                    if(profilePicSaved.success == true){
                      Swal.fire({
                      icon: 'success',
                      text: "Profile Photo Saved Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    setopenProfilePic(false);
                    setprofilepicName("");
                    setprofilepic("");
                    setproflePicErr("");
                  }
                  if(profilePicSaved.success == false){
                    setopenProfilePic(true);
                    Swal.fire({
                      icon: 'error',
                      text: profilePicSaved.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                  }      
                })
                .catch(err => {
                  setShowProcedure(false);
                  Swal.fire({
                    icon: 'error',
                    // text: "Something went wrong",
                    text: "Server Error. Please try again.",
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                  })
                });

              }
              else {
                console.log("---------FAILED UPLOAD");
                setShowProcedure(false);
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Upload failed to s3",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              }
            }

          }
          else {
            setShowProcedure(false);
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error (s3). Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }

        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error (s3). Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        })

      // 12 dec for s3

      // let user = JSON.parse(localStorage.getItem('user'));
      // let formdata = new FormData();
      // formdata.append('profilepic', profilepic);
      // formdata.append('device_token', device_token);
      // formdata.append('session_id', session_id);
      // const requestOptions = {
      //   method: 'POST',
      //   headers: {'Authorization': 'Token ' + user.key},
      //   // headers: authHeader(),
      //   body: formdata,
      // };
      // return fetch( process.env.REACT_APP_UPLOAD_PROFILE_PIC , requestOptions)
      //   .then((Response) => Response.json())
      //   .then( profilePicSaved => {
      //     setShowProcedure(false);
      //     console.log("profilePicSaved ",profilePicSaved.success);
      //     console.log("profilePicSaved ",profilePicSaved);
      //     if(profilePicSaved.success == true){
      //       Swal.fire({
      //       icon: 'success',
      //       text: "Profile Photo Saved Succesfully",
      //       confirmButtonColor: 'primary',
      //       confirmButtonText: 'OK',
      //       timer: 5000,
      //     })
      //     setopenProfilePic(false);
      //     setprofilepicName("");
      //     setprofilepic("");
      //     setproflePicErr("");
      //   }
      //   if(profilePicSaved.success == false){
      //     setopenProfilePic(true);
      //     Swal.fire({
      //       icon: 'error',
      //       text: profilePicSaved.errors,
      //       confirmButtonColor: '#d33',
      //       confirmButtonText: 'OK',
      //       timer: 5000,
      //     })
      //   }      
      // })
      // .catch(err => {
      //   setShowProcedure(false);
      //   Swal.fire({
      //     icon: 'error',
      //     // text: "Something went wrong",
      //     text: "Server Error. Please try again.",
      //     confirmButtonColor: '#d33',
      //     confirmButtonText: 'OK'
      //   })
      // });
    };


  }

  const bodyProfilePic = (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}>
        <h2>Reset Profile Photo</h2>
      </div>
      <div className={classes.modalBody}>
        <FormControl className={classes.formControl}>
          <Grid item xs={12} style={{ color: 'black', fontSize: 15, }}>
            Upload Profile Photo
          </Grid>
          <div style={{ marginTop: '4%' }}>
            <input type='file'
              // accept="image/*"
              accept=".jpg,.gif,.png,.bmp"
              id="idProf"
              onChange={(e) => onUploadProfilePic(e)}
            />
          </div>
        </FormControl>
        <div className={classes.validationBulk}>{(profilepic) ? (<div></div>) : (proflePicErr)}</div>

        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitResetProfilePic}
          >
            Upload
          </Button>
          <div style={{ marginLeft: '2%' }}></div>
          <Button
            type="submit"
            variant="contained"
            // color="grey"
            className={classes.submit}
            onClick={handelChangeProfilePhotoClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )

  let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));
  let user_details = JSON.parse(localStorage.getItem('users'));
  console.log("user_details in profile", user_details, role_of_user)
  // let user_name = user_details.data.user_details.name
  let user_name = user_details.data.user_details.first_name + " " + user_details.data.user_details.surname

  return (
    <div className={classes.root}>
      {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
        <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
      </modal> : null}
      <div className={classes.wrapper}>
        {/* <div>Users Profile</div> */}
        <Paper className={classes.paper}>
          <Grid container item xs={12}>
            <Grid item xs={12} className={classes.profileImgContainer}>
              <Avatar className={classes.profileImg} src="/static/images/avatar/1.jpg" />
              <EditIcon style={{ marginTop: '10%', cursor: 'pointer' }} onClick={handelChangeProfilePhotoOpen} />
            </Grid>
            <Modal
              open={openProfilePic}
              onClose={handelChangeProfilePhotoClose}
              className={classes.modal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {bodyProfilePic}
            </Modal>

            <Grid item xs={12} style={{ color: 'black', fontSize: 20, marginTop: '2%' }}>
              {user_name} ({role_of_user})
            </Grid>

            <hr style={{ width: "80%", marginTop: "2%", }} />

            <Grid item xs={12} className={classes.itemContainer}>
              {/* <AddToQueueIcon/> Address: */}
              {/* <Grid item  style={{marginTop: '1.5%', cursor: "pointer", marginLeft: "-7%"}} > */}
              <Grid item xs={1} style={{ marginTop: '1.5%', marginLeft: "10%" }} >
                {/* <Link to="UserAddressDetails"> */}
                  <HomeIcon className={classes.IconColor} />
                {/* </Link> */}
              </Grid>
              <Grid item xs={1} style={{ marginTop: '1.7%', marginLeft: '-2%', textDecoration: 'none', color: 'black', fontSize: 15,}}>
                {/* <Link to="UserAddressDetails" style={{ textDecoration: 'none', color: 'black', fontSize: 15, }}> */}
                  Address
                {/* </Link> */}
              </Grid>
              <Grid item xs={10} style={{ marginTop: '1.7%', marginLeft: '', cursor: "pointer", }}>
                <Link to="UserAddressDetails" style={{ textDecoration: 'none', fontSize: 15, marginLeft: '10%' }}>
                  View List
                </Link>
              </Grid>
            </Grid>


            {/* <hr style={{width: "80%", marginTop:"2%",}}/> */}
            <Grid item xs={12} className={classes.itemContainer}>
              {/* <AddToQueueIcon/> Address: */}
              {/* <Grid item  style={{marginTop: '1.5%', cursor: "pointer",marginLeft: "-2.8%"}} > */}
              <Grid item xs={1} style={{ marginTop: '1.5%', marginLeft: "10%" }} >
                {/* <Link to="UserPhoneNumberDetails"> */}
                  <CallIcon className={classes.IconColor} />
                {/* </Link> */}
              </Grid>
              <Grid item xs={2} style={{ color: 'black', fontSize: 15, marginTop: '1.7%', marginLeft: '-4%', textDecoration: 'none', color: 'black', fontSize: 15, }}>
                {/* <Link to="UserPhoneNumberDetails" style={{ textDecoration: 'none', color: 'black', fontSize: 15, }}> */}
                  Phone Number
                {/* </Link> */}
              </Grid>
              <Grid item xs={10} style={{ marginTop: '1.7%', marginLeft: '-5.5%', cursor: "pointer", }}>
                <Link to="UserPhoneNumberDetails" style={{ textDecoration: 'none', fontSize: 15, marginLeft: '10%' }}>
                  View List
                </Link>
              </Grid>
            </Grid>

            {
              (role_of_user == "client") ?
                (
                  <Grid item xs={12} className={classes.itemContainer}>
                    {/* <AddToQueueIcon/> Address: */}
                    {/* <Grid item  style={{marginTop: '1.5%', cursor: "pointer",marginLeft: "-2.8%"}} > */}
                    <Grid item style={{ marginTop: '1.5%', cursor: "pointer", marginLeft: "13%" }} >
                      <Link >
                        <PermContactCalendarIcon className={classes.IconColor} />
                      </Link>
                    </Grid>
                    <Grid container item style={{ color: 'black', fontSize: 15, marginTop: '1.7%', marginLeft: '1%', cursor: "pointer", }}>
                      <Grid style={{ color: 'black', fontSize: 15, }}>
                        PAN No :
                      </Grid>
                      <Grid style={{ color: 'black', fontSize: 15, marginLeft: "11%" }}>
                        {user_details.data.user_details.business_link.pan}
                      </Grid>
                    </Grid>
                  </Grid>
                )
                : (
                  <Grid></Grid>
                )
            }


            {/* <hr style={{width: "80%", marginTop:"2%",}}/> */}
            <Grid item xs={12} className={classes.itemContainer}>
              {/* <AddToQueueIcon/> Address: */}
              {/* <Grid item  style={{marginTop: '1.5%', cursor: "pointer",marginLeft: "1.7%"}} > */}
              <Grid item style={{ marginTop: '1.5%', cursor: "pointer", marginLeft: "13%" }} >
                <Link to="UsersIdProofDetails">
                  <PublishIcon className={classes.IconColor} />
                </Link>
              </Grid>
              <Grid item style={{ color: 'black', fontSize: 15, marginTop: '1.7%', marginLeft: '1%', cursor: "pointer", }}>
                <Link to="UsersIdProofDetails" style={{ textDecoration: 'none', color: 'black', fontSize: 15, }}>
                  Add Update ID Proofs
                </Link>
              </Grid>
            </Grid>

            {/* <hr style={{width: "80%", marginTop:"2%",}}/> */}
            {
              (role_of_user == "client") ?
                (
                  <Grid item xs={12} className={classes.itemContainer}>
                    {/* <AddToQueueIcon/> Address: */}
                    {/* <Grid item  style={{marginTop: '1.5%', cursor: "pointer",marginLeft: "-2.8%"}} > */}
                    <Grid item style={{ marginTop: '1.5%', cursor: "pointer", marginLeft: "13%" }} >
                      <Link to="UserOtherContactDetails">
                        <PermContactCalendarIcon className={classes.IconColor} />
                      </Link>
                    </Grid>
                    <Grid item style={{ color: 'black', fontSize: 15, marginTop: '1.7%', marginLeft: '1%', cursor: "pointer", }}>
                      <Link to="UserOtherContactDetails" style={{ textDecoration: 'none', color: 'black', fontSize: 15, }}>
                        Your Contacts
                      </Link>
                    </Grid>
                  </Grid>
                )
                : (
                  <Grid></Grid>
                )
            }

            {/* <hr style={{width: "80%", marginTop:"2%",}}/> */}
            <Grid item xs={12} className={classes.itemContainer}>
              {/* <AddToQueueIcon/> Address: */}
              {/* <Grid item onClick={handelResetPasswordOpen}  style={{marginTop: '1.5%', cursor: "pointer", marginLeft: "-2%"}} > */}
              <Grid item onClick={handelResetPasswordOpen} style={{ marginTop: '1.5%', cursor: "pointer", marginLeft: "13%" }} >
                <VpnKeyIcon className={classes.IconColor} />
              </Grid>
              <Grid item onClick={handelResetPasswordOpen} style={{ color: 'black', fontSize: 15, marginTop: '1.7%', marginLeft: '1%', cursor: "pointer", }}>
                Change Password
              </Grid>
            </Grid>
            <Modal
              open={open}
              onClose={handelResetPasswordClose}
              className={classes.modal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {body}
            </Modal>

            {/* {
              (role_of_user == "client") ?
                (
                  <Grid item xs={12} className={classes.itemContainer}>
                    <Grid item style={{ marginTop: '1.5%', cursor: "pointer", marginLeft: "13%" }} >
                      <Link >
                        <BusinessIcon className={classes.IconColor} />
                      </Link>
                    </Grid>
                    <Grid item style={{ color: 'black', fontSize: 15, marginTop: '1.7%', marginLeft: '1%', cursor: "pointer", }}>
                      <Link style={{ textDecoration: 'none', color: 'black', fontSize: 15, }}>
                        Switch Business
                      </Link>
                    </Grid>
                  </Grid>
                )
                : (<div></div>)
            } */}

          </Grid>
        </Paper>

      </div>
    </div>
  )
}