import React, {useEffect} from "react";
import Slide from "@material-ui/core/Slide";
import fetch from "cross-fetch";
import { injectIntl } from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage } from "react-intl";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import TaxManagementCaseDetails from "../TaxManagementCaseDetails";
import { modal } from 'reactstrap';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { PropagateLoader } from 'react-spinners';
import { userActions } from "../actions";
import "rc-steps/assets/index.css";
import { Link, Redirect} from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import TextField from "@material-ui/core/TextField";
import AntSwitch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  pageHeaderContainer: {
    marginBottom: "5%",
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  AddNewCGLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "blue",
    textAlign: "right",
    cursor: "pointer",

  },
  CategoryLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
    marginLeft: "5%",
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  paperSteps: {
    padding: theme.spacing(2),
    height: '90%',
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginLeft:"2%",
    marginRight:"2%",
    overflowY:'scroll',
  },
  stepContainer: {
    textAlign: "left",
    color: "#4D47DD",
  },
  stateLableStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
    marginBottom:'2%',
  },
  TaskContainer: {
    textAlign: "left",
  },
  stepNameHeadingStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    marginTop:'4%',
  },
  TaskDetailsHeadingStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    marginTop:'1%',
    marginLeft: "2%",
  },
  TaskLabelStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    marginLeft: "4%",
    // marginTop:'1%',
  },
  questionContainer: {
    textAlign: "left",
  },
  questionsTypeHeadingStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    marginTop:'4%',
  },
  questionsTitleStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    marginTop:'2%',
  },
  questionsOptionStyle: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    marginTop:'1%',
    marginLeft: '3%',
  },
  paperStepsHeading: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    marginLeft:"2%",
    marginRight:"2%",
  },
  paperStepsHeadingLabelStyle:{ 
    fontSize:18, 
    fontWeight:'600', 
    color:'black',
    marginTop:'-2%',
  },
  formControl: {
    // margin: theme.spacing(1),
    marginLeft: "5%",
    marginTop: '2%',
    width: '90%',
  },
  rootDescription: {
    display: "flex",
    justifyContent: "flex-end",
    minWidth: "500px",
    marginTop: "2px",
    marginBottom: "10px",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    // height: 620,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'12%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
}));

function TaxManagementClientGroups({ intl , props}) {
  const classes = useStyles();
  const[ShowProcedure, setShowProcedure ] = React.useState(false);
  const[Flag, setFlag] = React.useState(false);
  const[business_id, setbusiness_id ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const[device_token, setdevice_token ] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const[session_id, setsession_id ] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [selected_Client_Showin_Ui, setselected_Client_Showin_Ui] = React.useState(JSON.parse(localStorage.getItem('selected_Client_Showin_Ui')));
  const[BusinessClientGroupDetails, setBusinessClientGroupDetails] = React.useState([]);
  const[RedirectToClientGroupDetails, setRedirectToClientGroupDetails] = React.useState(false);
  const[open, setopen] = React.useState(false);
  const[NewGroupDetails, setNewGroupDetails] = React.useState({
    name: "",
    shortcode: "",
    description: "",
    is_active: true,
    client_group_id: "",
    business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  });
  const[grpNameErr, setgrpNameErr] = React.useState("");
  const[grpShortcodeErr, setgrpShortcodeErr] = React.useState("");
  const[grpDescriptionErr, setgrpDescriptionErr] = React.useState("");

  const [ShortCodeAlreadyExistErr, setShortCodeAlreadyExistErr] = React.useState("");

  // redux
  const dispatch = useDispatch();

  let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
  // console.log("user_permissions from local storage on header page::::::",user_permissions)


  useEffect(() => {
    dispatch(userActions.checkSessionValidity());

    localStorage.removeItem('view_clients_active_cases');
    
    setShowProcedure(true);
    setFlag(true);

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
    };
    // console.log("requestOptions for client groups",requestOptions);
    fetch( process.env.REACT_APP_GET_BUSINESS_CLIENT_GROUPS , requestOptions)
      .then((Response) => Response.json())
      .then( async(businessClientGroupDetails) => {
        setShowProcedure(false);
        console.log("businessClientGroupDetails from api",businessClientGroupDetails);
        if(businessClientGroupDetails.success == true)
        {
          setBusinessClientGroupDetails(businessClientGroupDetails.data.client_groups);
        }
        else if(businessClientGroupDetails.success == false){
            setBusinessClientGroupDetails([]);
          Swal.fire({
            icon: 'error',
            text: businessClientGroupDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 9000,
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

  }, [Flag]);

  const GropuDetailsClicked = async(item) => {
    dispatch(userActions.checkSessionValidity());
    console.log("GropuDetailsClicked", item)
    await localStorage.setItem('selected_group_for_details', JSON.stringify(item));
    await setRedirectToClientGroupDetails(true);
  }

  //   add new grp functions
  const handleAddNewGroupOpen = () => {
    dispatch(userActions.checkSessionValidity());
    setopen(true);
  }

  const handleAddNewGroupClose = () => {
    dispatch(userActions.checkSessionValidity());
    setopen(false);
    setNewGroupDetails({
        name: "",
        shortcode: "",
        description: "",
        is_active: true,
        client_group_id: "",
        business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    setgrpNameErr("");
    setgrpShortcodeErr("");
    setgrpDescriptionErr("");
    setShortCodeAlreadyExistErr("");
  }

  const handleAddNewGroupCloseMinimize = () => {
    dispatch(userActions.checkSessionValidity());
    setopen(false);
  }

  const handleAddNewGroupClear = () => {
    dispatch(userActions.checkSessionValidity());
    console.log("handleAddNewGroupCreate",handleAddNewGroupCreate)
    setNewGroupDetails({
        name: "",
        shortcode: "",
        description: "",
        is_active: true,
        client_group_id: "",
        business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
    });
    setgrpNameErr("");
    setgrpShortcodeErr("");
    setgrpDescriptionErr("");
    setShortCodeAlreadyExistErr("");
  }

  const handleNewGroupDetailsShortcode = async(value) => {
    console.log("handleNewGroupDetailsShortcode", value);
    await setNewGroupDetails({...NewGroupDetails, shortcode: value })

    if ( value !== "" ){
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'shortcode': value, 'entity_type': "groups", 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
      };
      return fetch( process.env.REACT_APP_SHORTCODE_VALIDATION, requestOptions)
      .then((Response) => Response.json())
      .then( checkShortcode => {
        console.log("checkShortcode is valid or not",checkShortcode)
        console.log("checkShortcode is valid or not",checkShortcode.data.is_valid)
        if( checkShortcode.success == true ){
          if( checkShortcode.data.is_valid == false ){
            setShortCodeAlreadyExistErr("Short code already Exist")
          }
          else if( checkShortcode.data.is_valid == true ){
            setShortCodeAlreadyExistErr("")
          }
        }
      })
    }

  }

  const validate = () => {
    let grpNameErr = '';
    let grpShortcodeErr = '';
    let grpDescriptionErr = '';

    if( !NewGroupDetails.name){
        grpNameErr = "Please Enter Group Name"
    }
    if( !NewGroupDetails.shortcode){
        grpShortcodeErr = "Please Enter Shortcode for Group"
    }
    if( !NewGroupDetails.description){
      grpDescriptionErr = "Please Enter Description for Group"
  }

    if(grpNameErr || grpShortcodeErr || grpDescriptionErr){
        setgrpNameErr(grpNameErr);
        setgrpShortcodeErr(grpShortcodeErr);
        setgrpDescriptionErr(grpDescriptionErr);

        return false;
    }

    return true;
  }

  const handleAddNewGroupCreate = () => {
    dispatch(userActions.checkSessionValidity());
    console.log("handleAddNewGroupCreate",NewGroupDetails);
    const isValid = validate();
    if(isValid){

      if ( ShortCodeAlreadyExistErr == "" ){
        setShowProcedure(true);
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(NewGroupDetails)
        };
        return fetch( process.env.REACT_APP_CREATE_CLIENT_GROUP , requestOptions)
            .then((Response) => Response.json())
            .then(groupCreated => {
                    setShowProcedure(false);
                    setopen(false);
                    console.log("groupCreated in api",groupCreated.success);
                    if(groupCreated.success == true){
                        // setBusinessClientGroupDetails(groupCreated.data.client_group_info)
                        Swal.fire({
                            icon: 'success',
                            text: "New Group Created Succesfully",
                            confirmButtonColor: 'primary',
                            confirmButtonText: 'OK',
                            timer: 5000,
                        })
                        setFlag(false);
                        setNewGroupDetails({
                            name: "",
                            shortcode: "",
                            description: "",
                            is_active: true,
                            client_group_id: "",
                            business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
                            device_token: JSON.parse(localStorage.getItem('device_token')),
                            session_id: JSON.parse(localStorage.getItem('session_id')),
                        })
                        setgrpNameErr("");
                        setgrpShortcodeErr("");
                        setgrpDescriptionErr("");
                        setShortCodeAlreadyExistErr("");
                    }
                    else if(groupCreated.success == false){
                        Swal.fire({
                            icon: 'error',
                            text: groupCreated.error,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK',
                            timer: 5000,
                        })
                    }
                })
                .catch(err => {
                    setShowProcedure(false);
                    Swal.fire({
                      icon: 'error',
                      // text: "Something went wrong",
                      text: "Server Error. Please try again.",
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK'
                    })
                });
      }
      else{
        Swal.fire({
          // icon: 'error',
          // text: "Something went wrong",
          text: "Please change the shortcode",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      }

        
    }
  }

  const body  = (
    <div className={classes.paperModal}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
            <Grid container item xs={12}>
                <Grid item xs={10} >
                    <h2 style={{marginLeft:'40%'}}>Create New Group</h2>
                </Grid>
                <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
                    <MinimizeIcon onClick={()=>handleAddNewGroupCloseMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
                </Grid>
                <Grid item xs={1} style={{marginTop:'1%',}}>
                    <CloseIcon onClick={()=>handleAddNewGroupClose()} style={{fontSize: 20, cursor:'pointer'}}/>
                </Grid>
            </Grid> 
        </div>
        <div className={classes.modalBody}>

            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Group Name"
                name="name"
                value={NewGroupDetails.name}
                onChange={(e) => setNewGroupDetails({...NewGroupDetails, name:e.target.value }) }
            />
            <div className={classes.validation}>{(NewGroupDetails.name)?(<div></div>):(grpNameErr)}</div>

            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="shortcode"
                label="Shortcode"
                name="shortcode"
                value={NewGroupDetails.shortcode}
                // onChange={(e) => setNewGroupDetails({...NewGroupDetails, shortcode:e.target.value }) }
                onChange={(e) => handleNewGroupDetailsShortcode(e.target.value) }
            />
            <div className={classes.validation}>{(NewGroupDetails.shortcode)?(<div></div>):(grpShortcodeErr)}</div>
            
            <div className={classes.validation}>{ShortCodeAlreadyExistErr}</div>

            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="description"
                label="Description"
                name="description"
                value={NewGroupDetails.description}
                onChange={(e) => setNewGroupDetails({...NewGroupDetails, description:e.target.value }) }
            />
            <div className={classes.validation}>{(NewGroupDetails.description)?(<div></div>):(grpDescriptionErr)}</div>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
                <Button
                type="submit"
                //   fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleAddNewGroupCreate}
                >
                    Create
                </Button>
                <div style={{marginLeft:'2%'}}></div>
                <Button
                    type="submit"
                    variant="contained"
                    // color="grey"
                    className={classes.submit}
                    onClick={handleAddNewGroupClear}
                >
                    Clear
                </Button>
            </div>
        </div>
        </div>
  )
  
  const handleDeactivteGroup = (item) => {
    dispatch(userActions.checkSessionValidity());
    console.log("handleDeactivteGroup",item)
    setBusinessClientGroupDetails(
      BusinessClientGroupDetails.map((i, index)=>{
        if(i.client_group_id == item.client_group_id){
          console.log("client_group_id matched",i.client_group_id)
          i.is_active = !i.is_active
        }
        return i;
      })
    )

    let changeGrpIsActive = {
      client_group_id: item.client_group_id,
      name: item.name,
      shortcode: item.shortcode,
      description: item.description,
      is_active: item.is_active, 
      business_id: business_id,
      device_token: device_token,
      session_id: session_id,
    }

    console.log("changeGrpIsActive", changeGrpIsActive)

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(changeGrpIsActive)
    };
    fetch( process.env.REACT_APP_CREATE_CLIENT_GROUP , requestOptions)
      .then((Response) => Response.json())
      .then(groupIsActive => {
        console.log("groupIsActive are",groupIsActive);
      })
  }

  if(RedirectToClientGroupDetails == true){
    return <Redirect to={`/TaxManagementClientGroupDetails`} />
  }
  else{
    return (
        console.log("BusinessClientGroupDetails,",BusinessClientGroupDetails),
        <div className={classes.root}>
            {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
                <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
            </modal>: null}
            <div className={classes.wrapper}>
                <Grid container item xs={12} className={classes.pageHeaderContainer} style={{marginTop:'1%'}}>
                    <Grid item xs={6} className={classes.pageHeaderLabel}>
                        Client Groups
                    </Grid>
                    {user_permissions && user_permissions.permissions.create_groups &&
                      <Grid item xs={6} className={classes.AddNewCGLabel}
                          onClick={() => handleAddNewGroupOpen()}
                      >
                          + Create New Group
                      </Grid>
                    }
                </Grid>
                <Modal
                    open={open}
                    onClose={handleAddNewGroupClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>

                {
                  ( BusinessClientGroupDetails && BusinessClientGroupDetails.length > 0 )?
                  (
                      <Paper className={classes.paper} style={{marginTop:'-2%'}}>
                      {/* <Grid container item xs={12} className={classes.pageHeaderLabel}>
                          Group Details
                      </Grid> */}
                      <Grid container>
                          {
                              BusinessClientGroupDetails && BusinessClientGroupDetails.map((item, index)=>(
                                  <Grid item xs={12} style={{marginTop:'2%', padding:10, backgroundColor: '#ebe2ca'}}
                                      // onClick={() => GropuDetailsClicked(item)}
                                  >
                                      <Grid container item xs={12} style={{color:'black', fontSize:'15px', marginLeft:'1%', cursor:'pointer' }}>
                                        <Grid onClick={() => GropuDetailsClicked(item)} item xs={5}> Name: {item.name} </Grid>
                                        <Grid onClick={() => GropuDetailsClicked(item)} item xs={3} style={{fontSize:'15px',}}>
                                          Shortcode: {item.shortcode}
                                        </Grid>
                                        <Grid onClick={() => GropuDetailsClicked(item)} item xs={3} style={{fontSize:'15px',}}>
                                          Total Clients: {item.total_clients}
                                        </Grid>
                                        <Grid item xs={1} >
                                          {/* {
                                            (item.is_active == true)?
                                            (
                                              <Grid>Active</Grid>
                                            )
                                            :(
                                              <Grid>Inactive</Grid>
                                            )
                                          } */}
                                          <AntSwitch 
                                            checked={item.is_active} 
                                            onChange={() => handleDeactivteGroup(item)} 
                                            size="small" 
                                            name="item.is_active" 
                                            value="item.is_active"
                                            color="primary"
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid onClick={() => GropuDetailsClicked(item)}  style={{color:'black', fontSize:'15px', marginLeft:'1%', cursor:'pointer' }}>
                                        <Grid style={{ fontSize:'15px', marginTop:'0.5%'}}>
                                          Description: {item.description}
                                        </Grid>
                                      </Grid>
                                  </Grid>
                              ))
                          }
                      </Grid>
                    </Paper>
                  )
                  :(
                    <Grid  style={{marginTop:'-2%'}}>
                      {/* <Grid container item xs={12} className={classes.pageHeaderLabel}>
                          Group Details
                      </Grid> */}
                      <Grid container style={{color:'black', fontSize:'15px', marginLeft:'1%' }}>
                        No groups defined.
                      </Grid>
                    </Grid>
                  )
                }

                

            </div>
        </div>
    );  
  }
}

export default injectIntl(TaxManagementClientGroups);
