import React, { useEffect } from "react";
import { Router, Route, Redirect} from "react-router-dom";
import Button from "@material-ui/core/Button";

// from templet
// import "tailwindcss/dist/base.css";
import "tailwindcss/base.css";
import "./globalStyles.css";

import tw from "twin.macro";
import AnimationRevealPage from "./AnimationRevealPage.js";
import Hero from "./TwoColumnWithInput.js";
import Features from "./ThreeColWithSideImage.js";
import MainFeature from "./TwoColWithButton.js";
import MainFeature2 from "./TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "./TwoColWithSteps.js";
import Pricing from "./ThreePlans.js";
import Testimonial from "./TwoColumnWithImageAndRating.js";
import FAQ from "./SingleCol.js";
import GetStarted from "./GetStarted";
import Footer from "./FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "./hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "./hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "./prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import 'slick-carousel/slick/slick.css';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../actions";
import Swal from 'sweetalert2';

function TaxManagementHomePage({ intl,users,props }) {
    const [redirectToLogin, setredirectToLogin] = React.useState(false);

    const Subheading = tw.span`uppercase tracking-widest font-bold text-gray-500`;
    const HighlightedText = tw.span`text-gray-500`;
    // const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
    // const HighlightedText = tw.span`text-primary-500`;

    const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));

    const dispatch = useDispatch()

    React.useEffect(() => {
        console.log("device_token in useeffect login",device_token)
        console.log("session_id in useeffect login",session_id)
        
        let user = JSON.parse(localStorage.getItem('user'));
        console.log("user in useeffect login",user)
    
    
        if( device_token !== null && session_id !== null && user !== null){
          console.log("device token , session id, user info found in useeffect of login")
        //   Swal.fire({
        //     icon: 'error',
        //     text: "You are already logged in in app",
        //     confirmButtonColor: '#d33',
        //     confirmButtonText: 'OK'
        //   })
          dispatch(userActions.logout());
        }
        else{
          console.log("device token , session id, user info is empty in useeffect of login")
        }
    
      }, []);

    const LoginFunc = () => {
        setredirectToLogin(true);
    }

    if(redirectToLogin == true){
        return(
          <Redirect to={'/login'}/>
        )
    }
    else{
        return (
            // <div style={{color:'black',height:'10%'}}>
            //     On Home Page 
            //     <h1>On Home Page</h1>
            //     <Button onClick={LoginFunc}>Login</Button>
            // </div>
            <AnimationRevealPage>
            <Hero roundedHeaderButton={true} />
            <Features
                subheading={<Subheading>Features</Subheading>}
                heading={
                <>
                    We have Amazing <HighlightedText>Service.</HighlightedText>
                </>
                }
            />
            <MainFeature
                subheading={<Subheading>Quality Work</Subheading>}
                imageSrc={heroScreenshotImageSrc}
                imageBorder={true}
                imageDecoratorBlob={true}
            />
            <FeatureWithSteps
                subheading={<Subheading>STEPS</Subheading>}
                heading={
                <>
                    Easy to <HighlightedText>Get Started.</HighlightedText>
                </>
                }
                textOnLeft={false}
                imageSrc={macHeroScreenshotImageSrc}
                imageDecoratorBlob={true}
                decoratorBlobCss={tw`xl:w-40 xl:h-40 -translate-x-1/2 left-auto`}
                // decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
            />
            <MainFeature2
                subheading={<Subheading>VALUES</Subheading>}
                heading={
                <>
                    We Always Abide by Our <HighlightedText>Principles.</HighlightedText>
                </>
                }
                imageSrc={prototypeIllustrationImageSrc}
                showDecoratorBlob={false}
                features={[
                {
                    Icon: MoneyIcon,
                    title: "Affordable",
                    description: "We promise to offer you the best rate we can - at par with the industry standard.",
                    iconContainerCss: tw`bg-green-300 text-green-800`
                },
                {
                    Icon: BriefcaseIcon,
                    title: "Professionalism",
                    description: "We assure you that our templates are designed and created by professional designers.",
                    iconContainerCss: tw`bg-red-300 text-red-800`
                }
                ]}
            />
            <Pricing
                subheading={<Subheading>Pricing</Subheading>}
                heading={
                <>
                    Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
                </>
                }
                plans={[
                {
                    name: "Personal",
                    price: "$17.99",
                    duration: "Monthly",
                    mainFeature: "For Individuals",
                    features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"]
                },
                {
                    name: "Business",
                    price: "$37.99",
                    duration: "Monthly",
                    mainFeature: "For Small Businesses",
                    features: ["60 Templates", "15 Landing Pages", "22 Internal Pages", "Priority Assistance"],
                    featured: true
                },
                {
                    name: "Enterprise",
                    price: "$57.99",
                    duration: "Monthly",
                    mainFeature: "For Large Companies",
                    features: ["90 Templates", "27 Landing Pages", "37 Internal Pages", "Personal Assistance"]
                }
                ]}
            />
            <Testimonial
                subheading={<Subheading>Testimonials</Subheading>}
                heading={
                <>
                    Our Clients <HighlightedText>Love Us.</HighlightedText>
                </>
                }
                testimonials={[
                {
                    stars: 5,
                    profileImageSrc:
                    "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
                    heading: "Amazing User Experience",
                    quote:
                    "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
                    customerName: "Charlotte Hale",
                    customerTitle: "Director, Delos Inc."
                },
                {
                    stars: 5,
                    profileImageSrc:
                    "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
                    heading: "Love the Developer Experience and Design Principles !",
                    quote:
                    "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                    customerName: "Adam Cuppy",
                    customerTitle: "Founder, EventsNYC"
                }
                ]}
            />
            <FAQ
                subheading={<Subheading>FAQS</Subheading>}
                heading={
                <>
                    You have <HighlightedText>Questions ?</HighlightedText>
                </>
                }
                faqs={[
                {
                    question: "Are all the templates easily customizable ?",
                    answer:
                    "Yes, they all are. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                },
                {
                    question: "How long do you usually support an standalone template for ?",
                    answer:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                },
                {
                    question: "What kind of payment methods do you accept ?",
                    answer:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                },
                {
                    question: "Is there a subscribption service to get the latest templates ?",
                    answer:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                },
                {
                    question: "Are the templates compatible with the React ?",
                    answer:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                },
                {
                    question: "Do you really support Internet Explorer 11 ?",
                    answer:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                }
                ]}
            />
            <GetStarted/>
            <Footer />
            </AnimationRevealPage>
        )
    }
}

export default TaxManagementHomePage;