import React, { useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import TaxManagementRecentCases from "../TaxManagementRecentCases";
import SearchView from "../SearchView";
import { FormattedMessage } from "react-intl";
import { userActions } from "../actions";
import Grid from "@material-ui/core/Grid";
import TaxManagementCaseDetails from "../TaxManagementCaseDetails";
import TaxManagementRecentClient from "../TaxManagementRecentClient";
import TaxManagementClientDetails from "../TaxManagementClientDetails";
import useCheckSessionApi from "../SessionValidity";
import { authHeader } from '../helpers';
import { useSelector, useDispatch } from 'react-redux';
import Paper from "@material-ui/core/Paper";
import { set } from "lodash";
import BusinessIcon from "@material-ui/icons/Business";
import { PropagateLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import { modal } from 'reactstrap';

import { useThemeContext, useThemeUpdate } from '../ThemeContext'

import ChartsFile from "./ChartsFile";

// import SessionValidity from '../SessionValidity';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '5%',
    // overflowY: 'scroll',
  },
  root2: {
    flexGrow: 1,
    marginTop: '50%',
  },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  quickAccessLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  recentCaseLabelContainer: {
    marginTop: "5%",
    marginBottom: "1%",
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  pageHeaderContainer: {
    marginBottom: "5%",
  },
  casesdisplay: {
    display: "block",
  },
  casesdisplayNone: {
    display: "none",
  },
  pageHeaderLabelClient: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "25px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
    marginTop: "2%",
  },
  paperWrapper: {
    margin: "10%",
    marginRight: "10%",
    marginTop: "8%",
    marginBottom: "3%",
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  businessHeading: {
    // display: 'flex',
    // justifyContent: 'center',
    // marginTop: '2%',
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "23px",
    color: 'black'
  },
  activeBusinessLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "20px",
    color: "black",
    // textAlign: "left",
  },
  IconColor: {
    color: 'blue',
    size: '10'
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },

}));

export default function TaxManagementSearch(props) {
  const darkTheme = useThemeContext()
  const classes = useStyles();
  const [ ShowProcedure, setShowProcedure ] = React.useState(true);
  const [device_token, setdevice_token ] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id ] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [business_id, setbusiness_id ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const [user_deatils_async, setuser_deatils_async] = React.useState(JSON.parse(localStorage.getItem('users')));
  const [user_deatils_redux, setuser_deatils_redux] = React.useState();
  const [user_name_redux, setuser_name_redux] = React.useState(""); 
  const [user_business_redux, setuser_business_redux] = React.useState(""); 
  const [searchChecked, setChecked] = React.useState(false);
  const [recentCaseChecked, setRecentCaseChecked] = React.useState(true);
  const [recentClientChecked, setRecentClientChecked] = React.useState(true);
  const [DeviceToken, setDeviceToken] =   React.useState();
  const [SessionId, setSessionId] =   React.useState();
  const [flag, setflag] = React.useState(false);
  // const [drawerOpenClose, setdrawerOpenClose] = React.useState(JSON.parse(localStorage.getItem('drawerOpenClose')));
  const [drawerOpenClose, setdrawerOpenClose] = React.useState();

  // const [token, setToken] =  useState('');

  //  const [createSession, setcreateSession] = React.useState(false);
  
  const dispatch = useDispatch();
  const infoFromRedux = useSelector(state => state);
  const userInfoFromRedux = useSelector(state => state.users);
  const userInfoFromReduxLogDetails = useSelector(state => state.loginDetails.logedInUsersDetails);
  const deviceTokenFromRedux = useSelector(state => state.loginDetails.deviceToken);
  const sessionIdFromRedux = useSelector(state => state.loginDetails.sessionId);
  const businessIdFromRedux = useSelector(state => state.loginDetails.businessId);


  // ////////////////////////////////////////////////////////////////////////////
  // working 7 june this is working 27 july
  let user = JSON.parse(localStorage.getItem('user'));
  const [data, checkSessionValidity] = useCheckSessionApi({
    url: process.env.REACT_APP_CHECK_SESSION_VALIDITY,
    options: {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
         Authorization: "Token " + user.key,
      },
      // body: ({'device_token':deviceTokenFromRedux, 
      //       'session_id':sessionIdFromRedux}),
      body: JSON.stringify({
        device_token: deviceTokenFromRedux,
        session_id: sessionIdFromRedux,
      }),
    },
    props,
  });
// /////////////////////////////////////////////////////////////////////////////

  // useEffect(() => {
  //   setdrawerOpenClose(JSON.parse(localStorage.getItem('drawerOpenClose')))
  // }, [drawerOpenClose]);

  // useEffect(() => {
  //   setflag(true)
    //  if(deviceTokenFromRedux !== undefined ){
    //            setDeviceToken(deviceTokenFromRedux)
    //           // setflag(false)
    //         }
    //         if(sessionIdFromRedux !== undefined ){
    //            setSessionId(sessionIdFromRedux)
    //           // setflag(true)
    //         }
    // readItemFromStorage();
    // if (DeviceToken && SessionId) {
      // const resp = checkSessionValidity;
     // }
    // console.log("infoFromRedux on search page useEffect",infoFromRedux)
    // console.log("deviceTokenFromRedux in search useeffect", deviceTokenFromRedux)
    // console.log(" SessionId in search for check call",sessionIdFromRedux)

    // let device_token = JSON.parse(localStorage.getItem('device_token'));
    // let session_id = JSON.parse(localStorage.getItem('session_id'));
    // console.log("device token n session id from localstorage on search useeffect",device_token,session_id)

    
    
    // readItemFromRedux();
    // if(DeviceToken !== '' && SessionId !== '' && DeviceToken !== null && SessionId !== null){
    // if( DeviceToken !== undefined && SessionId !== undefined ){
      // console.log("here in search useeffect if condition",DeviceToken,SessionId);
      // const resp =  checkSessionValidity
      // const requestOptions = {
      //   method: 'POST',
      //   headers: authHeader(),
      //   // body: JSON.stringify({'username': username})
      //   body: JSON.stringify({'device_token': "db04433b-c8e6-4d6a-a821-4d5c48677b8a", 'session_id' : "078103b8-0da7-4c05-b9b0-7f622bced3a7"})
      // };
      // console.log("requestOptions in search",requestOptions)
      // return fetch( process.env.REACT_APP_CHECK_SESSION_VALIDITY , requestOptions)
      // .then((Response) => Response.json())
      // .then( async(checkSession) => {
      //       console.log("checkSession in check session func on search==========",checkSession.success,checkSession);
      //       // console.log("checkSession in check session func .data.session_info.is_active==========",checkSession.data.session_info.is_active);
      //       if(checkSession.success === true){
      //           console.log("in checkSession.success === true");
      //           if(checkSession.data.session_info.is_active == true && checkSession.data.session_info.session_id_refreshed == false ){
      //               console.log("as it is session==1=1=1=1=");
      //           }
      //           if(checkSession.data.session_info.is_active == true && checkSession.data.session_info.session_id_refreshed == true ){
      //               console.log("in checkSession.data.session_info.is_active === true && checkSession.data.session_info.session_id_refreshed === true");
      //               await localStorage.setItem('device_token', JSON.stringify(checkSession.data.session_info.device_token));
      //               await localStorage.setItem('session_id', JSON.stringify(checkSession.data.session_info.session_id));
      //               }
      //           else if(checkSession.data.session_info.is_active === false){
      //               console.log("checkSession.data.session_info.is_active === false");
      //               // dispatch(userActions.logout());
      //           }
      //       }
      //       else if(checkSession.success === false){
      //           console.log("checkSession checkSession.success === false",checkSession);
      //           // dispatch(userActions.logout());
      //       }

      //   })
    //  }
  // }, []);

  // const readItemFromStorage = async() => {
  //       try {
  //           // let device_token = JSON.parse(localStorage.getItem('device_token'));
  //           // let session_id = JSON.parse(localStorage.getItem('session_id'));
  //           // console.log("here in search====>device_token,session_id",device_token,session_id);
  //           // if(deviceTokenFromRedux !== undefined ){
  //           //   await setDeviceToken(deviceTokenFromRedux)
  //           //   // setflag(false)
  //           // }
  //           // if(sessionIdFromRedux !== undefined ){
  //           //   await setSessionId(sessionIdFromRedux)
  //           //   // setflag(true)
  //           // }
  //            setDeviceToken(deviceTokenFromRedux)
  //            setSessionId(sessionIdFromRedux)
  //           setflag(false)
  //           // callCjeckSession()
  //           // const resp =  checkSessionValidity
            
  //       } catch (error) {
  //           console.log("ERROR:",error);
  //       }
  //     };

  // const callCjeckSession = () => {
  //   dispatch(userActions.checkSessionValidity(deviceTokenFromRedux,sessionIdFromRedux));
  // }

  const handleRecentCaseChange = () => {
    setChecked((prev) => !prev);
    setRecentCaseChecked((prev) => !prev);
  };

  const handleRecentClientChange = () => {
    setChecked((prev) => !prev);
    setRecentClientChecked((prev) => !prev);
  };

  React.useEffect(() => {
    // let device_token = JSON.parse(localStorage.getItem('device_token'));
    // let session_id = JSON.parse(localStorage.getItem('session_id'));
    // // if (device_token){
    //   dispatch(userActions.checkSessionValidity(device_token, session_id));
    // // }
    getInfo()
    localStorage.removeItem('view_clients_active_cases');
    // localStorage.setItem('pageNumberOfTableCasesTableTrpAdmin', 0);
    // localStorage.setItem('rowsPerPageOfTableCasesTableTrpAdmin', 5);
    
    
  }, [user_name_redux,user_business_redux,user_deatils_redux,userInfoFromReduxLogDetails,drawerOpenClose]);

  const getInfo = async() => {
    setdrawerOpenClose(JSON.parse(localStorage.getItem('drawerOpenClose')))
    if(userInfoFromReduxLogDetails !== undefined){
      await setuser_deatils_redux(userInfoFromReduxLogDetails);
      await setuser_name_redux(userInfoFromReduxLogDetails && userInfoFromReduxLogDetails.name);
      
      if(userInfoFromReduxLogDetails.business_link !== null){
        await setuser_business_redux(userInfoFromReduxLogDetails && userInfoFromReduxLogDetails.business_link.business_name)
      }
    }
  } 

  // const changeFlag = () => {
  //   setflag(false);
  // }
  const themeStyles = {
    // backgroundColor: darkTheme ? {marginLeft: '10%'} : '',
    // color: darkTheme ? '#CCC' : '#333',
    marginLeft: darkTheme ? '20%' : '0%',
    marginTop: darkTheme ? '10%' : '0%',
    // padding: '2rem',
    // margin: '2rem',
}

  let logedIn_users_businessId = JSON.parse(localStorage.getItem('logedIn_users_businessId'));
  let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));
  let users = JSON.parse(localStorage.getItem('users'));

  if( userInfoFromReduxLogDetails ){
    console.log("ok got userInfoFromReduxLogDetails",userInfoFromReduxLogDetails)

    // client
    if (role_of_user == "client"){
      return(
        <div
                className={
                  !searchChecked ? classes.casesdisplay : classes.casesdisplayNone
                }
              >
                <Slide
                  direction="right"
                  in={!searchChecked}
                  mountOnEnter
                  unmountOnExit
                >
                  <Grid container className={classes.pageHeaderContainer}>
                    <Grid item xs={12} className={classes.pageHeaderLabelClient}>
                      Welcome !! &nbsp; {user_name_redux && user_name_redux}
                      {/* {user_deatils_async && user_deatils_async.data.user_details.name} */}
                      {/* {userInfoFromRedux.items.data.user_details.name} */}
                    </Grid>
                  </Grid>
                </Slide>
  
                {/* here switch business */}
                <div className={classes.paperWrapper}>
                   <Paper className={classes.paper}>
                    <Grid container>
                      <Grid item xs={12} className={classes.businessHeading}>                  
                        Current Business Details
                      </Grid>
                      {/* <hr style={{width: "950%", marginTop:"2%",}}/> */}
                      <Grid container style={{marginTop:"4%",}}>
                        <Grid item xs={12} className={classes.activeBusinessLabel}> 
                          Active Business : {user_business_redux && user_business_redux}
                        </Grid>
                      </Grid>
  
                      {/* <Grid container style={{marginTop:"4%",}}>
                        <Grid container item xs={12} className={classes.activeBusinessLabel} style={{cursor:"pointer"}} >
                          <Grid item style={{marginLeft: "39%"}}>
                            <BusinessIcon className={classes.IconColor} />
                          </Grid>
                          <Grid item style={{marginLeft: "2%"}}>
                            Switch Business
                          </Grid>
                        </Grid>
                      </Grid> */}
  
                    </Grid>
                  </Paper>

                  <Grid style={{marginTop:'5%'}} className={classes.pageHeaderLabel}>
                        <Grid className={classes.pageHeaderLabel}>
                          Charts: 
                        </Grid>
                        <Grid item xs={12} style={{marginTop:'1%'}}>
                          <ChartsFile/>
                        </Grid>
      
                      </Grid>
                </div>
              </div>
      )
    }

    // trpadmin
    // if ( role_of_user == "trpadmin" && userInfoFromReduxLogDetails && userInfoFromReduxLogDetails.is_active == false){
    // if ( role_of_user == "trpadmin" && userInfoFromReduxLogDetails && userInfoFromReduxLogDetails.business_is_active == false){
      if ( role_of_user == "trpadmin" && userInfoFromReduxLogDetails && userInfoFromReduxLogDetails.business.business_is_active == false){
    
        return(
          console.log("userInfoFromReduxLogDetails",userInfoFromReduxLogDetails),
          <div className={classes.root}>
          <div className={classes.wrapper}>
            <div className={classes.paperWrapper} style={{marginTop:'10%'}}>
                     <Paper className={classes.paper}>
                      <Grid container className={classes.businessHeading}>
                        Business account is inactive. Please contact your administrator.
                      </Grid>
                    </Paper>
                  </div>
    
          </div>
          </div>
    
        )
      }
    
      // otherwise
      else{
        return (
          // console.log("logedIn_users_businessId on search",logedIn_users_businessId),
          // console.log("infoFromRedux on search page return",infoFromRedux),
          // console.log("deviceTokenFromRedux,businessIdFromRedux in search return", deviceTokenFromRedux,businessIdFromRedux),
          // console.log("role_of_user on search",role_of_user),
          console.log("userInfoFromReduxLogDetails",userInfoFromReduxLogDetails),
          console.log("user_deatils_redux,user_name_redux,user_business_redux ",user_deatils_redux,user_name_redux,user_business_redux),
          console.log("drawerOpenClose in search page", drawerOpenClose),
      
          <div className={classes.root}>
            <div className={classes.wrapper}>
                  <div
                    className={
                      !searchChecked ? classes.casesdisplay : classes.casesdisplayNone
                    }
                  >
                    <Grid container className={classes.pageHeaderContainer}>
                        <Grid item xs={12} className={classes.pageHeaderLabel}>
                          <FormattedMessage id="searchLink" />
                        </Grid>
                      </Grid>
      
                        <Grid container>
                          <Grid item xs={12}>
                            <SearchView />
                          </Grid>
                        </Grid>
                      {/* </Slide> */}
                      <Grid style={{marginTop:'5%'}} className={classes.pageHeaderLabel}>
                        {
                          ( role_of_user !== "sysadmin" && role_of_user !== "trpdoc")?
                          (
                            <Grid className={classes.pageHeaderLabel}>
                              Charts: 
                              {/* {process.env.REACT_APP_API_TRP_FLOW_COM_WORKFLOW}
                              {process.env.NODE_ENV} */}
                            </Grid>
                          ):
                          (<Grid></Grid>)
                        }
                        <Grid item xs={12} style={{marginTop:'1%'}}>
                          <ChartsFile/>
                          {/* <Chart
                            width={1000}
                            height={400}
                            chartType="ColumnChart"
                            loader={<div>Loading Chart</div>}
                            data={[
                              ['No', 'Title'],
                              ['CREATED', CaseStatCreatedCount],
                              ['DRAFT', CaseStatDraftCount],
                              ['CONFIRMED', CaseStatConfirmedCount],
                              ['INITIATED', CaseStatInitittedCount],
                            ]}
                            options={{
                              title: 'Cases count on current states',
                              chartArea: { width: '30%' },
                              hAxis: {
                                title: 'Case Stat',
                                minValue: 0,
                              },
                              vAxis: {
                                title: 'Count',
                              },
                            }}
                            legendToggle
                          /> */}
                        </Grid>
      
                      </Grid>
                    </div>
                
      
            </div>
          </div>
        );
        }
  }
  else{
    return(
      console.log("userInfoFromReduxLogDetails",userInfoFromReduxLogDetails),
      <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.paperWrapper} style={{marginTop:'10%'}}>
                 {/* <Paper className={classes.paper}>
                  <Grid container className={classes.businessHeading}>
                    Loading....
                  </Grid>
                </Paper> */}
                {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
                  <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
                </modal>: null}
              </div>

      </div>
      </div>

    )
  }
  
  
  
}